<template>
    <div class="create-scouting-report">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" :class="['default__dialog', 'create-scouting-report-dialog', { 'full-length': selectedEPPlayer?.id || selectedPlayer?.id }]">
                <div class="icon__container">
                    <i class="fa-solid fa-clipboard-list"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-report__header">
                    <h1 class="dialog--title">Create Scouting Report</h1>
                    <i @click="onCancelClicked" class="fa-sharp fa-solid fa-xmark item-cancel__btn" title="Close"></i>
                </div>
                <div class="upsert-item__container create-scouting-report">
                    <k-stepper :value="wizardStep" :items="wizardSteps"></k-stepper>
                    <section class="upsert-item__section player-search" v-if="wizardStep === 0">
                        <h2 class="upsert-item__section--title">Player Search</h2>
                        <p class="section-info">Search Elite Prospects by first and last name</p>

                        <div class="input__row ep-player-search__row trio">
                            <label class="input__label">
                                First Name
                                <k-input v-model="searchFirstName" />
                            </label>
                            <label class="input__label">
                                Last Name
                                <k-input v-model="searchLastName" />
                            </label>
                            <k-button class="ep-player-search__btn" :disabled="!searchFirstName || !searchLastName" @click="playerSearch"><i class="fa-regular fa-magnifying-glass"></i> Search</k-button>
                        </div>
                        <div class="player-not-found-info">
                            <p class="ep-player-warning" v-if="EPPlayerSearchLoaded && !selectedEPPlayer?.id && EPPlayers.length > 0"><i class="fa-solid fa-circle-exclamation"></i>  Select a player from the dropdown below or click 'Player Not Found'</p>
                            <p class="ep-player-not-found" v-if="EPPlayerSearchLoaded && !selectedEPPlayer?.id && EPPlayers.length === 0"><i class="fa-solid fa-circle-exclamation"></i>  A player match was not found. Update your search or click 'Player Not Found'</p>
                            <p class="ep-player-warning" v-if="EPPlayerSearchLoaded && selectedEPPlayer?.id && multipleEPPlayerMatches"><i class="fa-solid fa-circle-exclamation"></i>  Multiple possible matches found. Select player from dropdown or click 'Next'</p>
                        </div>
                        <div class="ep-players-data" v-if="EPPlayerSearchLoaded">
                            <div class="input__row select">
                                <label class="input__label">
                                    Player
                                    <k-dropdown :data-items="EPPlayers"
                                                class="ep-player__dropdown"
                                                v-model="selectedEPPlayer"
                                                :default-item="{ selectDisplay: 'Select Player', id: null }"
                                                :text-field="'selectDisplay'"
                                                :data-item-key="'id'"
                                                :disabled="EPPlayers.length === 0"></k-dropdown>
                                </label>

                            </div>
                            <div :class="['selected-player-data', { 'with-image': selectedEPPlayer.imageUrl }]" v-if="selectedEPPlayer?.id">
                                <div class="name-container" v-if="!selectedEPPlayer.imageUrl">
                                    <i class="fa-duotone fa-solid fa-user"></i>
                                    <h3>{{ selectedEPPlayer.firstName }} {{ selectedEPPlayer.lastName }} <span v-if="selectedEPPlayer.alternativeName">({{ selectedEPPlayer.alternativeName }})</span></h3>
                                </div>
                                <div class="name-container" v-if="selectedEPPlayer.imageUrl">
                                    <img class="selected-player-img" :src="selectedEPPlayer.imageUrl" />
                                </div>
                                <div class="player-data-rows">
                                    <div class="player-data-row player-name" v-if="selectedEPPlayer.imageUrl">
                                        <h3>{{ selectedEPPlayer.firstName }} {{ selectedEPPlayer.lastName }} <span v-if="selectedEPPlayer.alternativeName">({{ selectedEPPlayer.alternativeName }})</span></h3>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Nationality: <span>{{ selectedEPPlayer.nationality?.name ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Position: <span>{{ selectedEPPlayer.position ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Date of Birth: <span>{{ selectedEPPlayer.dateOfBirth ? new Date(selectedEPPlayer.dateOfBirth).toLocaleDateString() : 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Age: <span>{{ selectedEPPlayer.age ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>League: <span>{{ selectedEPPlayer.latestStats?.leagueName ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Team: <span>{{ selectedEPPlayer.latestStats?.teamName ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="either-or">- OR -</p>
                            <k-button class="player-not-found-btn" @click="onPlayerNotFound">Player Not Found</k-button>
                        </div>
                    </section>

                    <section class="upsert-item__section confirm-player" v-if="wizardStep === 1">
                        <h2 class="upsert-item__section--title">Confirm Player</h2>
                        <p class="section-info">If the player is not found in the dropdown below, create a new player record</p>
                        <div class="player-not-found-info">
                            <p class="ep-player-not-found" v-if="!selectedPlayer?.id && allPlayers.length > 0 && playerSearchLoaded"><i class="fa-solid fa-circle-exclamation"></i>  A player match was not found. Select a player from the dropdown or click 'Create a New Player Record'</p>
                            <p class="ep-player-warning" v-if="allPlayers.length > 0 && selectedPlayer?.id && multiplePlayerMatches && playerSearchLoaded"><i class="fa-solid fa-circle-exclamation"></i>  Multiple possible matches found. Select player from dropdown or click 'Next'</p>
                        </div>
                        <div class="ep-players-data" v-if="allPlayers.length > 0">
                            <div class="input__row select">
                                <label class="input__label">
                                    Player
                                    <k-dropdown :data-items="filteredPlayers"
                                                class="ep-player__dropdown"
                                                v-model="selectedPlayer"
                                                :default-item="{ selectDisplay: 'Select Player', id: null }"
                                                :text-field="'selectDisplay'"
                                                :data-item-key="'id'"
                                                :filterable="true"
                                                @filterchange="onPlayerFilterChange"></k-dropdown>
                                </label>
                            </div>
                            <div class="selected-player-data" v-if="selectedPlayer?.id">
                                <div class="name-container">
                                    <i class="fa-duotone fa-solid fa-user"></i>
                                    <h3>{{ selectedPlayer.fullName }} 
                                        <span v-if="selectedPlayer.altFirstName || selectedPlayer.altLastName">({{ selectedPlayer.altFirstName ?? selectedPlayer.firstName }} {{ selectedPlayer.altLastName ?? selectedPlayer.lastName }})</span></h3>
                                </div>
                                <div class="player-data-rows">
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Nationality: <span>{{ selectedPlayer.citzenship ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Position: <span>{{ selectedPlayer.positionString ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Date of Birth: <span>{{ selectedPlayer.dob ? new Date(selectedPlayer.dob).toLocaleDateString() : 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Age: <span>{{ selectedPlayer.playerAge ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>League: <span>{{ selectedPlayer.currentLeague ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Team: <span>{{ selectedPlayer.currentTeam ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="either-or">- OR -</p>
                            <k-button class="create-player-btn" @click="onCreatePlayer">Create New Player Record</k-button>
                        </div>
                    </section>

                    <section class="upsert-item__section select-game" v-if="wizardStep === 2">
                        <h2 class="upsert-item__section--title">Select Game</h2>
                        <p class="section-info">Select a game log or manually enter game data in the next step</p>
                        
                        <div class="ep-players-data" v-if="gameLogsLoaded">
                            <div class="input__row select">
                                <label class="input__label">
                                    Game Log
                                    <k-dropdown :data-items="EPPlayerGameLogs"
                                                class="ep-player__dropdown"
                                                v-model="selectedEPGameLog"
                                                :default-item="{ selectDisplay: 'Select Game Log', id: null }"
                                                :text-field="'selectDisplay'"
                                                :data-item-key="'id'"
                                                :disabled="EPPlayerGameLogs.length === 0"></k-dropdown>
                                </label>
                            </div>
                            <div class="no-game-logs" v-if="(gameLogsLoaded && EPPlayerGameLogs.length === 0)">
                                <i class="fa-solid fa-circle-exclamation"></i> 
                                <p class="no-game-logs--title">There are no game logs available for this player</p>
                                <p class="no-game-logs--info">Proceed to the next step</p>
                            </div>
                            <div class="selected-player-data" v-if="selectedEPGameLog?.id">
                                <div class="name-container">
                                    <i class="fa-duotone fa-solid fa-hockey-sticks"></i>
                                    <h3>{{ selectedEPGameLog.homeTeam }} (H) vs {{ selectedEPGameLog.awayTeam }} (A)</h3>
                                </div>
                                <div class="player-data-rows">
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Game Date: <span>{{ selectedEPGameLog.game?.date ? new Date(selectedEPGameLog.game.date).toLocaleDateString() : 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>League: <span>{{ selectedEPGameLog.game?.leagueName ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Season: <span>{{ selectedEPGameLog.game?.seasonType ? getSeasonTypeString(selectedEPGameLog.game.seasonType): 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Final Score: <span>{{ selectedEPGameLog.teamScore }} - {{ selectedEPGameLog.opponentScore }} ({{ selectedEPGameLog.outcome }})</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="upsert-item__section scouting-report" v-if="wizardStep === 3">
                        <h2 class="upsert-item__section--title">Scouting Report</h2>
                        <p class="section-info">All fields required</p>

                        <div class="ep-players-data">
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !newScoutingReport.scout }]">
                                    Report Completed By
                                    <k-input v-model="newScoutingReport.scout" />
                                </label>
                            </div>
                            <div class="input__row trio">
                                <label class="input__label">
                                    Player First Name
                                    <k-input v-model="selectedPlayer.firstName" disabled />
                                </label>
                                <label class="input__label">
                                    Player Last Name
                                    <k-input v-model="selectedPlayer.lastName" disabled />
                                </label>
                                <label class="input__label">
                                    Position
                                    <k-input v-model="selectedPlayer.positionString" disabled />
                                </label>
                            </div>

                            <h2 class="upsert-item__section--title">Game Information</h2>
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !newScoutingReport.teamOne }]">
                                    Home Team
                                    <k-input v-model="newScoutingReport.teamOne" />
                                </label>
                                <label :class="['input__label', {'missing': !newScoutingReport.teamTwo }]">
                                    Away Team
                                    <k-input v-model="newScoutingReport.teamTwo" />
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !newScoutingReport.league }]">
                                    League
                                    <k-input v-model="newScoutingReport.league" />
                                </label>
                                <label :class="['input__label', {'missing': !newScoutingReport.gameDate }]">
                                    Game Date
                                    <k-datepicker v-model="newScoutingReport.gameDate"></k-datepicker>
                                </label>
                            </div>
                            <div class="forward-defense-rows-container" v-if="selectedPlayer.positionString !== 'G'">
                                <div class="input__row quad">
                                    <label :class="['input__label', {'missing': newScoutingReport.goals === null || newScoutingReport.goals === undefined }]">
                                        Goals
                                        <k-numeric-input :placeholder="'Goals'" v-model="newScoutingReport.goals"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': newScoutingReport.assists === null || newScoutingReport.assists === undefined }]">
                                        Assists
                                        <k-numeric-input :placeholder="'Assists'" v-model="newScoutingReport.assists"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': newScoutingReport.points === null || newScoutingReport.points === undefined }]">
                                        Points
                                        <k-numeric-input :placeholder="'Points'" v-model="newScoutingReport.points"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': newScoutingReport.plusMinus === null || newScoutingReport.plusMinus === undefined }]">
                                        +/-
                                        <k-numeric-input :placeholder="'+/-'" v-model="newScoutingReport.plusMinus"></k-numeric-input>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Projection</h2>
                                <div class="input__row trio">
                                    <label :class="['input__label', {'missing': !newScoutingReport.forwardProjection || newScoutingReport.forwardProjection === 'Please Select' }]" v-if="Object.hasOwn(newScoutingReport, 'forwardProjection')">
                                        Forward
                                        <k-dropdown :data-items="forwardProjectionSelects" :default-item="'Please Select'" v-model="newScoutingReport.forwardProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.defenseProjection || newScoutingReport.defenseProjection === 'Please Select' }]" v-if="Object.hasOwn(newScoutingReport, 'defenseProjection')" >
                                        Defense
                                        <k-dropdown :data-items="defenseProjectionSelects" :default-item="'Please Select'" v-model="newScoutingReport.defenseProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.powerPlayProjection || newScoutingReport.powerPlayProjection === 'Please Select' }]">
                                        Power Play
                                        <k-dropdown :data-items="powerPlayProjectionSelects" :default-item="'Please Select'" v-model="newScoutingReport.powerPlayProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.penaltyKillProjection || newScoutingReport.penaltyKillProjection === 'Please Select' }]">
                                        Penalty Kill
                                        <k-dropdown :data-items="penaltyKillProjectionSelects" :default-item="'Please Select'" v-model="newScoutingReport.penaltyKillProjection"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Skating</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.speed || newScoutingReport.speed === 'Please Select' }]">
                                        Speed <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'speed')" @mouseleave="toggleReportDetails($event, 'speed')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.speed}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Ability to win races</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.speed"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.agility || newScoutingReport.agility === 'Please Select' }]">
                                        Agility <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'agility')" @mouseleave="toggleReportDetails($event, 'agility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.agility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Acceleration, first step quickness, stop and go plays</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.agility"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Puck Skill</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.shot || newScoutingReport.shot === 'Please Select' }]">
                                        Shot
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.shot"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.stickHandling || newScoutingReport.stickHandling === 'Please Select' }]">
                                        Stickhandling
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.stickHandling"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Hockey Sense</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.playmakingWithPuck || newScoutingReport.playmakingWithPuck === 'Please Select' }]">
                                        Playmaking <strong>with</strong> the puck <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'playmakingwith')" @mouseleave="toggleReportDetails($event, 'playmakingwith')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.playmakingwith}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Offensive gamesense and ability to generate scoring chances</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.playmakingWithPuck"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.playmakingWithoutPuck || newScoutingReport.playmakingWithoutPuck === 'Please Select' }]">
                                        Playmaking <strong>without</strong> the puck <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'playmakingwithout')" @mouseleave="toggleReportDetails($event, 'playmakingwithout')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.playmakingwithout}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Decisions, reads, awareness</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.playmakingWithoutPuck"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Compete Level</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.consistencyEffort || newScoutingReport.consistencyEffort === 'Please Select' }]">
                                        Consistency and effort <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'consistencyeffort')" @mouseleave="toggleReportDetails($event, 'consistencyeffort')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.consistencyeffort}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-stick-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Corner and wall play, physicality, high stakes</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.consistencyEffort"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row">
                                    <label :class="['input__label', {'missing': !newScoutingReport.consistencyEffortNotes }]">
                                        Why?
                                        <k-textarea v-model="newScoutingReport.consistencyEffortNotes" :rows="4"></k-textarea>
                                    </label>
                                </div>

                                <div v-if="selectedPlayer.positions.some(position => defensePositions.some(p => p === position.position))">
                                    <h2 class="upsert-item__section--title">Defensive Play</h2>
                                    <div class="input__row">
                                        <label :class="['input__label', {'missing': !newScoutingReport.defensivePlayNotes }]">
                                            Pro qualities
                                            <k-textarea v-model="newScoutingReport.defensivePlayNotes" :rows="4"></k-textarea>
                                        </label>
                                    </div>
                                    <div class="input__row pair">
                                        <label :class="['input__label', {'missing': !newScoutingReport.physicality || newScoutingReport.physicality === 'Please Select' }]">
                                            Physicality
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.physicality"></k-dropdown>
                                        </label>
                                        <label :class="['input__label', {'missing': !newScoutingReport.gapControl || newScoutingReport.gapControl === 'Please Select' }]">
                                            Gap Control
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.gapControl"></k-dropdown>
                                        </label>
                                    </div>
                                    <div class="input__row pair">
                                        <label :class="['input__label', {'missing': !newScoutingReport.hardToPlayAgainst || newScoutingReport.hardToPlayAgainst === 'Please Select' }]">
                                            Hard to play against
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.hardToPlayAgainst"></k-dropdown>
                                        </label>
                                        <label :class="['input__label', {'missing': !newScoutingReport.howWellDefend || newScoutingReport.howWellDefend === 'Please Select' }]">
                                            How well does he defend
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.howWellDefend"></k-dropdown>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="goalie-rows-container" v-if="selectedPlayer.positionString === 'G'">
                                <div class="input__row trio">
                                    <label :class="['input__label', {'missing': newScoutingReport.goalsAgainst === null || newScoutingReport.goalsAgainst === undefined }]">
                                        Goals Against
                                        <k-numeric-input :placeholder="'Goals Against'" v-model="newScoutingReport.goalsAgainst"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': newScoutingReport.shotsOnGoal === null || newScoutingReport.shotsOnGoal === undefined }]">
                                        Shots On Goal
                                        <k-numeric-input :placeholder="'Shots on Goal'" v-model="newScoutingReport.shotsOnGoal"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': newScoutingReport.savePercentage === null || newScoutingReport.savePercentage === undefined }]">
                                        Save Percentage
                                        <k-numeric-input :placeholder="'Save %'" v-model="newScoutingReport.savePercentage" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Projection</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.goalieProjection || newScoutingReport.goalieProjection === 'Please Select' }]" v-if="Object.hasOwn(newScoutingReport, 'goalieProjection')">
                                        Goalie
                                        <k-dropdown :data-items="goalieProjectionSelects" :default-item="'Please Select'" v-model="newScoutingReport.goalieProjection"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Analysis</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.skatingAbility || newScoutingReport.skatingAbility === 'Please Select' }]">
                                        Skating Ability <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'skatingAbility')" @mouseleave="toggleReportDetails($event, 'skatingAbility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.skatingAbility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Lateral movement</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.skatingAbility"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.slidingAbility || newScoutingReport.slidingAbility === 'Please Select' }]">
                                        Sliding Ability <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'slidingAbility')" @mouseleave="toggleReportDetails($event, 'slidingAbility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.slidingAbility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Butterfly slides and stops</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.slidingAbility"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.technique || newScoutingReport.technique === 'Please Select' }]">
                                        Technique <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'technique')" @mouseleave="toggleReportDetails($event, 'technique')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.technique}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-stick-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Save selection and timeliness for each position</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.technique"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.saves || newScoutingReport.saves === 'Please Select' }]">
                                        Saves <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'saves')" @mouseleave="toggleReportDetails($event, 'saves')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.saves}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-goal-net"></i>
                                            </div>
                                            <p class="tooltip--text">Rebound placement, body positioning and control</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.saves"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.leadershipQualities || newScoutingReport.leadershipQualities === 'Please Select' }]">
                                        Leadership Qualities <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'leadershipQualities')" @mouseleave="toggleReportDetails($event, 'leadershipQualities')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.leadershipQualities}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-mask"></i>
                                            </div>
                                            <p class="tooltip--text">Behavior on / off-ice if applicable</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.leadershipQualities"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.mentality || newScoutingReport.mentality === 'Please Select' }]">
                                        Mentality <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'mentality')" @mouseleave="toggleReportDetails($event, 'mentality')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.mentality}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Goal streaks, momentum, and ability to handle stress within the game</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.mentality"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.stickHandling || newScoutingReport.stickHandling === 'Please Select' }]">
                                        Stickhandling <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'stickhandling')" @mouseleave="toggleReportDetails($event, 'stickhandling')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.stickhandling}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Shooting, passing, and play development</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.stickHandling"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.functionalReadiness || newScoutingReport.functionalReadiness === 'Please Select' }]">
                                        Functional Readiness <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'functionalReadiness')" @mouseleave="toggleReportDetails($event, 'functionalReadiness')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.functionalReadiness}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-sharp fa-solid fa-person-running"></i>
                                            </div>
                                            <p class="tooltip--text">Preparation, fitness, and conditioning</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.functionalReadiness"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.speed || newScoutingReport.speed === 'Please Select' }]">
                                        Quickness <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'quickness')" @mouseleave="toggleReportDetails($event, 'quickness')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.quickness}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Explosiveness</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.speed"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.mobility || newScoutingReport.mobility === 'Please Select' }]">
                                        Mobility <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'mobility')" @mouseleave="toggleReportDetails($event, 'mobility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.mobility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-goal-net"></i>
                                            </div>
                                            <p class="tooltip--text">RVH, VH, and butterfly width</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.mobility"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.hockeyIQ || newScoutingReport.hockeyIQ === 'Please Select' }]">
                                        Hockey IQ <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'hockeyIQ')" @mouseleave="toggleReportDetails($event, 'hockeyIQ')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.hockeyIQ}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-sharp fa-solid fa-hockey-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Play reading ability, perception of information, and puck pursuit</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="newScoutingReport.hockeyIQ"></k-dropdown>
                                    </label>
                                </div>
                            </div>

                            <h2 class="upsert-item__section--title">Additional Information</h2>
                            <div class="input__row">
                                <label :class="['input__label', {'missing': !newScoutingReport.reportNotes }]">
                                    Notes
                                    <k-textarea v-model="newScoutingReport.reportNotes" :rows="4"></k-textarea>
                                </label>
                            </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !newScoutingReport.playerOfInterest || newScoutingReport.playerOfInterest === 'Please Select' }]">
                                        Player of Interest
                                        <k-dropdown :data-items="yesNoSelects" :default-item="'Please Select'" v-model="newScoutingReport.playerOfInterest"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !newScoutingReport.comparablePlayer }]">
                                        Player Comparable (Optional)
                                        <k-input v-model="newScoutingReport.comparablePlayer" />
                                    </label>
                                </div>
                        </div>
                    </section>
                    <k-action-bar>
                        <div class="action-btns">
                            <k-button class="wizard-prev__btn" v-if="wizardStep >= 1" @click="wizardStep -= 1">Previous</k-button>
                            <div class="action-btns-group">
                                <k-button class="item-save__btn" v-if="wizardStep === wizardSteps.length - 1" @click="onSaveClicked" :disabled="saveReportBtnDisabled">Save</k-button>
                                <k-button class="item-save__btn" v-if="wizardStep < wizardSteps.length - 1" @click="wizardStep += 1" :disabled="!wizardSteps[wizardStep].valid">Next</k-button>
                            </div>
                        </div>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <create-player-dialog :showDialog="showCreateDialog"
                            :player-first-name="searchFirstName"
                            :player-last-name="searchLastName"
                            @dialogclosed="toggleCreateDialog"
                            @playercreated="refreshPlayers"></create-player-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="getPlayerCreationRoute"></confirm-dialog>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Stepper } from '@progress/kendo-vue-layout';
    import { Input, NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { filterBy } from '@progress/kendo-data-query';

    import confirmDialog from './confirmDialog.vue';
    import createPlayerDialog from './createPlayerDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    import playerHelpers from '../helpers/playerHelpers.js';
    import scoutingReportHelpers from '../helpers/scoutingReportHelpers.js';

    export default {
        name: 'createScoutingReportDialog',
        props: ['showDialog'],
        emits: ['dialogclosed', 'reportcreated'],
        data () {
            return {
                allPlayers: [],
                confirmIconClass: '',
                confirmMsg: '',
                defenseScoutingReportModel: {
                    defenseProjection: null, 
                    defensivePlayNotes: null,
                    physicality: null,
                    gapControl: null,
                    hardToPlayAgainst: null,
                    howWellDefend: null,
                },
                EPPlayerGameLogs: [],
                EPPlayers: [],
                EPPlayerSearchLoaded: false,
                errorMsg: '',
                errorTitle: '',
                filteredPlayers: [],
                forwardDefenseScoutingReportModel: {
                    goals: null,
                    assists: null,
                    points: null,
                    plusMinus: null,
                    powerPlayProjection: null,
                    penaltyKillProjection: null,
                    agility: null,
                    shot: null,
                    playmakingWithPuck: null,
                    playmakingWithoutPuck: null,
                    consistencyEffort: null,
                    consistencyEffortNotes: null,
                },
                forwardScoutingReportModel: {
                    forwardProjection: null,

                },
                gameLogsLoaded: false,
                goalieScoutingReportModel: {
                    goalsAgainst: null,
                    shotsOnGoal: null,
                    savePercentage: null,
                    goalieProjection: null,
                    skatingAbility: null,
                    slidingAbility: null,
                    technique: null,
                    saves: null,
                    leadershipQualities: null,
                    mentality: null,
                    functionalReadiness: null,
                    mobility: null,
                    hockeyIQ: null     
                },
                isLoading: false,
                multipleEPPlayerMatches: false,
                multiplePlayerMatches: false,
                newPlayer: {},
                newScoutingReport: {},
                newScoutingReportModel: {
                    playerId: null,
                    teamOne: null,
                    teamTwo: null,
                    league: null,
                    gameDate: null,
                    stickHandling: null,
                    speed: null,
                    reportNotes: null,
                    playerOfInterest: null,
                    comparablePlayer: null,
                    scout: null,            
                },
                notificationMsg: '',
                playerSearchLoaded: false,
                searchFirstName: null,
                searchLastName: null,
                selectedEPGameLog: null,
                selectedEPPlayer: null,
                selectedPlayer: null,
                showConfirmDialog: false,
                showCreateDialog: false,
                showDetails: {
                    speed: false,
                    agility: false,
                    playmakingwith: false,
                    playmakingwithout: false,
                    consistencyeffort: false,
                    skatingAbility: false,
                    slidingAbility: false,
                    technique: false,
                    saves: false,
                    leadershipQualities: false,
                    mentality: false,
                    stickhandling: false,
                    quickness: false,
                    mobility: false,
                    hockeyIQ: false
                },
                showErrorDialog: false,
                successNotification: false,
                wizardStep: 0,
                wizardSteps: [
                    {
                        label: 'Player Search',
                        valid: false
                    },
                    {
                        label: 'Confirm Player',
                        valid: false
                    },
                    {
                        label: 'Select Game',
                        valid: false
                    },
                    {
                        label: 'Scouting Report'
                    }
                ]
            }
        },
        computed: {
            decimalSelects() {
                return scoutingReportHelpers.decimalSelects;
            },
            defensePositions() {
                return scoutingReportHelpers.defensePositions;
            },
            defenseProjectionSelects() {
                return scoutingReportHelpers.defenseProjectionSelects;
            },
            forwardPositions() {
                return scoutingReportHelpers.forwardPositions;
            },
            forwardProjectionSelects() {
                return scoutingReportHelpers.forwardProjectionSelects;
            },
            goaliePositions() {
                return scoutingReportHelpers.goaliePositions;
            },
            goalieProjectionSelects() {
                return scoutingReportHelpers.goalieProjectionSelects;
            },
            newPlayerModel() {
                return playerHelpers.newPlayerModel;
            },
            penaltyKillProjectionSelects() {
                return scoutingReportHelpers.penaltyKillProjectionSelects;
            },
            playerOfInterestSelects() {
                return scoutingReportHelpers.playerOfInterestSelects;
            },
            powerPlayProjectionSelects() {
                return scoutingReportHelpers.powerPlayProjectionSelects;
            },
            saveReportBtnDisabled() {
                let requiredFields = cloneDeep(this.newScoutingReport);
                delete requiredFields.comparablePlayer;

                if (Object.values(requiredFields).some(v => v === null || v === undefined || v === 'Please Select' || v === '')) {
                    return true;
                } 

                return false;
            },
            yesNoSelects() {
                return scoutingReportHelpers.yesNoSelects;
            }
        },
        watch: {
            gameLogsLoaded(val) {
                if (val) {
                    this.wizardSteps[2].valid = true;
                } else {
                    this.wizardSteps[2].valid = false;
                }
            },
            selectedEPPlayer(val) {
                if (val && val.id) {
                    this.wizardSteps[0].valid = true;
                } else {
                    this.wizardSteps[0].valid = false;
                }
            },
            selectedPlayer(val) {
                if (val && val.id) {
                    this.wizardSteps[1].valid = true;
                    this.assignScoutingReport();
                } else {
                    this.wizardSteps[1].valid = false;
                }
            },
            showDialog(val) {
                if (!val) {
                    this.resetDefaults();
                } 
            },
            wizardStep(val) {
                if (val === 1) {
                    this.getPlayers();
                }

                if (val === 2) {
                    if (this.selectedEPPlayer?.id) {
                        this.getPlayerGameLogs();
                    } else {
                        this.gameLogsLoaded = true;
                    }
                }

                if (val === 3 && this.selectedEPGameLog) {
                    this.populateReportFromGameLog();
                }
            }
        },
        methods: {
            assignNewPlayerFields() {
                this.newPlayer.firstName = this.selectedEPPlayer.firstName;
                this.newPlayer.lastName = this.selectedEPPlayer.lastName;
                this.newPlayer.citzenship = this.selectedEPPlayer.nationality?.name;
                this.newPlayer.dob = this.selectedEPPlayer.dateOfBirth ? new Date(this.selectedEPPlayer.dateOfBirth) : null;
                this.newPlayer.positions = this.selectedEPPlayer.position ? [playerHelpers.getPositionFullName(this.selectedEPPlayer.position)] : [];
                this.newPlayer.status = 'Target';
                this.newPlayer.currentLeague = this.selectedEPPlayer.latestStats?.leagueName;
                this.newPlayer.currentTeam = this.selectedEPPlayer.latestStats?.teamName;
                this.newPlayer.nhlRightsTeam = this.selectedEPPlayer.nhlRights?.team?.name;
            },
            assignScoutingReport() {
                this.newScoutingReport = cloneDeep(this.newScoutingReportModel);

                this.newScoutingReport.playerId = this.selectedPlayer.id;

                if (this.selectedPlayer.positions.some(position => this.forwardPositions.some(p => p === position.position))) {
                    this.newScoutingReport = {...this.newScoutingReport, ...this.forwardDefenseScoutingReportModel, ...this.forwardScoutingReportModel};
                }

                if (this.selectedPlayer.positions.some(position => this.defensePositions.some(p => p === position.position))) {
                    this.newScoutingReport = {...this.newScoutingReport, ...this.forwardDefenseScoutingReportModel, ...this.defenseScoutingReportModel};
                }

                if (this.selectedPlayer.positions.some(position => this.goaliePositions.some(p => p === position.position))) {
                    this.newScoutingReport = {...this.newScoutingReport, ...this.goalieScoutingReportModel}
                }
            },
            async createPlayer() {
                this.selectedPlayer = null;
                this.toggleConfirmDialog();

                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreatePlayer(tokenResponse.accessToken, this.newPlayer) 
                    .then(async () => {
                        await this.refreshPlayers();

                        self.isLoading = false;
                        self.showNotification('Player created successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async createScoutingReport(report) {
                let self = this;
                this.isLoading = true;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateScoutingReport(tokenResponse.accessToken, report)
                    .then(async () => {
                        await this.$store.dispatch('getScoutingReports', true);

                        self.isLoading = false;
                        self.showNotification('Report created successfully');

                        self.$emit('reportcreated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            getPlayerCreationRoute() {
                if (this.selectedEPPlayer?.id) {
                    this.newPlayer = cloneDeep(this.newPlayerModel);
                    this.assignNewPlayerFields();
                    this.createPlayer();
                } else {
                    this.toggleConfirmDialog();
                    this.toggleCreateDialog();
                }
            },
            async getPlayerGameLogs() {
                this.gameLogsLoaded = false;

                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.EPGetPlayerGameLogs(tokenResponse.accessToken, this.selectedEPPlayer.id)
                    .then(res => {
                        self.isLoading = false;
                        self.processGameLogs(res.data.data.data);
                    }).catch(error => {
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })

            },
            async getPlayers() {
                this.playerSearchLoaded = false;

                this.isLoading = true;
                await this.$store.dispatch('getPlayers', true);

                this.processPlayers();

                this.playerSearchLoaded = true;
                this.isLoading = false;
            },
            getSeasonTypeString(seasonType) {
                if (seasonType === 'PRESEASON') {
                    return 'Preseason';
                }

                if (seasonType === 'REGULAR_SEASON') {
                    return 'Regular Season';
                }

                if (seasonType === 'POSTSEASON') {
                    return 'Postseason';
                }

                return seasonType;
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCreatePlayer() {
                this.confirmIconClass="fa-solid fa-user";

                if (this.selectedEPPlayer?.id) {
                    this.confirmMsg = `Are you sure you would like to create a new player record for ${this.selectedEPPlayer.name}?`;
                } else {
                    this.confirmMsg = `Are you sure you would like to create a new player record?`;
                }

                this.toggleConfirmDialog();
            },
            onPlayerFilterChange(evt) {
                this.filteredPlayers = filterBy(this.allPlayers, evt.filter);
            },
            onPlayerNotFound() {
                this.selectedEPPlayer = null;
                this.wizardSteps[0].valid = true;
                this.wizardStep = 1;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                let report = cloneDeep(this.newScoutingReport);

                if (this.selectedEPPlayer?.id) {
                    if (this.selectedEPPlayer.alternativeName) {
                        if (this.selectedEPPlayer.firstName !== this.selectedPlayer.firstName || this.selectedEPPlayer.lastName !== this.selectedPlayer.lastName) {
                            report.altFirstName = this.selectedEPPlayer.firstName;
                            report.altLastName = this.selectedEPPlayer.lastName;
                        }
                    }

                    report.eliteProspectsId = this.selectedEPPlayer.id;
                }

                this.createScoutingReport(report);
            },
            async playerSearch() {
                this.EPPlayerSearchLoaded = false;

                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.EPGetPlayersByNameStartsWith(tokenResponse.accessToken, this.searchFirstName, this.searchLastName)
                    .then(res => {
                        if (res.data.data) {
                            self.processEPPlayers(res.data.data.data);
                        }

                        self.EPPlayerSearchLoaded = true;
                        self.isLoading = false;
                    }).catch(error => {
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            populateReportFromGameLog() {
                this.newScoutingReport.teamOne = this.selectedEPGameLog.homeTeam;
                this.newScoutingReport.teamTwo = this.selectedEPGameLog.awayTeam;
                this.newScoutingReport.league = this.selectedEPGameLog.game?.leagueName;
                this.newScoutingReport.gameDate = this.selectedEPGameLog.game?.date ? new Date(this.selectedEPGameLog.game.date) : null;
                this.newScoutingReport.goals = this.selectedEPGameLog.stats?.g ?? 0;
                this.newScoutingReport.assists = this.selectedEPGameLog.stats?.a ?? 0;
                this.newScoutingReport.points = this.selectedEPGameLog.stats?.pts ?? 0;
                this.newScoutingReport.plusMinus = this.selectedEPGameLog.stats?.pm ?? 0;
                this.newScoutingReport.goalsAgainst = this.selectedEPGameLog.stats?.ga ?? 0;
                this.newScoutingReport.shotsOnGoal = this.selectedEPGameLog.stats?.sog ?? 0;
                this.newScoutingReport.savePercentage = this.selectedEPGameLog.stats?.svp ?? 0;
            },
            processGameLogs(logData) {
                logData.forEach(log => {
                    let homeTeam = log.gameType === 'home' ? log.team?.name : log.opponent?.name;
                    let awayTeam = log.gameType === 'home' ? log.opponent?.name : log.team?.name;

                    log.selectDisplay = `${new Date(log.game?.date)?.toLocaleDateString()} - ${homeTeam} (H) vs ${awayTeam} (A)`;
                    log.homeTeam = homeTeam;
                    log.awayTeam = awayTeam;
                });

                this.EPPlayerGameLogs = logData;
                this.gameLogsLoaded = true;
            },
            processEPPlayers(playerData) {
                playerData.forEach(player => {
                    player.selectDisplay = `${player.firstName} ${player.lastName} - ${player.position} - ${player.age} y/o`;
                });

                let playerMatches = playerData.filter(player => (player.firstName.toLowerCase() === this.searchFirstName.trim().toLowerCase() && player.lastName.toLowerCase() === this.searchLastName.trim().toLowerCase()) 
                    || `${this.searchFirstName.trim().toLowerCase()} ${this.searchLastName.trim().toLowerCase()}` === player.alternativeName?.toLowerCase());

                if (playerMatches.length > 0) {
                    this.selectedEPPlayer = playerMatches[0];

                    if (playerMatches.length > 1) {
                        this.multipleEPPlayerMatches = true;
                    } else {
                        this.multipleEPPlayerMatches = false;
                    }
                } else {
                    this.multipleEPPlayerMatches = false;
                    this.selectedEPPlayer = null;
                }

                this.EPPlayers = playerData;
            },
            processPlayers() {
                this.allPlayers = cloneDeep(this.$store.state.players).sort((a, b) => a.fullName.localeCompare(b.fullName));

                this.allPlayers.forEach(player => {
                    player.selectDisplay = `${player.fullName} - ${player.positionString} - ${player.playerAge ?? 'N/A'} y/o`;
                });

                this.filteredPlayers = cloneDeep(this.allPlayers);

                let playerMatches = [];

                if (this.selectedEPPlayer?.id) {
                    playerMatches = this.filteredPlayers.filter(player => (player.fullName.toLowerCase() === this.selectedEPPlayer.name.toLowerCase() || player.fullName.toLowerCase() === this.selectedEPPlayer.alternativeName?.toLowerCase())
                        || player.eliteProspectsId === this.selectedEPPlayer.id);
                } else {
                    if (this.searchFirstName && this.searchLastName) {
                        playerMatches = this.filteredPlayers.filter(player => (player.firstName.toLowerCase() === this.searchFirstName.toLowerCase() && player.lastName.toLowerCase() === this.searchLastName?.toLowerCase()));
                    }
                }

                let likelyMatch;

                if (playerMatches.length > 0) {
                    likelyMatch = playerMatches[0];

                    if (playerMatches.length > 1) {
                        if (this.selectedEPPlayer?.id) {
                            let detailMatch = playerMatches.find(player => player.currentLeague === this.selectedEPPlayer.latestStats?.leagueName || player.currentTeam === this.selectedEPPlayer.latestStats?.teamName);
                            
                            if (detailMatch) {
                                likelyMatch = detailMatch;
                            }
                        } 

                        this.multiplePlayerMatches = true;
                    } else {
                        this.multiplePlayerMatches = false;
                    }
                } else {
                    likelyMatch = null;
                }

                this.selectedPlayer = likelyMatch;
            },
            async refreshPlayers() {
                await this.$store.dispatch('getPlayers', true);
                this.processPlayers();
            },
            resetDefaults() {
                this.EPPlayers = [];
                this.selectedEPPlayer = null;
                this.selectedPlayer = null;
                this.searchFirstName = null;
                this.searchLastName = null;
                this.newPlayer = {};
                this.wizardStep = 0;
                this.gameLogsLoaded = false;
                this.EPPlayerGameLogs = [];
                this.selectedEPGameLog = null;
                this.wizardSteps =  [              
                    {
                        label: 'Player Search',
                        valid: false
                    },
                    {
                        label: 'Confirm Player',
                        valid: false
                    },
                    {
                        label: 'Select Game',
                        valid: false
                    },
                    {
                        label: 'Scouting Report'
                    }
                ],
                this.EPPlayerSearchLoaded = false;
                this.playerSearchLoaded = false;
                this.multipleEPPlayerMatches = false;
                this.multiplePlayerMatches = false;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleCreateDialog() {
                this.showCreateDialog = !this.showCreateDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleReportDetails(evt, detailType) {
                this.showDetails[detailType] = !this.showDetails[detailType];
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-stepper': Stepper,
            'k-input': Input,
            'k-loader': Loader,
            'k-dropdown': DropDownList,
            'confirm-dialog': confirmDialog,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-textarea': TextArea,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'create-player-dialog': createPlayerDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/createScoutingReportDialog.css';
</style>