export default {
    agencyContractColumns: [
        { field: 'contractTitle', title: 'Name' },
        { field: 'playingCommissionPercent', title: 'Player Commission', type: 'number', filter: 'numeric', format: '{0: #.###\\%}'},
        { field: 'endorsementCommissionPercent', title: 'Endorsement Commission', type: 'number', filter: 'numeric', format: '{0: #.###\\%}'},
        { field: 'primaryAgent', title: 'Primary Agent' },
        { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
    ],
    agencyContractType: 'Agency Contract',
    ahlNhlTwoWayContractType: 'AHL/NHL Two Way',
    ahlOneWayContractType: 'AHL One Way',
    ahlTwoWayContractType: 'AHL Two Way',
    allContractColumns: [
        { field: 'contractTitle', title: 'Name' },
        { field: 'contractType', title: 'Type' },
        { field: 'expiration_field', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
    ],
    contractAgentSelects: ['Daniel Milstein', 'Keith McKittrick', 'Patrick Bedell', 'Vladimir Spektor'],
    contractDateFields: ['endorsementSigningDate', 'endorsementExpirationDate', 'expirationDate', 'limitedModifiedDate', 'limitedModifiedStartDate', 'limitedModifiedEndDate', 'nhlContractEndYear', 'nhlContractStartYear', 'nhlExpirationDate', 'spacSigningDate', 'equipmentSigningDate', 'equipmentExpirationDate'],
    contractEquipmentContractSelects: ['BAUER', 'CCM', 'TRUE', 'WARRIOR'],
    contractNhlExpirationStatusSelects: ['RFA', 'RFA ARB Rights', 'UFA'],
    contractNhlTypeSelects: ['ELC', 'NHL One Way', 'AHL One Way', 'AHL/NHL Two Way', 'AHL Two Way', 'ECHL'],
    contractSpcBillingTypeSelects: ['Endorsement', 'Playing Contract', 'Signing Bonus'],
    echlContractType: 'ECHL',
    elcBonusScheduleTypeSelects: ['Schedule A', 'Schedule B'],
    elcContractType: 'ELC',
    elcPerformanceBonusDateFields: ['bonusDate'],
    endorsementContractColumns: [
        { field: 'contractTitle', title: 'Name' },
        { field: 'endorsementCompanyName', title: 'Company' },
        { field: 'endorsementSigningDate', title: 'Signing Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { field: 'endorsementExpirationDate', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { field: 'endorsementAmount', title: 'Endorsement Amount', type: 'number', filter: 'numeric', format: '{0:c0}' },
        { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
    ],
    endorsementContractType: 'Endorsement Contract',
    endorsementContractTypeSelects: ['Autograph', 'Card', 'Other'],
    endorsementPayoutDateFields: ['payoutDate'],
    endorsementProductCreditDateFields: ['endDate', 'startDate'],
    equipmentContractColumns: [
        { field: 'contractTitle', title: 'Name' },
        { field: 'equipmentContract', title: 'Equipment Company' },
        { field: 'equipmentSigningDate', title: 'Signing Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { field: 'equipmentExpirationDate', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
    ],
    equipmentContractType: 'Equipment Contract',
    limitedModifiedNoTradeClauseTypes: [ 'Specified Date', 'Upon Team Notice of Request' ],
    noTradeClauseDateFields: ['dueDate'],
    noTradeClauseStatusSelects: ['Pending', 'Sent'],
    nhlOneWayContractType: 'NHL One Way',
    nhlSigningBonusDateFields: ['bonusDate'],
    nhlTwoWayContractType: 'NHL Two Way',
    performanceBonusDateFields: ['bonusDate'],
    playerContractColumns: [
        { cell: 'hasBonusCell', title: 'Bonus', filterable: false, columnMenu: false, width: '100' },
        { field: 'contractTitle', title: 'Name' },
        { field: 'contractyears_field', title: 'Contract Years' },
        { field: 'nhlContractEndYear', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
        { field: 'nhlContractExpirationStatus', title: 'Expiration Status' },
        { field: 'nhlSigningClub', title: 'Signing Club' },
        { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' },
    ],
    playingContractType: 'Playing Contract',
    getContractExpirationField(contract) {
        switch(contract.contractType) {
            case 'Agency Contract':
                return contract.expirationDate ? new Date(contract.expirationDate) : null;
            case 'Playing Contract':
                return contract.nhlContractEndYear ? new Date(contract.nhlContractEndYear) : null;
            case 'Endorsement Contract':
                return contract.endorsementExpirationDate ? new Date(contract.endorsementExpirationDate) : null;
            case 'Equipment Contract':
                return contract.equipmentExpirationDate ? new Date(contract.equipmentExpirationDate) : null;
        }
    },
    getContractSigningField(contract) {
        switch(contract.contractType) {
            case 'Agency Contract':
                return contract.spacSigningDate ? new Date(contract.spacSigningDate) : null;
            case 'Playing Contract':
                return contract.nhlContractStartYear ? new Date(contract.nhlContractStartYear) : null;
            case 'Endorsement Contract':
                return contract.endorsementSigningDate ? new Date(contract.endorsementSigningDate) : null;
            case 'Equipment Contract':
                return contract.equipmentSigningDate ? new Date(contract.equipmentSigningDate) : null;
        }
    },
    getContractYears(contract) {
        if (!contract.nhlContractStartYear || !contract.nhlContractEndYear) return '';

        return `${contract.nhlContractStartYear.getFullYear()} - ${contract.nhlContractEndYear.getFullYear()}`;
    }
}