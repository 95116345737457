<template>
    <div class="pipeline-view__container detail-view-grid">
        <k-grid :class="[{ 'clickable': rowClickable }]"
            :cell-render="watchListGrid ? 'watchListCellTemplate' : null"
            :data-items="filteredItems"
            :data-item-key="itemIdField"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="agentDashboard || eliteProspectsSearch || nhlTeamDetail ? false : true"
            :filter="filter"
            :column-menu="true"
            :resizable="true"
            :pageable="gridPageable"
            :groupable="gridGroupable"
            :group="group"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:headerTemplate="{ props }"><span :title="props.field.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase() })">{{ props.title }}</span></template>
            <template v-slot:footerTemplate="{ props }">
                <span v-if="props.field?.includes('year1')">{{ $options.filters.formatCurrency(year1Total) }}</span>
                <span v-if="props.field?.includes('year2')">{{ $options.filters.formatCurrency(year2Total) }}</span>
                <span v-if="props.field?.includes('year3')">{{ $options.filters.formatCurrency(year3Total) }}</span>
                <span v-if="props.field?.includes('year4')">{{ $options.filters.formatCurrency(year4Total) }}</span>
                <span v-if="props.field === 'fullName'">Total: {{ filteredItems.length }}</span>
            </template>
            <template v-slot:watchListCellTemplate="{ props }">
                <watch-list-cell :field="props.field"
                        :expanded="props.expanded"
                        :row-type="props.rowType"
                        :level="props.level"
                        :column-index="props.columnIndex"
                        :columns-count="props.columnsCount"
                        :data-item="props.dataItem"
                        :class-name="props.className" />

            </template>
            <template v-slot:watchListPlayed="{ props }">
                <td class="watch-list-played" v-if="props.dataItem.gameLogId && !props.dataItem.didNotPlay"><span><i class="fa-solid fa-circle-check"></i></span></td>
                <td class="watch-list-not-played" v-if="props.dataItem.gameLogId && props.dataItem.didNotPlay"><span><i class="fa-solid fa-circle-xmark"></i></span></td>
            </template>
            <template v-slot:amountYearOne="{ props }">
                <td v-if="props.dataItem.amountYearOne">{{ $options.filters.formatCurrency(props.dataItem.amountYearOne) }}</td>
                <td v-if="!props.dataItem.amountYearOne"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearTwo="{ props }">
                <td v-if="props.dataItem.amountYearTwo">{{ $options.filters.formatCurrency(props.dataItem.amountYearTwo) }}</td>
                <td v-if="!props.dataItem.amountYearTwo"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearThree="{ props }">
                <td v-if="props.dataItem.amountYearThree">{{ $options.filters.formatCurrency(props.dataItem.amountYearThree) }}</td>
                <td v-if="!props.dataItem.amountYearThree"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearFour="{ props }">
                <td v-if="props.dataItem.amountYearFour">{{ $options.filters.formatCurrency(props.dataItem.amountYearFour) }}</td>
                <td v-if="!props.dataItem.amountYearFour"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:projecteddraftround="{ props }">
                <td @click.stop="" v-if="props.dataItem.id !== editedPlayerId || !this.showEditProjRound">
                    <div class="data-cell">
                        {{ props.dataItem.projectedDraftRound }}
                        <k-button class="edit-round__btn" @click.stop="onEditProjRound(props.dataItem)" title="Edit"><i class="fa-solid fa-pencil"></i></k-button>
                    </div>
                </td>
                <td @click.stop="" v-if="props.dataItem.id === editedPlayerId && this.showEditProjRound">
                    <div class="data-cell">
                        <k-numeric-input :placeholder="'Enter round'" v-model="props.dataItem.projectedDraftRound" :format="'0'" :min="1" :max="7"></k-numeric-input>
                        <k-button class="save-round__btn" :disabled="uneditedProjRound === props.dataItem.projectedDraftRound" @click.stop="onSaveProjRound(props.dataItem)" title="Save"><i class="fa-sharp fa-floppy-disk"></i></k-button>
                        <k-button class="cancel-round__btn" @click.stop="onCancelEditProjRound(props.dataItem)" title="Cancel Edit"><i class="fa-sharp fa-solid fa-rotate-left"></i></k-button>
                    </div>
                </td>
            </template>
            <template v-slot:teamName="{ props }">
                <td>
                    <div class="logo-name-container">
                        <img class="team-logo" :src="props.dataItem.teamLogo" />
                        {{ props.dataItem.teamName }}
                    </div>
                </td>
            </template>
            <template v-slot:teamCapHit="{ props }">
                <td class="cap-info">
                    <div class="cap-info-container">
                        {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHit) }}
                        <i class="info-icon fa-regular fa-circle-info" @mouseover="toggleCapChart($event, props.dataItem)" @mouseleave="toggleCapChart"></i>    
                        <div v-bind:class="['cap-chart-tooltip', { 'tooltip--show': selectedCapChartTeam?.teamId === props.dataItem.teamId }]">
                            <bar-chart-component :chart-series="props.dataItem.chartSeries"></bar-chart-component>
                        </div>              
                    </div>
                </td>
            </template>
            <template v-slot:capHitForwards="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitForwards) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitForwards"> | {{ ((props.dataItem.currentRosterAnnualCapHitForwards / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitDefense="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitDefense) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitDefense"> | {{ ((props.dataItem.currentRosterAnnualCapHitDefense / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitLtir="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterLtir) }}
                    <span v-if="props.dataItem.currentRosterLtir"> | {{ ((props.dataItem.currentRosterLtir / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitGoalies="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitGoalies) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitGoalies"> | {{ ((props.dataItem.currentRosterAnnualCapHitGoalies / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitDead="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterDeadCapHit) }}
                    <span v-if="props.dataItem.currentRosterDeadCapHit"> | {{ ((props.dataItem.currentRosterDeadCapHit / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:rosterName="{ props }">
                <td :class="['roster-name', { 'injured': props.dataItem.lastStatus === 'LTIR' || props.dataItem.lastStatus === 'IR' }]">
                    {{ props.dataItem.fullName }}
                    <span :title="`Injured Reserve (${props.dataItem.lastStatus})`" class="injured" v-if="props.dataItem.lastStatus === 'LTIR' || props.dataItem.lastStatus === 'IR'">
                        <i class="fa-solid fa-briefcase-medical"></i> {{ props.dataItem.lastStatus }}  
                    </span>
                </td>
            </template>
            <template v-slot:year1Cap="{ props }">
                <td v-if="props.dataItem.year1Cap">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year1Cap) }}
                        <span class="clause-icons">
                            <span :title="'No Movement Clause'" class="no-move" v-if="props.dataItem.year1NoMovementClause" >
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span :title="'No Trade Clause'" class="no-trade"  v-if="props.dataItem.year1NoTradeClause">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year1ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year1ClauseDetail ? ' - ' + props.dataItem.year1ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year1Cap">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
            </template>
            <template v-slot:year2Cap="{ props }">
                <td v-if="props.dataItem.year2Cap">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year2Cap) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year2NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year2ClauseDetail ? ' - ' + props.dataItem.year2ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year2Cap">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>            </template>
            <template v-slot:year3Cap="{ props }">
                <td v-if="props.dataItem.year3Cap">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year3Cap) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year3NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year3ClauseDetail ? ' - ' + props.dataItem.year3ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Cap && props.dataItem.year2Cap">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
                <td v-if="!props.dataItem.year3Cap && !props.dataItem.year2Cap"></td>
            </template>
            <template v-slot:year4Cap="{ props }">
                <td v-if="props.dataItem.year4Cap">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year4Cap) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year4NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year4ClauseDetail ? ' - ' + props.dataItem.year4ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Cap && props.dataItem.year3Cap">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
                <td v-if="!props.dataItem.year4Cap && !props.dataItem.year3Cap"></td>
            </template>
            <template v-slot:year1Aav="{ props }">
                <td v-if="props.dataItem.year1Aav">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year1Aav) }}
                        <span class="clause-icons">
                            <span :title="'No Movement Clause'" class="no-move" v-if="props.dataItem.year1NoMovementClause" >
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span :title="'No Trade Clause'" class="no-trade"  v-if="props.dataItem.year1NoTradeClause">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year1ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year1ClauseDetail ? ' - ' + props.dataItem.year1ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year1Aav">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
            </template>
            <template v-slot:year2Aav="{ props }">
                <td v-if="props.dataItem.year2Aav">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year2Aav) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year2NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year2ClauseDetail ? ' - ' + props.dataItem.year2ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year2Aav">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
            </template>
            <template v-slot:year3Aav="{ props }">
                <td v-if="props.dataItem.year3Aav">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year3Aav) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year3NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year3ClauseDetail ? ' - ' + props.dataItem.year3ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Aav && props.dataItem.year2Aav">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
                <td v-if="!props.dataItem.year3Aav && !props.dataItem.year2Aav"></td>
            </template>
            <template v-slot:year4Aav="{ props }">
                <td v-if="props.dataItem.year4Aav">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year4Aav) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year4NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year4ClauseDetail ? ' - ' + props.dataItem.year4ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Aav && props.dataItem.year3Aav">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
                <td v-if="!props.dataItem.year4Aav && !props.dataItem.year3Aav"></td>
            </template>
            <template v-slot:year1Salary="{ props }">
                <td v-if="props.dataItem.year1Salary">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year1Salary) }}
                        <span class="clause-icons">
                            <span :title="'No Movement Clause'" class="no-move" v-if="props.dataItem.year1NoMovementClause" >
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span :title="'No Trade Clause'" class="no-trade"  v-if="props.dataItem.year1NoTradeClause">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year1ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year1ClauseDetail ? ' - ' + props.dataItem.year1ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year1Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
            </template>
            <template v-slot:year2Salary="{ props }">
                <td v-if="props.dataItem.year2Salary">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year2Salary) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year2NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year2ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year2ClauseDetail ? ' - ' + props.dataItem.year2ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>  
            </template>
            <template v-slot:year3Salary="{ props }">
                <td v-if="props.dataItem.year3Salary">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year3Salary) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year3NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year3ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year3ClauseDetail ? ' - ' + props.dataItem.year3ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Salary && props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Salary && !props.dataItem.year2Salary"></td>
            </template>
            <template v-slot:year4Salary="{ props }">
                <td v-if="props.dataItem.year4Salary">
                    <span class="season-column">
                        {{ $options.filters.formatCurrency(props.dataItem.year4Salary) }}
                        <span class="clause-icons" >
                            <span :title="'No Movement Clause'" class="no-move"  v-if="props.dataItem.year4NoMovementClause">
                                <i class="fa-solid fa-user-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4NoTradeClause" :title="'No Trade Clause'" class="no-trade">
                                <i class="fa-solid fa-lock"></i>
                            </span>
                            <span  v-if="props.dataItem.year4ModifiedNoTradeClause" :title="`Modified No Trade Clause${props.dataItem.year4ClauseDetail ? ' - ' + props.dataItem.year4ClauseDetail : ''}`" class="mod-trade">
                                <i class="fa-solid fa-unlock"></i>
                            </span>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Salary && props.dataItem.year3Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Salary && !props.dataItem.year3Salary"></td>
            </template>
            <template v-slot:year1SigningBonus="{ props }">
                <td>{{ $options.filters.formatCurrency(props.dataItem.year1SigningBonus) }}</td>
            </template>
            <template v-slot:year2SigningBonus="{ props }">
                <td v-if="props.dataItem.year2Salary">{{ $options.filters.formatCurrency(props.dataItem.year2SigningBonus) }}</td>
                <td v-if="!props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
            </template>
            <template v-slot:year3SigningBonus="{ props }">
                <td v-if="props.dataItem.year3Salary">{{ $options.filters.formatCurrency(props.dataItem.year3SigningBonus) }}</td>
                <td v-if="!props.dataItem.year3Salary && props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Salary && !props.dataItem.year2Salary"></td>
            </template>
            <template v-slot:year4SigningBonus="{ props }">
                <td v-if="props.dataItem.year4Salary">{{ $options.filters.formatCurrency(props.dataItem.year4SigningBonus) }}</td>
                <td v-if="!props.dataItem.year4Salary && props.dataItem.year3Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Salary && !props.dataItem.year3Salary"></td>
            </template>
            <template v-slot:year1PerformanceBonus="{ props }">
                <td>{{ $options.filters.formatCurrency(props.dataItem.year1PerformanceBonus) }}</td>
            </template>
            <template v-slot:year2PerformanceBonus="{ props }">
                <td v-if="props.dataItem.year2Salary">{{ $options.filters.formatCurrency(props.dataItem.year2PerformanceBonus) }}</td>
                <td v-if="!props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
            </template>
            <template v-slot:year3PerformanceBonus="{ props }">
                <td v-if="props.dataItem.year3Salary">{{ $options.filters.formatCurrency(props.dataItem.year3PerformanceBonus) }}</td>
                <td v-if="!props.dataItem.year3Salary && props.dataItem.year2Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year3Salary && !props.dataItem.year2Salary"></td>
            </template>
            <template v-slot:year4PerformanceBonus="{ props }">
                <td v-if="props.dataItem.year4Salary">{{ $options.filters.formatCurrency(props.dataItem.year4PerformanceBonus) }}</td>
                <td v-if="!props.dataItem.year4Salary && props.dataItem.year3Salary">
                    <span :class="[{ 'expiration-block': props.dataItem.expiryStatus }, { 'expiration-ufa': props.dataItem.expiryStatus === 'UFA'}]">{{ props.dataItem.expiryStatus }}
                        <span :title="'ARB Rights'" class="arb-eligible"  v-if="props.dataItem.expiryStatus === 'RFA' && props.dataItem.arbitrationEligible">
                            <i class="fa-solid fa-gavel"></i>
                        </span>
                    </span>
                </td>
                <td v-if="!props.dataItem.year4Salary && !props.dataItem.year3Salary"></td>
            </template>
            <template v-slot:pipelineActions="{ props }"> 
                <div class="pipeline-actions__container">
                    <k-button class="edit-item__btn" v-if="editAction" @click.stop="onEditItem(props.dataItem)" title="Edit"><i class="fa-solid fa-pen-to-square"></i></k-button>
                    <k-button class="delete-item__btn" v-if="deleteAction" @click.stop="onDeleteItem(props.dataItem)" title="Delete"><i class="fa-solid fa-trash"></i></k-button>
                </div>
            </template>
            <template v-slot:watchListActions="{ props }">
                <td v-if="editAction && props.dataItem.gameLogId">
                    <k-button class="edit-watch-list__btn" @click.stop="onEditItem(props.dataItem)" title="Add Misc Note"><i class="fa-solid fa-pen-to-square"></i></k-button>
                </td>
            </template>
            <k-grid-toolbar v-if="!agentDashboard">
                <span class="k-textbox k-grid-search k-display-flex">
                    <k-input :style="{ width: '300px' }"
                                placeholder="Search in all columns..."
                                :value="searchWord"
                                :icon-name="'search'"
                                @input="onSearchChange"></k-input>
                </span>
                <div class="grid-button__group">
                    <k-button title="Export to Excel"
                            class="export-excel__btn" 
                            v-if="allowExport"
                            @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                    </k-button>
                </div>
            </k-grid-toolbar>
        </k-grid>
    </div>
</template>

<script>
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";

    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import barChartComponent from './barChartComponent.vue';
    import watchListCell from './watchListCell.vue';

    import cloneDeep from 'lodash.clonedeep';

    const CustomGroupHeader = (props) => (`League: ${props.group.items[0].league}`);

    export default {
        name: 'detailViewGrid',
        props: ['agentDashboard', 'agentSearchWord', 'allowExport',  'columns', 'deleteAction', 'deleteFunction', 'editAction', 
                'editFunction', 'eliteProspectsSearch', 'exportTitle', 'gridGroupable', 'initialGroup', 'initialSort', 'itemIdField', 'items', 'nhlTeamDetail', 
                'notPageable', 'playerId', 'rowClickable', 'rowClickFunction', 'watchListGrid', 'year1Total', 'year2Total', 'year3Total', 'year4Total'],
        emits: ['filtereditems', 'playerupdated'],
        data() {
            return {
                columnMenu: true,
                editedPlayerId: '',
                filter: null,
                filteredItems: [],
                group: null,
                searchWord: '',
                selectedCapChartTeam: null,
                showEditProjRound: false,
                skip: 0,
                sort: null,
                sortable: { allowUnsort: false, mode: 'single' },
                take: 50,
                total: 0,
                uneditedProjRound: null

            }
        },
        computed: {
            gridPageable() {
                if (this.agentDashboard || this.eliteProspectsSearch || this.notPageable) return null;

                return {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                }
            }
        },
        watch: {
            agentSearchWord(val) {
                this.searchWord = val;
                this.getData();
            },
            items(val) {
                this.filteredItems = cloneDeep(this.items);
                this.getData();
            }
        },
        mounted() {
            if (this.initialSort) {
                this.sort = this.initialSort;
            }

            if (this.gridGroupable) {
                this.group = this.initialGroup;
            }

            this.filteredItems = cloneDeep(this.items);
            this.getData();
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            exportExcel() {
                let filteredItems;

                if (this.searchWord) {
                    filteredItems = this.items.filter(item => {
                        return this.searchObject(item, this.searchWord);
                    });
                } else {
                    filteredItems = cloneDeep(this.items);
                }

                if (this.sort?.length > 0) {
                    filteredItems = orderBy(filteredItems, this.sort);
                }

                if (this.filter) {
                    filteredItems = filterBy(filteredItems, this.filter);
                } 
              
                let filteredData = process(filteredItems, {
                    group: this.watchListGrid ? [{ field: 'leagueOrder' }] : this.group
                }).data;

                let columns = cloneDeep(this.columns);

                columns.forEach(col => {
                    col.cellOptions = { textAlign: 'left' };
                    if (col.field === 'leagueOrder') {
                        col.groupHeader = CustomGroupHeader;
                    }
                });

                saveExcel({
                    data: filteredData,
                    fileName: this.exportTitle,
                    columns: columns,
                    group: this.watchListGrid ? [{ field: 'leagueOrder' }] : this.group
                });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    sort: this.sort,
                    group: this.group,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                if (this.agentDashboard) {
                    this.$emit('filtereditems', this.total);
                }

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.items.filter(item => {
                        return this.searchObject(item, this.searchWord);
                    });
                } else {
                    this.filteredItems = cloneDeep(this.items);
                }

                if (this.sort?.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                } 

                return this.filteredItems;
            },
            onCancelEditProjRound(item) {
                item.projectedDraftRound = this.uneditedProjRound;
                this.editedPlayerId = '';
                this.showEditProjRound = false;
                this.uneditedProjRound = null;
            },
            onDeleteItem(item) {
                this.deleteFunction(item);
            },
            onEditItem(item) {
                this.editFunction(item);
            },
            onEditProjRound(item) {
                this.uneditedProjRound = item.projectedDraftRound;
                this.editedPlayerId = item.id;

                this.showEditProjRound = true;
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onRowClick(evt) {
                if (!this.rowClickable || !this.rowClickFunction) return;
                
                this.rowClickFunction(evt)
            },
            onSaveProjRound(item) {
                this.editedPlayerId = '';
                this.uneditedProjRound = null;
                this.showEditProjRound = false;

                let request = {
                    playerId: item.id,
                    playerUpdated: true,
                    player: item
                }

                this.$emit('playerupdated', request);
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            searchObject(obj, searchWord) {
                for (let key in obj) {
                    if (typeof obj[key] === 'object') {
                        let result = this.searchObject(obj[key], searchWord);

                        if (result) return result;
                    } else {
                        if (obj[key]?.toString().toLowerCase().includes(searchWord.toLowerCase())) {
                            return true;
                        }
                    }
                }

                return false;
            },
            toggleCapChart(evt, team = null) {
                if (team) {
                    this.selectedCapChartTeam = team;
                } else {
                    this.selectedCapChartTeam = null;
                }
            }
        },
        filters: {
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            },
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-input': Input,
            'k-button': Button,
            'k-numeric-input': NumericTextBox,
            'bar-chart-component': barChartComponent,
            'watch-list-cell': watchListCell
        }
    }
</script>

<style>
    @import '/src/assets/css/detailViewGrid.css';
</style>