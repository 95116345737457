<template>
    <div class="upload-documents single">
        <transition name="dialogade">
            <k-dialog v-if="showDialog" :class="['default__dialog', 'upload-documents-dialog', { 'file-detail': uploadRequest.length > 0 }]">
                <div class="icon__container">
                    <i class="fa-solid fa-upload"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">Upload Document</h1>
                </div>
                <div class="upsert-item__container create-association">
                    <section class="upsert-item__section">
                        <div class="section__content">
                            <div class="dropdown-upload__container">
                                <div class="player-documents__dropdown-container">
                                    <div class="file-detail__row">
                                        <div class="file-detail__item">
                                            <label>
                                                Select Document
                                                <k-dropdown :data-items="filteredDocuments"
                                                            v-model="selectedDocument"
                                                            :text-field="'documentName'"
                                                            :filterable="true"
                                                            @filterchange="onDocumentFilterChange"></k-dropdown>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <p class="document-selection--or">- OR -</p>
                                <div class="player-documents__upload-container">
                                    <div class="file-detail__row">
                                        <div class="file-detail__item">
                                            <label>
                                                Upload New Document
                                                <k-upload :files="files"
                                                        :multiple="false"
                                                        :batch="false"
                                                        :showActionButtons="false"
                                                        :autoUpload="false"
                                                        @add="onFileAdd"
                                                        @remove="onFileRemove"></k-upload>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <k-fade :appear="uploadRequest.length > 0 || selectedDocument.documentId != null" :class="['upload-detail__animation', { 'active': uploadRequest.length > 0 || selectedDocument.documentId != null }]">
                                <div v-if="uploadRequest.length > 0">
                                    <p class="field--required">*Required</p>
                                    <div v-for="file in uploadRequest" :key="file.documentPath" class="file-detail__container">
                                        <div class="file-detail__row">
                                            <div class="file-detail__item">
                                                <label>
                                                    *Document Name 
                                                    <k-input v-model="file.documentName" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="file-detail__row">
                                            <div class="file-detail__item">
                                                <label>
                                                    Document Description
                                                    <k-input v-model="file.documentDescription" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="selectedDocument.documentId != null">
                                    <p class="field--required">*Required</p>
                                    <div class="file-detail__container">
                                        <div class="file-detail__row">
                                            <div class="file-detail__item">
                                                <label>
                                                    *Document Name
                                                    <k-input v-model="selectedDocument.documentName" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="file-detail__row">
                                            <div class="file-detail__item">
                                                <label>
                                                    Document Description
                                                    <k-input v-model="selectedDocument.documentDescription" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </k-fade>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" v-if="uploadMode" @click="onUploadFiles" :disabled="uploadFilesBtnDisabled">Upload</k-button>
                        <k-button class="item-save__btn" v-if="editMode" @click="onEditFiles" :disabled="uploadFilesBtnDisabled">Save</k-button>

                        <k-button @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
                <div class="loader-container" v-if="isLoading">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input } from "@progress/kendo-vue-inputs";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Upload } from '@progress/kendo-vue-upload';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import { filterBy } from '@progress/kendo-data-query';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import documentHelpers from '../helpers/documentHelpers';

    export default {
        name: 'uploadDocumentsDialog',
        props: ['contractId', 'currentDocument', 'defaultFolderName', 'identityId', 'identityType', 'playerId', 'reportId', 'showDialog'],
        emits: ['dialogclosed', 'documentsuploaded'],
        data () {
            return {
                allDocuments: [],
                documentRootFolders: [],
                editMode: false,
                errorMsg: '',
                errorTitle: '',
                files: [],
                filteredDocuments: [],
                isLoading: false,
                notificationMsg: '',
                selectedDocument: { documentName: 'Select Document', documentId: null },
                showErrorDialog: false,
                successNotification: false,
                uploadFolderDropdownValue: null,
                uploadMode: false,
                uploadRequest: []
            }
        },
        computed: {
            documentFolderNames() {
                return documentHelpers.documentFolderNames;
            },
            uploadFilesBtnDisabled() {
                if (this.uploadMode) {
                    let isFiles = this.files && this.files.length > 0;
                    let isFolders = this.uploadRequest.every(file => file.documentFolder && file.documentFolder !== 'Select Folder');
                    let isDocumentNames = this.uploadRequest.every(file => file.documentName);

                    return !(isFiles && isFolders && isDocumentNames);
                }

                if (this.editMode) {
                    return !(this.selectedDocument.documentId && this.selectedDocument.documentName);
                }

                return true;
            }
        },
        watch: {
            async showDialog(val) {
                if (val) {
                    this.isLoading = true;
                    await this.getDocumentRootFolders();
                    await this.getDocuments();
                    this.isLoading = false;

                    this.$nextTick(() => {
                        this.addDropzoneHoverEvent()
                    });
                } else {
                    this.resetDefaults();
                }
            },
            selectedDocument(val) {
                if (val.documentId) {
                    this.files = [];
                    this.uploadRequest = [];
                    this.uploadMode = false;
                    this.editMode = true;
                }
            }
        },
        methods: {
            addDropzoneHoverEvent() {
                let dropzone = document.querySelector('.k-dropzone');
                
                if (dropzone) {
                    dropzone.addEventListener('dragenter', () => {
                        dropzone.classList.add('hovered');
                    });

                    dropzone.addEventListener('dragleave', () => {
                        dropzone.classList.remove('hovered');
                    });

                    dropzone.addEventListener('dragover', (evt) => {
                        evt.preventDefault();
                    });
                }
            },
            formatUploadRequest() {
                Promise.all(this.files.map(async(file) => {
                    let selectedDocumentFolder = null;

                    let rawFile = file.getRawFile();

                    let fileBase64 = await this.getFileBase64(rawFile);

                    return {
                        base64File: fileBase64.split(',')[1],
                        documentFullName: rawFile.name,
                        documentExtension: file.extension,
                        documentName: rawFile.name.substring(0, rawFile.name.lastIndexOf('.')) || rawFile.name,
                        documentType: rawFile.type,
                        documentPath: `/${rawFile.name}`,
                        documentFolder: this.defaultFolderName,
                        documentDescription: '',
                        folderError: false,
                        selectedDocumentFolder: selectedDocumentFolder
                    };
                })).then(data => {
                    this.uploadRequest = data;
                })
            },
            async getDocumentRootFolders() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDocumentRootFolders(tokenResponse.accessToken)
                    .then((res) => {
                        self.documentRootFolders = res.data.data ?? [];
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });

            },
            async getDocuments() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerDocuments(tokenResponse.accessToken, this.playerId)
                    .then((res) => {
                        self.allDocuments = res.data.data ?? [];
                        self.filteredDocuments = cloneDeep(self.allDocuments);

                        if (self.currentDocument) {
                            let currentDocument = self.allDocuments.find(doc => doc.documentId === self.currentDocument.documentId);

                            if (currentDocument) {
                                self.selectedDocument = cloneDeep(currentDocument);
                                self.editMode = true;
                            }
                        }

                    }).catch(error => {
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });

            },
            getFileBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });
            }, 
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDocumentFilterChange(evt) {
                this.filteredDocuments = filterBy(this.allDocuments, evt.filter);
            },
            onEditFiles() {
                let request = cloneDeep(this.selectedDocument);

                let fileCodeAndExtension = request.documentPath.split('~')[1];
                request.documentFullName = `${this.selectedDocument.documentName}~${fileCodeAndExtension}`;
                request.documentPath = `${this.defaultFolderName}/${this.selectedDocument.documentName}~${fileCodeAndExtension}`;
                request.documentFolder = this.defaultFolderName;

                if (request.documentFolder !== this.selectedDocument.documentFolder) {
                    request.isMoved = true;
                    let folder = this.documentRootFolders.find(folder => folder.name === this.defaultFolderName);

                    if (folder) {
                        request.newFolderId = folder.id;
                    }
                }

                let document = this.allDocuments.find(doc => doc.documentId === request.documentId);
                
                if (document && document.documentName !== this.selectedDocument.documentName) {
                    request.isRenamed = true;
                }

                this.isLoading = true;

                if (this.defaultFolderName === this.documentFolderNames.contractsFolderName) {
                    this.updateContractDocument(request);
                }

                if (this.defaultFolderName === this.documentFolderNames.identityDocumentsFolderName) {
                    this.updateIdentityDocument(request);
                }

                if (this.defaultFolderName === this.documentFolderNames.picturesFolderName) {
                    this.updatePictureDocument(request);
                }

                if (this.defaultFolderName === this.documentFolderNames.scoutingReportsFolderName) {
                    this.updateScoutingDocument(request);
                }
            },
            onFileAdd(evt) {
                this.selectedDocument = { documentName: 'Select Document', documentId: null };
                this.editMode = false;
                this.uploadMode = true;

                this.files = evt.newState;
                this.formatUploadRequest();
            },
            onFileRemove(evt) {
                this.files = evt.newState;
                this.uploadRequest = [];
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onUploadFiles() {
                this.isLoading = true;

                this.uploadRequest.forEach(file => {
                    let fileCode = this.playerId.split('-').join('');
                    file.documentFullName = `${file.documentName}~${fileCode}${file.documentExtension}`;
                    file.documentPath = `${file.documentFolder}/${file.documentName}~${fileCode}${file.documentExtension}`;
                });

                if (this.defaultFolderName === this.documentFolderNames.contractsFolderName) {
                    this.uploadContractDocuments(this.uploadRequest);
                }

                if (this.defaultFolderName === this.documentFolderNames.identityDocumentsFolderName) {
                    this.uploadIdentityDocuments(this.uploadRequest);
                }

                if (this.defaultFolderName === this.documentFolderNames.picturesFolderName) {
                    this.uploadPictureDocuments(this.uploadRequest);
                }

                if (this.defaultFolderName === this.documentFolderNames.scoutingReportsFolderName) {
                    this.uploadScoutingDocuments(this.uploadRequest);
                }
            },
            resetDefaults() {
                this.files = [];
                this.selectedDocument = { documentName: 'Select Document', documentId: null };
                this.uploadRequest = [];
                this.editMode = false;
                this.uploadMode = false;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async updateContractDocument(document) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateContractDocument(tokenResponse.accessToken, document, this.contractId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document updated successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });

            },
            async updatePictureDocument(document) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdatePictureDocument(tokenResponse.accessToken, document)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Picture updated successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async uploadPictureDocuments(documentsData) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UploadPictureDocument(tokenResponse.accessToken, documentsData, this.playerId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Picture uploaded successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async uploadContractDocuments(documentsData) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UploadContractDocument(tokenResponse.accessToken, documentsData, this.playerId, this.contractId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document uploaded successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async updateIdentityDocument(document) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateIdentityDocument(tokenResponse.accessToken, document, this.identityId, this.identityType)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document updated successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });

            },
            async uploadIdentityDocuments(documentsData) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UploadIdentityDocument(tokenResponse.accessToken, documentsData, this.playerId, this.identityId, this.identityType)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document uploaded successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async updateScoutingDocument(document) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateScoutingDocument(tokenResponse.accessToken, document, this.reportId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document updated successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });

            },
            async uploadScoutingDocuments(documentsData) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UploadScoutingDocument(tokenResponse.accessToken, documentsData, this.playerId, this.reportId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Document uploaded successfully');

                        self.$emit('documentsuploaded', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            wizardChange(evt) {
                this.wizardStep = evt.value;
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade, 
            'k-upload': Upload,
            'k-input': Input,
            'k-dropdown': DropDownList
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/uploadDocumentsDialog.css';
    @import '/src/assets/css/upsertDocumentDialog.css';
</style>