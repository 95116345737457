<template>
    <div class="bar-chart-component">
        <k-chart>
            <k-chart-legend :position="'bottom'" :orientation="'horizontal'" />
            <k-chart-series>
                <k-chart-series-item  v-for="series in chartSeries" 
                    :key="series.title"
                    :name="series.title"
                    :type="'bar'"
                    :stack="{ type: '100%' }"
                    :data-items="series.dataItems">
                </k-chart-series-item>
            </k-chart-series>
        </k-chart>
    </div>
</template>

<script>
    import {
        Chart,
        ChartSeries,
        ChartSeriesItem,
        ChartLegend
    } from "@progress/kendo-vue-charts";

    import 'hammerjs';

    export default {
        name: 'barChartComponent',
        props: ['chartSeries'],
        data() {
            return {
            }
        },
        components: {
            'k-chart': Chart,
            'k-chart-series': ChartSeries,
            'k-chart-series-item': ChartSeriesItem,
            'k-chart-legend': ChartLegend
        }
    }
</script>

<style>
    @import '/src/assets/css/barChartComponent.css';
</style>

