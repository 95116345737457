<template>
    <div class="dashboard-puckpedia-component">
        <div class="puckpedia-data-container">
            <h3 class="title-container" v-if="!teamId">{{ componentTitle ?? 'NHL News' }}</h3>
            <h3 class="title-container" v-if="teamId"><i class="fa-sharp-duotone fa-solid fa-newspaper"></i> {{ componentTitle ?? 'NHL News' }}</h3>

            <k-dropdown v-if="!hideDropdown" class="news-category__dropdown" :data-items="newsCategorySelects" v-model="selectedNewsCategory"></k-dropdown>
            <div class="data-cards__container" v-if="filteredDashboardData.length > 0">
                <div class="data-card" v-for="item in filteredDashboardData" v-bind:key="item.eventId" :id="item.eventId">
                    <div class="card-header-row">
                        <h4 class="header-text">{{ item.eventDate.toLocaleDateString('en-US', { month: 'short'}).toUpperCase() }}  {{ item.eventDate.getDate() }}  {{ item.eventDate.getFullYear() }}
                        </h4>
                        <div class="header-badge">{{ item.eventType?.charAt(0).toUpperCase() + item.eventType?.slice(1) }}</div>
                    </div>

                    <div class="data-card-detail detail--injury" v-if="item.eventType === 'injury'">
                        <div class="logo-column">
                            <img class="team-logo" :src="item.teamLogo" />
                        </div>
                        <div class="detail-column">
                            <div class="detail-header">
                                <h5>{{ item.fullPlayerName }}</h5>
                            </div>
                            <p class="card-subtitle">{{ item.injuryStatus.toUpperCase() }} | {{ item.injuryType.toUpperCase() }}</p>
                            <p class="detail-body">{{ item.details }}</p>
                        </div>
                    </div>

                    <div class="data-card-detail detail--signing" v-if="item.eventType === 'signing'">
                        <div class="logo-column">
                            <img class="team-logo" :src="item.teamLogo" />
                        </div>
                        <div class="detail-column">
                            <div class="detail-header">
                                <h5>{{ item.fullPlayerName }}</h5>
                            </div>
                            <p class="card-subtitle">{{ item.team.toUpperCase() }}</p>
                            <p class="ext" v-if="item.contractDetail === 'future'">EXTENSION</p>
                            <div class="card-row">
                                <div class="row-item">
                                    <p>{{ item.contractType }} | {{ item.contractLength }} yrs</p>
                                </div>
                                <div class="row-item">
                                    <p>Cap Hit</p>
                                </div>
                            </div>
                            <div class="card-row">
                                <div class="row-item bordered">
                                    <p>{{ $options.filters.formatCurrency(item.contractValue) }}</p>
                                </div>
                                <div class="row-item bordered">
                                    <p>{{ $options.filters.formatCurrency(item.capHit) }}</p>
                                </div>
                            </div>
                            <div class="card-row">
                                <p>{{ item.fullAgentName }} <span v-if="item.agency"> | {{ item.agency }}</span></p>
                            </div>
                        </div>
                    </div>

                    <div class="data-card-detail detail--trade" v-if="item.eventType === 'trade'">
                        <div class="card-row trade-header">
                            <img class="team-logo" :src="item.tradeToLogo" />
                            <i class="fa-solid fa-right-left"></i>
                            <img class="team-logo" :src="item.tradeFromLogo" />
                        </div>
                        <p class="detail-body">{{ item.details }}</p>
                    </div>

                    <div class="data-card-footer">
                        <p @click="openPuckPediaLink(item.puckPediaLink)">View More <i class="fa-solid fa-angle-right"></i></p>                  
                    </div>
                </div>
            </div>

        </div>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog } from '@progress/kendo-vue-dialogs';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'dashboardPuckPediaComponent',
        props: ['componentTitle', 'dashboardDataCategory', 'hideDropdown', 'teamId'],
        data () {
            return {
                dashboardData: {
                    all: [],
                    injuries: [],
                    signings: [],
                    trades: []
                },
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                newsCategorySelects: ['All', 'Injuries', 'Signings', 'Trades'],
                selectedNewsCategory: 'All',
                showErrorDialog: false
            }
        },
        computed: {
            filteredDashboardData() {
                return this.dashboardData[`${this.selectedNewsCategory.toLowerCase()}`];
            }
        },
        watch: {
            selectedNewsCategory(val) {
                if (this.filteredDashboardData.length === 0) return;

                this.$nextTick(() => {
                    let newestEvent = document.getElementById(this.filteredDashboardData[0].eventId);

                    if (newestEvent) {
                        newestEvent.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                });
            }
        },
        async created() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        await auth.login();
                    }
                }

                if (this.teamId) {
                    this.selectedNewsCategory = this.dashboardDataCategory ?? 'All';
                    
                    if (this.dashboardDataCategory === 'Signings') {
                        return this.getPuckPediaSigningsByTeam(this.teamId);
                    }

                    if (this.dashboardDataCategory === 'Trades') {
                        return this.getPuckPediaTradesByTeam(this.teamId);
                    }

                    if (this.dashboardDataCategory === 'Injuries') {
                        return this.getPuckPediaInjuriesByTeam(this.teamId);
                    }

                    this.getPuckPediaDashboardByTeam(this.teamId);
                } else {
                    this.getPuckPediaDashboard();
                }
            });
        },
        methods: {
            assignDashboardData(data) {
                if (!this.dashboardDataCategory) {
                    [...data.injuries, ...data.signings, ...data.trades].forEach(event => {
                        event.eventDate = new Date(event.eventDate);
                    });

                    this.dashboardData.all = [...data.injuries, ...data.signings, ...data.trades].sort((a, b) => b.eventDate - a.eventDate);
                    this.dashboardData.injuries = data.injuries;
                    this.dashboardData.signings = data.signings;
                    this.dashboardData.trades = data.trades;
                } else {
                    data.forEach(event => {
                        event.eventDate = new Date(event.eventDate);
                    });

                    this.dashboardData[`${this.dashboardDataCategory.toLowerCase()}`] = data;
                }
            },
            async getPuckPediaDashboard() {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaDashboard(tokenResponse.accessToken)
                    .then((res) => {
                        self.assignDashboardData(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPuckPediaDashboardByTeam(teamId) {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaDashboardByTeam(tokenResponse.accessToken, teamId)
                    .then((res) => {
                        self.assignDashboardData(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPuckPediaInjuriesByTeam(teamId) {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaInjuriesByTeam(tokenResponse.accessToken, teamId)
                    .then((res) => {
                        self.assignDashboardData(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPuckPediaSigningsByTeam(teamId) {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaSigningsByTeam(tokenResponse.accessToken, teamId)
                    .then((res) => {
                        self.assignDashboardData(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPuckPediaTradesByTeam(teamId) {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaTradesByTeam(tokenResponse.accessToken, teamId)
                    .then((res) => {
                        self.assignDashboardData(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            openPuckPediaLink(link) {
                window.open(link, '_blank');
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        filters: {
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-loader': Loader,
            'k-dialog': Dialog
        }
    }
</script>

<style>
    @import '/src/assets/css/dashboardPuckPediaComponent.css';
</style>