<template>
    <div class="update-scouting-report">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog create-scouting-report-dialog full-length">
                <div class="icon__container">
                    <i class="fa-solid fa-clipboard-list"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <h1 class="dialog--title">Scouting Report <span v-if="editedReport">- {{ editedReport.fullName }}</span></h1>
                <div class="upsert-item__container create-scouting-report">
                    <section class="upsert-item__section scouting-report">
                        <h2 class="upsert-item__section--title">Scouting Report</h2>
                        <p class="section-info">All fields required</p>

                        <div class="ep-players-data" v-if="selectedPlayer && editedReport">
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !editedReport.scout }]">
                                    Report Completed By
                                    <k-input v-model="editedReport.scout" />
                                </label>
                            </div>
                            <div class="input__row trio">
                                <label class="input__label">
                                    Player First Name
                                    <k-input v-model="selectedPlayer.firstName" disabled />
                                </label>
                                <label class="input__label">
                                    Player Last Name
                                    <k-input v-model="selectedPlayer.lastName" disabled />
                                </label>
                                <label class="input__label">
                                    Position
                                    <k-input v-model="selectedPlayer.positionString" disabled />
                                </label>
                            </div>

                            <h2 class="upsert-item__section--title">Game Information</h2>
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !editedReport.teamOne }]">
                                    Home Team
                                    <k-input v-model="editedReport.teamOne" />
                                </label>
                                <label :class="['input__label', {'missing': !editedReport.teamTwo }]">
                                    Away Team
                                    <k-input v-model="editedReport.teamTwo" />
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label :class="['input__label', {'missing': !editedReport.league }]">
                                    League
                                    <k-input v-model="editedReport.league" />
                                </label>
                                <label :class="['input__label', {'missing': !editedReport.gameDate }]">
                                    Game Date
                                    <k-datepicker v-model="editedReport.gameDate"></k-datepicker>
                                </label>
                            </div>
                            <div class="forward-defense-rows-container" v-if="selectedPlayer.positionString !== 'G'">
                                <div class="input__row quad">
                                    <label :class="['input__label', {'missing': editedReport.goals === null || editedReport.goals === undefined }]">
                                        Goals
                                        <k-numeric-input :placeholder="'Goals'" v-model="editedReport.goals"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': editedReport.assists === null || editedReport.assists === undefined }]">
                                        Assists
                                        <k-numeric-input :placeholder="'Assists'" v-model="editedReport.assists"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': editedReport.points === null || editedReport.points === undefined }]">
                                        Points
                                        <k-numeric-input :placeholder="'Points'" v-model="editedReport.points"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': editedReport.plusMinus === null || editedReport.plusMinus === undefined }]">
                                        +/-
                                        <k-numeric-input :placeholder="'+/-'" v-model="editedReport.plusMinus"></k-numeric-input>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Projection</h2>
                                <div class="input__row trio">
                                    <label :class="['input__label', {'missing': !editedReport.forwardProjection || editedReport.forwardProjection === 'Please Select' }]" v-if="selectedPlayer.positions.some(position => forwardPositions.some(p => p === position.position))">
                                        Forward
                                        <k-dropdown :data-items="forwardProjectionSelects" :default-item="'Please Select'" v-model="editedReport.forwardProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.defenseProjection || editedReport.defenseProjection === 'Please Select' }]" v-if="selectedPlayer.positions.some(position => defensePositions.some(p => p === position.position))">
                                        Defense
                                        <k-dropdown :data-items="defenseProjectionSelects" :default-item="'Please Select'" v-model="editedReport.defenseProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.powerPlayProjection || editedReport.powerPlayProjection === 'Please Select' }]">
                                        Power Play
                                        <k-dropdown :data-items="powerPlayProjectionSelects" :default-item="'Please Select'" v-model="editedReport.powerPlayProjection"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.penaltyKillProjection || editedReport.penaltyKillProjection === 'Please Select' }]">
                                        Penalty Kill
                                        <k-dropdown :data-items="penaltyKillProjectionSelects" :default-item="'Please Select'" v-model="editedReport.penaltyKillProjection"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Skating</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.speed || editedReport.speed === 'Please Select' }]">
                                        Speed <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'speed')" @mouseleave="toggleReportDetails($event, 'speed')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.speed}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Ability to win races</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.speed"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.agility || editedReport.agility === 'Please Select' }]">
                                        Agility <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'agility')" @mouseleave="toggleReportDetails($event, 'agility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.agility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Acceleration, first step quickness, stop and go plays</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.agility"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Puck Skill</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.shot || editedReport.shot === 'Please Select' }]">
                                        Shot
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.shot"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.stickHandling || editedReport.stickHandling === 'Please Select' }]">
                                        Stickhandling
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.stickHandling"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Hockey Sense</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.playmakingWithPuck || editedReport.playmakingWithPuck === 'Please Select' }]">
                                        Playmaking <strong>with</strong> the puck <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'playmakingwith')" @mouseleave="toggleReportDetails($event, 'playmakingwith')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.playmakingwith}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Offensive gamesense and ability to generate scoring chances</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.playmakingWithPuck"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.playmakingWithoutPuck || editedReport.playmakingWithoutPuck === 'Please Select' }]">
                                        Playmaking <strong>without</strong> the puck <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'playmakingwithout')" @mouseleave="toggleReportDetails($event, 'playmakingwithout')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.playmakingwithout}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Decisions, reads, awareness</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.playmakingWithoutPuck"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Compete Level</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.consistencyEffort || editedReport.consistencyEffort === 'Please Select' }]">
                                        Consistency and effort <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'consistencyeffort')" @mouseleave="toggleReportDetails($event, 'consistencyeffort')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.consistencyeffort}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-stick-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Corner and wall play, physicality, high stakes</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.consistencyEffort"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row">
                                    <label :class="['input__label', {'missing': !editedReport.consistencyEffortNotes }]">
                                        Why?
                                        <k-textarea v-model="editedReport.consistencyEffortNotes" :rows="4"></k-textarea>
                                    </label>
                                </div>

                                <div v-if="selectedPlayer.positions.some(position => defensePositions.some(p => p === position.position))">
                                    <h2 class="upsert-item__section--title">Defensive Play</h2>
                                    <div class="input__row">
                                        <label :class="['input__label', {'missing': !editedReport.defensivePlayNotes }]">
                                            Pro qualities
                                            <k-textarea v-model="editedReport.defensivePlayNotes" :rows="4"></k-textarea>
                                        </label>
                                    </div>
                                    <div class="input__row pair">
                                        <label :class="['input__label', {'missing': !editedReport.physicality || editedReport.physicality === 'Please Select' }]">
                                            Physicality
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.physicality"></k-dropdown>
                                        </label>
                                        <label :class="['input__label', {'missing': !editedReport.gapControl || editedReport.gapControl === 'Please Select' }]">
                                            Gap Control
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.gapControl"></k-dropdown>
                                        </label>
                                    </div>
                                    <div class="input__row pair">
                                        <label :class="['input__label', {'missing': !editedReport.hardToPlayAgainst || editedReport.hardToPlayAgainst === 'Please Select' }]">
                                            Hard to play against
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.hardToPlayAgainst"></k-dropdown>
                                        </label>
                                        <label :class="['input__label', {'missing': !editedReport.howWellDefend || editedReport.howWellDefend === 'Please Select' }]">
                                            How well does he defend
                                            <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.howWellDefend"></k-dropdown>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="goalie-rows-container" v-if="selectedPlayer.positionString === 'G'">
                                <div class="input__row trio">
                                    <label :class="['input__label', {'missing': editedReport.goalsAgainst === null || editedReport.goalsAgainst === undefined }]">
                                        Goals Against
                                        <k-numeric-input :placeholder="'Goals Against'" v-model="editedReport.goalsAgainst"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': editedReport.shotsOnGoal === null || editedReport.shotsOnGoal === undefined }]">
                                        Shots On Goal
                                        <k-numeric-input :placeholder="'Shots on Goal'" v-model="editedReport.shotsOnGoal"></k-numeric-input>
                                    </label>
                                    <label :class="['input__label', {'missing': editedReport.savePercentage === null || editedReport.savePercentage === undefined }]">
                                        Save Percentage
                                        <k-numeric-input :placeholder="'Save Percentage'" v-model="editedReport.savePercentage" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Projection</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.goalieProjection || editedReport.goalieProjection === 'Please Select' }]">
                                        Goalie
                                        <k-dropdown :data-items="goalieProjectionSelects" :default-item="'Please Select'" v-model="editedReport.goalieProjection"></k-dropdown>
                                    </label>
                                </div>

                                <h2 class="upsert-item__section--title">Player Analysis</h2>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.skatingAbility || editedReport.skatingAbility === 'Please Select' }]">
                                        Skating Ability <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'skatingAbility')" @mouseleave="toggleReportDetails($event, 'skatingAbility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.skatingAbility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Lateral movement</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.skatingAbility"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.slidingAbility || editedReport.slidingAbility === 'Please Select' }]">
                                        Sliding Ability <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'slidingAbility')" @mouseleave="toggleReportDetails($event, 'slidingAbility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.slidingAbility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Butterfly slides and stops</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.slidingAbility"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.technique || editedReport.technique === 'Please Select' }]">
                                        Technique <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'technique')" @mouseleave="toggleReportDetails($event, 'technique')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.technique}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-stick-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Save selection and timeliness for each position</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.technique"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.saves || editedReport.saves === 'Please Select' }]">
                                        Saves <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'saves')" @mouseleave="toggleReportDetails($event, 'saves')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.saves}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-goal-net"></i>
                                            </div>
                                            <p class="tooltip--text">Rebound placement, body positioning and control</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.saves"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.leadershipQualities || editedReport.leadershipQualities === 'Please Select' }]">
                                        Leadership Qualities <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'leadershipQualities')" @mouseleave="toggleReportDetails($event, 'leadershipQualities')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.leadershipQualities}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-mask"></i>
                                            </div>
                                            <p class="tooltip--text">Behavior on / off-ice if applicable</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.leadershipQualities"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.mentality || editedReport.mentality === 'Please Select' }]">
                                        Mentality <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'mentality')" @mouseleave="toggleReportDetails($event, 'mentality')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.mentality}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Goal streaks, momentum, and ability to handle stress within the game</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.mentality"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.stickHandling || editedReport.stickHandling === 'Please Select' }]">
                                        Stickhandling <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'stickhandling')" @mouseleave="toggleReportDetails($event, 'stickhandling')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.stickhandling}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-hockey-sticks"></i>
                                            </div>
                                            <p class="tooltip--text">Shooting, passing, and play development</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.stickHandling"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.functionalReadiness || editedReport.functionalReadiness === 'Please Select' }]">
                                        Functional Readiness <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'functionalReadiness')" @mouseleave="toggleReportDetails($event, 'functionalReadiness')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.functionalReadiness}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-sharp fa-solid fa-person-running"></i>
                                            </div>
                                            <p class="tooltip--text">Preparation, fitness, and conditioning</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.functionalReadiness"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.speed || editedReport.speed === 'Please Select' }]">
                                        Quickness <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'quickness')" @mouseleave="toggleReportDetails($event, 'quickness')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.quickness}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-ice-skate"></i>
                                            </div>
                                            <p class="tooltip--text">Explosiveness</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.speed"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.mobility || editedReport.mobility === 'Please Select' }]">
                                        Mobility <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'mobility')" @mouseleave="toggleReportDetails($event, 'mobility')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.mobility}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-solid fa-goal-net"></i>
                                            </div>
                                            <p class="tooltip--text">RVH, VH, and butterfly width</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.mobility"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.hockeyIQ || editedReport.hockeyIQ === 'Please Select' }]">
                                        Hockey IQ <i class="fa-solid fa-circle-info tooltip" @mouseover="toggleReportDetails($event, 'hockeyIQ')" @mouseleave="toggleReportDetails($event, 'hockeyIQ')"></i>
                                        <div v-bind:class="['report__tooltip', { 'tooltip--show': showDetails.hockeyIQ}]">
                                            <div class="tooltip--icon">
                                                <i class="fa-sharp fa-solid fa-hockey-puck"></i>
                                            </div>
                                            <p class="tooltip--text">Play reading ability, perception of information, and puck pursuit</p>
                                        </div>
                                        <k-dropdown :data-items="decimalSelects" :default-item="'Please Select'" v-model="editedReport.hockeyIQ"></k-dropdown>
                                    </label>
                                </div>
                            </div>

                            <h2 class="upsert-item__section--title">Additional Information</h2>
                            <div class="input__row">
                                <label :class="['input__label', {'missing': !editedReport.reportNotes }]">
                                    Notes
                                    <k-textarea v-model="editedReport.reportNotes" :rows="4"></k-textarea>
                                </label>
                            </div>
                                <div class="input__row pair">
                                    <label :class="['input__label', {'missing': !editedReport.playerOfInterest || editedReport.playerOfInterest === 'Please Select' }]">
                                        Player of Interest
                                        <k-dropdown :data-items="yesNoSelects" :default-item="'Please Select'" v-model="editedReport.playerOfInterest"></k-dropdown>
                                    </label>
                                    <label :class="['input__label', {'missing': !editedReport.comparablePlayer }]">
                                        Player Comparable (Optional)
                                        <k-input v-model="editedReport.comparablePlayer" />
                                    </label>
                                </div>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked" :disabled="saveReportBtnDisabled">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input, NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import cloneDeep from 'lodash.clonedeep';
    import isEqual from 'lodash.isequal';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    import scoutingReportHelpers from '../helpers/scoutingReportHelpers.js';

    export default {
        name: 'updateScoutingReportDialog',
        props: ['selectedReport', 'showDialog'],
        emits: ['dialogclosed', 'reportupdated'],
        data() {
            return {
                defenseOnlyFields: ['defenseProjection', 'defensivePlayNotes', 'physicality', 'gapControl', 
                    'hardToPlayAgainst', 'howWellDefend'],
                defenseForwardOnlyFields: ['agility', 'assists', 'consistencyEffort', 'consistencyEffortNotes',
                    'penaltyKillProjection', 'playmakingWithPuck', 'playmakingWithoutPuck', 'powerPlayProjection',
                    'shot', 'goals', 'assists', 'points', 'plusMinus'],
                editedReport: null,
                errorMsg: '',
                errorTitle: '',
                forwardOnlyFields: ['forwardProjection'],
                goalieOnlyFields: ['goalieProjection', 'skatingAbility', 'slidingAbility', 'technique', 
                    'saves', 'leadershipQualities', 'mentality', 'functionalReadiness', 'mobility', 'hockeyIQ',
                    'goalsAgainst', 'shotsOnGoal', 'savePercentage'],
                isLoading: false,
                notificationMsg: '',
                originalReport: null,
                selectedPlayer: null,
                showDetails: {
                    speed: false,
                    agility: false,
                    playmakingwith: false,
                    playmakingwithout: false,
                    consistencyeffort: false,
                    skatingAbility: false,
                    slidingAbility: false,
                    technique: false,
                    saves: false,
                    leadershipQualities: false,
                    mentality: false,
                    stickhandling: false,
                    quickness: false,
                    mobility: false,
                    hockeyIQ: false
                },
                showErrorDialog: false,
                successNotification: false
            }
        },
        computed: {
            decimalSelects() {
                return scoutingReportHelpers.decimalSelects;
            },
            defensePositions() {
                return scoutingReportHelpers.defensePositions;
            },
            defenseProjectionSelects() {
                return scoutingReportHelpers.defenseProjectionSelects;
            },
            forwardPositions() {
                return scoutingReportHelpers.forwardPositions;
            },
            forwardProjectionSelects() {
                return scoutingReportHelpers.forwardProjectionSelects;
            },
            goaliePositions() {
                return scoutingReportHelpers.goaliePositions;
            },
            goalieProjectionSelects() {
                return scoutingReportHelpers.goalieProjectionSelects;
            },
            penaltyKillProjectionSelects() {
                return scoutingReportHelpers.penaltyKillProjectionSelects;
            },
            playerOfInterestSelects() {
                return scoutingReportHelpers.playerOfInterestSelects;
            },
            powerPlayProjectionSelects() {
                return scoutingReportHelpers.powerPlayProjectionSelects;
            },
            saveReportBtnDisabled() {
                if (this.editedReport && this.originalReport) {
                    let requiredFields = cloneDeep(this.editedReport);
                    delete requiredFields.comparablePlayer;

                    if (Object.values(requiredFields).some(v => v === null || v === undefined || v === 'Please Select' || v === '') || isEqual(this.originalReport, this.editedReport)) {
                        return true;
                    } 
                }

                return false;
            },
            yesNoSelects() {
                return scoutingReportHelpers.yesNoSelects;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.editedReport = cloneDeep(this.selectedReport);
                    this.getAssociatedPlayer();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignScoutingReport() {
                delete this.editedReport.altFirstName;
                delete this.editedReport.altLastName;
                delete this.editedReport.eliteProspectsId;
                delete this.editedReport.associatedDocumentId;

                if (this.selectedPlayer.positions.some(position => this.forwardPositions.some(p => p === position.position))) {
                    [...this.defenseOnlyFields, ...this.goalieOnlyFields].forEach(field => {
                        delete this.editedReport[field];
                    });
                }

                if (this.selectedPlayer.positions.some(position => this.defensePositions.some(p => p === position.position))) {
                    [...this.forwardOnlyFields, ...this.goalieOnlyFields].forEach(field => {
                        delete this.editedReport[field];
                    });
                }

                if (this.selectedPlayer.positions.some(position => this.goaliePositions.some(p => p === position.position))) {
                    [...this.forwardOnlyFields, ...this.defenseOnlyFields, ...this.defenseForwardOnlyFields].forEach(field => {
                        delete this.editedReport[field];
                    });
                }

                this.originalReport = cloneDeep(this.editedReport);
            },
            async getAssociatedPlayer() {
                this.isLoading = true;
                await this.$store.dispatch('getPlayers', true);

                this.selectedPlayer = this.$store.state.players.find(player => player.id === this.editedReport.playerId);
                this.isLoading = false;

                if (this.selectedPlayer) {
                    this.assignScoutingReport();
                }
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                this.editedReport.altFirstName = this.selectedReport.altFirstName;
                this.editedReport.altLastName = this.selectedReport.altLastName;
                this.editedReport.eliteProspectsId = this.selectedReport.eliteProspectsId;
                this.editedReport.associatedDocumentId = this.selectedReport.associatedDocumentId;

                this.updateScoutingReport(cloneDeep(this.editedReport));
            },
            resetDefaults() {
                this.selectedPlayer = null;
                this.editedReport = null;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleReportDetails(evt, detailType) {
                this.showDetails[detailType] = !this.showDetails[detailType];
            },
            async updateScoutingReport(report) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateScoutingReport(tokenResponse.accessToken, report) 
                    .then(async () => {
                        await this.$store.dispatch('getScoutingReports', true);

                        self.isLoading = false;
                        self.showNotification('Report updated successfully');

                        self.$emit('reportupdated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-input': Input,
            'k-dropdown': DropDownList,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-textarea': TextArea,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade
        }
    }
</script>

<style>
    @import '/src/assets/css/createScoutingReportDialog.css';
    @import '/src/assets/css/updateScoutingReportDialog.css';
</style>