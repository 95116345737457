<template>
    <div class="manage-watch-list">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog manage-watch-list-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-people-group"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 55" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,21.74H169.3a19.32,19.32,0,0,1-38.64,0H10c-5.52,0-10,2.53-10,5.66V50H300V27.4C300,24.27,295.52,21.74,290,21.74Z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                </div>
                <div class="manage-watch-list__header">
                    <h1 class="dialog--title">Manage Watch List</h1>
                </div>
                <div class="upsert-item__container watch-list">
                    <div class="watch-list-body">
                        <div class="watch-list-column create-player">
                            <h2 class="watch-list-column-title"><i class="fa-duotone fa-solid fa-user"></i> Add Player</h2>
                            <elite-prospects-search-component :detail-dialog-view="false" :on-clear-search="playerAdded" @playerselected="onPlayerSelected" />
                            <div :class="['selected-player-data', { 'with-image': selectedEPPlayer.imageUrl }]" v-if="selectedEPPlayer?.id">
                                <div class="name-container" v-if="!selectedEPPlayer.imageUrl">
                                    <i class="fa-duotone fa-solid fa-user"></i>
                                    <h3>{{ selectedEPPlayer.firstName }} {{ selectedEPPlayer.lastName }} <span v-if="selectedEPPlayer.alternativeName">({{ selectedEPPlayer.alternativeName }})</span></h3>
                                </div>
                                <div class="name-container" v-if="selectedEPPlayer.imageUrl">
                                    <img class="selected-player-img" :src="selectedEPPlayer.imageUrl" />
                                </div>
                                <div class="player-data-rows">
                                    <div class="player-data-row player-name" v-if="selectedEPPlayer.imageUrl">
                                        <h3>{{ selectedEPPlayer.firstName }} {{ selectedEPPlayer.lastName }} <span v-if="selectedEPPlayer.alternativeName">({{ selectedEPPlayer.alternativeName }})</span></h3>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Nationality: <span>{{ selectedEPPlayer.nationality?.name ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Position: <span>{{ selectedEPPlayer.position ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>Date of Birth: <span>{{ selectedEPPlayer.dateOfBirth ? new Date(selectedEPPlayer.dateOfBirth).toLocaleDateString() : 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Age: <span>{{ selectedEPPlayer.age ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                    <div class="player-data-row">
                                        <div class="row-item">
                                            <p>League: <span>{{ selectedEPPlayer.latestStats?.leagueName ?? 'N/A' }}</span></p>
                                        </div>
                                        <div class="row-item">
                                            <p>Team: <span>{{ selectedEPPlayer.latestStats?.teamName ?? 'N/A' }}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <k-button v-if="selectedEPPlayer?.id" @click="onAddPlayer" class="add-player-btn">Add Player to Watch List</k-button>
                            <k-fade :appear="showPlayerDuplicateAlert">
                                <p class="duplicate-player-alert" v-if="showPlayerDuplicateAlert"><i class="fa-solid fa-circle-exclamation"></i> Player is already added to watch list</p>
                            </k-fade>

                            <div class="search-players-container" v-if="!selectedEPPlayer?.id && !this.loadingPlayer">
                                <i class="fa-sharp-duotone fa-solid fa-magnifying-glass"></i>
                                <p class="search-players-info">No Player Selected</p>
                            </div>
                        </div>
                        <div class="watch-list-column player-list">
                            <div class="watch-list-title-search">
                                <h2 class="watch-list-column-title"><i class="fa-sharp-duotone fa-thin fa-binoculars"></i> Watch List</h2>
                                <k-input class="player-search-input" v-model="playerSearch" :placeholder="'Search Players by Name'" />
                            </div>
                            <k-listview :data-items="filteredWatchListPlayers"
                                        :item="'player'"
                                        :header="'watchListHeader'"
                                        :footer="'watchListFooter'"
                                        class="watch-list-listview">
                                <template v-slot:watchListHeader>
                                    <k-listview-header>
                                        <div class="watch-list-row">
                                            <div class="list-row-item name">
                                                <p>Player</p>
                                            </div>
                                            <div class="list-row-item age">
                                                <p>Age</p>
                                            </div>
                                            <div class="list-row-item position">
                                                <p>Pos</p>
                                            </div>
                                            <div class="list-row-item actions">
                                            </div>
                                        </div>
                                    </k-listview-header>
                                </template>
                                <template v-slot:player="{ props }">
                                    <div class="watch-list-row">
                                        <div class="list-row-item name">
                                            <p>{{ props.dataItem.lastName }}, {{ props.dataItem.firstName }}</p>
                                        </div>
                                        <div class="list-row-item age">
                                            <p>{{ props.dataItem.playerAge }}</p>
                                        </div>
                                        <div class="list-row-item position">
                                            <p>{{ props.dataItem.position }}</p>
                                        </div>
                                        <div class="list-row-item actions" title="Remove Player" @click="onDeletePlayer(props.dataItem)">
                                            <i class="fa-duotone fa-solid fa-trash-can"></i>
                                        </div>
                                    </div>
                                </template>   
                                <template v-slot:watchListFooter>
                                    <k-listview-footer>
                                        Total: {{ watchListPlayers?.length }}
                                    </k-listview-footer>
                                </template>   
                            </k-listview>
                        </div>
                    </div>
                    <k-action-bar>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Close</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deletePlayer"></confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-vue-listview';
    import { Input } from "@progress/kendo-vue-inputs";

    import eliteProspectsSearchComponent from './eliteProspectsSearchComponent.vue';
    import confirmDialog from '../components/confirmDialog.vue';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'manageWatchListDialog',
        props: ['showDialog'],
        emits: ['dialogclosed', 'playersupdated'],
        data() {
            return {
                confirmIconClass: '',
                confirmMsg: '',
                deletePlayerId: null,
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                loadingPlayer: false,
                notificationMsg: '',
                playerAdded: false,
                playerSearch: null,
                selectedEPPlayer: null,
                showConfirmDialog: false,
                showErrorDialog: false,
                showPlayerDuplicateAlert: false,
                successNotification: false,
                watchListPlayers: []
            }
        },
        computed: {
            filteredWatchListPlayers() {
                if (this.playerSearch) {
                    return this.watchListPlayers.filter(player => player.firstName?.toLowerCase().includes(this.playerSearch.toLowerCase()) 
                                                        || player.lastName?.toLowerCase().includes(this.playerSearch.toLowerCase()));
                }

                return this.watchListPlayers;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.getWatchListPlayers()
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async addWatchListPlayer(request) {
                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateWatchListPlayer(tokenResponse.accessToken, request)
                    .then((res) => {
                        self.watchListPlayers = res.data?.data ?? [];
                        self.isLoading = false;

                        self.$emit('playersupdated');
                        self.selectedEPPlayer = null;
                        self.playerAdded = true;

                        self.$nextTick(() => {
                            self.playerAdded = false;
                        })

                        self.showNotification('Player successfully added');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async deletePlayer() {
                let self = this;
                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteWatchListPlayer(tokenResponse.accessToken, this.deletePlayerId)
                    .then((res) => {
                        self.watchListPlayers = res.data?.data ?? [];
                        self.isLoading = false;

                        self.$emit('playersupdated');

                        self.showNotification('Player successfully removed');
                        self.deletePlayerId = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getEPPlayerById(playerId) {
                this.loadingPlayer = true;
                this.selectedEPPlayer = null;

                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.EPGetPlayerById(tokenResponse.accessToken, playerId)
                    .then((res) => {
                        self.selectedEPPlayer = res.data?.data?.data;

                        self.isLoading = false;
                        self.loadingPlayer = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async getWatchListPlayers() {
                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetWatchListPlayers(tokenResponse.accessToken)
                    .then((res) => {
                        self.watchListPlayers = res.data?.data ?? [];
                        self.isLoading = false;
                        console.log(self.watchListPlayers)
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            onAddPlayer() {
                if (this.watchListPlayers.some(player => player.eliteProspectsId === this.selectedEPPlayer.id)) {
                    return this.showPlayerDuplicateAlert = true;
                }

                let request = {
                    firstName: this.selectedEPPlayer.firstName,
                    lastName: this.selectedEPPlayer.lastName,
                    eliteProspectsId: this.selectedEPPlayer.id,
                    isGoalie: this.selectedEPPlayer.position === 'G',
                    dateOfBirth: this.selectedEPPlayer.dateOfBirth,
                    position: this.selectedEPPlayer.position
                }

                this.addWatchListPlayer(request);
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDeletePlayer(player) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletePlayerId = player.eliteProspectsId;
                this.confirmMsg = `Are you sure you would like to remove ${player.firstName} ${player.lastName} from the watch list?`;
                this.toggleConfirmDialog()
            },
            onPlayerSelected(playerId) {
                this.showPlayerDuplicateAlert = false;

                this.getEPPlayerById(playerId);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;
                this.loadingPlayer = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                this.$emit('paymentEntered', this.payment);
            },
            resetDefaults() {
                this.isLoading = false;
                this.loadingPlayer = false;
                this.showPlayerDuplicateAlert = false;
                this.selectedEPPlayer = null;
                this.watchListPlayers = [];
                this.playerSearch = null;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'elite-prospects-search-component': eliteProspectsSearchComponent,
            'k-listview': ListView,
            'k-listview-header': ListViewHeader,
            'k-listview-footer': ListViewFooter,
            'confirm-dialog': confirmDialog,
            'k-input': Input
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/manageWatchListDialog.css';
</style>