<template>
    <div class="upsert-watch-list-game-log-misc">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog upsert-watch-list-game-log-misc-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-notes"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">Add Notes</h1>
                </div>

                <div class="upsert-item__container game-log-misc-notes">
                    <div class="notes-body">
                        <div class="notes-textarea-container">
                            <k-textarea :rows="4" v-model="notes" :placeholder="'Add your note here'" />
                        </div>
                    </div>
                    <k-action-bar>
                        <k-button class="item-save__btn" :disabled="saveNotesBtnDisabled" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { TextArea } from "@progress/kendo-vue-inputs";

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'upsertWatchListGameLogMiscDialog',
        props: ['gameLog', 'showDialog'],
        emits: ['dialogclosed', 'notesupdated'],
        data() {
            return {
                notes: null
            }
        },
        computed: {
            saveNotesBtnDisabled() {
                return this.notes === this.gameLog?.misc;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.notes = this.gameLog.misc;
                } else {
                    this.notes = null;
                }
            }
        },
        methods: {
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onSaveClicked() {
                let request = {
                    gameLogId: this.gameLog.gameLogId,
                    misc: this.notes
                };

                this.$emit('notesupdated', request);
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-textarea': TextArea
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/upsertWatchListGameLogMiscDialog.css';
</style>