<template>
    <div class="draft-column">
        <h5 class="draft-year">{{ draftDetail.draftYear }}</h5>
        <div class="draft-rows-container">
            <div class="draft-row row-header">
                <div class="draft-row-item round">
                    <p>Round</p>
                </div>
                <div class="draft-row-item pick">
                    <p>Pick</p>
                </div>
                <div class="draft-row-item pick-from">
                    <p>From</p>
                </div>
                <div class="draft-row-item pick-status">
                    <p>Status</p>
                </div>
                <div class="draft-row-item trade-detail">
                    <p>Detail</p>
                </div>
            </div>
            <div class="draft-rows">
                <div :class="['draft-row', { 'traded-away': draft.original_team_id === teamId }]" v-for="draft in draftDetail.drafts" :key="draft.draft_pick_id">
                    <div class="draft-row-item round">
                        <p>{{ draft.round }}</p>
                    </div>
                    <div class="draft-row-item pick">
                        <p>{{ draft.round_position }}</p>
                    </div>
                    <div class="draft-row-item pick-from">
                        <img v-if="!draft.originalTeamLogo" :src="draft.toTeamLogo" />
                        <img v-if="draft.originalTeamLogo" :src="draft.originalTeamLogo" />
                        <i v-if="draft.originalTeamLogo" class="fa-sharp fa-regular fa-arrow-right"></i>    
                        <img v-if="draft.originalTeamLogo" :src="draft.toTeamLogo" />
                    </div>
                    <div class="draft-row-item pick-status">
                        <p>{{ draft.status }}</p>
                    </div>
                    <div class="draft-row-item trade-detail">
                        <p v-if="draft.trade"><span class="trade-date">{{ draft.tradeDate.toLocaleDateString() }}</span> | {{ draft.tradeDetail }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'nhlTeamDraftColumn',
        props: ['draftDetail', 'teamId'],
        data() {
            return {

            }
        }
    }
</script>

<style></style>