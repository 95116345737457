<template>
    <div class="player-detail-view">
        <img :src="playerPicUrl" v-if="playerPicUrl" :class="['player-pic-expanded', { 'show': showPlayerPic }]"/>

        <view-header :title="uneditedPlayerDetail.firstName ? `${uneditedPlayerDetail.firstName} ${uneditedPlayerDetail.lastName}` : ''" :player-pic="playerPicUrl">
            <template v-slot:viewicon>
                <i v-if="!playerPicUrl" class="fa-duotone fa-user"></i>
                <img class="profile-pic" v-if="playerPicUrl" :src="playerPicUrl" @mouseenter="showPlayerPic=true" @mouseleave="showPlayerPic=false" />
            </template>
            <template v-slot:viewactions>
                <div class="action-btns">
                    <k-button class="save-changes__btn" :disabled="saveChangesBtnDisabled" @click="onUpdatePlayer"><i class="fa-sharp fa-solid fa-floppy-disk"></i> Save Changes</k-button>
                    <k-button class="discard-changes__btn" :disabled="saveChangesBtnDisabled" @click="onDiscardChanges"><i class="fa-sharp fa-solid fa-rotate-left"></i> Discard Changes</k-button>
                    <k-button v-if="!agentDetail" class="discard-changes__btn" @click="onBackToPlayers"><i class="fa-solid fa-left"></i> Back to Players</k-button>
                    <k-button v-if="agentDetail" class="discard-changes__btn" @click="onBackToDashboard"><i class="fa-solid fa-left"></i> Back to Dashboard</k-button>
                </div>
            </template>
        </view-header>
        <div class="player-detail__container">
            <section class="upsert-item__container player-info">
                <div class="upsert-item__section player-information">
                    <h2 class="upsert-item__section--title first">Player Information <span class="field--required">*Required</span></h2>
                    <div class="input__row pair">
                        <label class="input__label">
                            <k-checkbox v-model="playerDetail.isActive"></k-checkbox>
                            Active
                        </label>
                        <label v-if="!playerPicUrl" class="input__label document-action__btn"><span @click="toggleUploadDocumentDialog"><i class="fa-sharp fa-solid fa-plus"></i> Add Player Picture</span></label>
                        <label v-if="playerPicUrl" class="input__label document-action__btn"><span @click="toggleUploadDocumentDialog"><i class="fa-sharp fa-solid fa-plus"></i> Edit Player Picture</span></label>
                    </div>
                    <div class="input__row trio">
                        <label class="input__label">
                            *First Name
                            <k-input v-model="playerDetail.firstName" @keydown="player$.playerDetail.firstName.$reset()" :class="[{ 'error': player$.playerDetail.firstName.$error }]" />
                            <span class="item-input--vuelidate-error" v-if="player$.playerDetail.firstName.$error"><i>First name is required</i></span>
                        </label>
                        <label class="input__label">
                            *Last Name
                            <k-input v-model="playerDetail.lastName" @keydown="player$.playerDetail.lastName.$reset()" :class="[{ 'error': player$.playerDetail.lastName.$error }]" />
                            <span class="item-input--vuelidate-error" v-if="player$.playerDetail.lastName.$error"><i>Last Name is required</i></span>
                        </label>
                        <label class="input__label">
                            *Status
                            <k-dropdown :data-items="statusSelects" v-model="playerDetail.status" :class="[{ 'error': player$.playerDetail.status.$error }]"></k-dropdown>
                            <span class="item-input--vuelidate-error" v-if="player$.playerDetail.status.$error"><i>Status is required</i></span>
                        </label>
                    </div>
                    <div class="input__row pair">
                        <label class="input__label">
                            Date of Birth
                            <k-datepicker v-model="playerDetail.dob_formatted"></k-datepicker>
                        </label>
                        <label class="input__label">
                            Citizenship
                            <k-input v-model="playerDetail.citzenship" />
                        </label>
                    </div>
                    <div class="input__row pair">
                        <label class="input__label">
                            Draft Eligible Year
                            <k-numeric-input :placeholder="'Enter year'" v-model="draftEligibleYear" :format="'0'" :min="1000" @change="onDraftEligibleYearEntered"></k-numeric-input>
                        </label>
                        <label class="input__label">
                            Projected Draft Round
                            <k-numeric-input :placeholder="'Enter round'" v-model="playerDetail.projectedDraftRound" :format="'0'" :min="1" :max="7"></k-numeric-input>
                        </label>
                    </div>
                    <div class="input__row pair">
                        <label class="input__label">
                            *Position
                            <k-multiselect :data-items="positionSelects" v-model="playerPositions"></k-multiselect>
                            <span class="item-input--vuelidate-error" v-if="player$.playerPositions.$error"><i>Position is required</i></span>
                        </label>
                        <label class="input__label">
                            AAV
                            <k-numeric-input v-if="!playerDetail.aav" v-model="playerDetail.annualContractValue" :format="'c2'" :min="0"></k-numeric-input>
                            <k-numeric-input v-if="playerDetail.aav" disabled v-model="playerDetail.aav" :format="'c2'" :min="0"></k-numeric-input>
                        </label>
                    </div>
                </div>

                <div class="upsert-item__section billing-information">
                    <h2 class="upsert-item__section--title">Billing Information</h2>
                    <div class="input__row pair">
                        <label class="input__label">
                            Billing Contact Name
                            <k-input v-model="playerDetail.billingContactName" />
                        </label>
                        <label class="input__label">
                            Billing Contact Email
                            <k-input v-model="playerDetail.billingContactEmail" @keydown="player$.playerDetail.billingContactEmail.$reset()" :class="[{ 'error': player$.playerDetail.billingContactEmail.$error }]" />
                            <span class="item-input--vuelidate-error" v-if="player$.playerDetail.billingContactEmail.$error"><i>Email is invalid</i></span>
                        </label>
                    </div>
                    <div class="input__row pair">
                        <label class="input__label">
                            Phone
                            <k-input v-model="playerDetail.billingContactPhone" @keydown="formatContactPhoneNumberInput" @input="player$.playerDetail.billingContactPhone.$reset()" :class="[{ 'error': player$.playerDetail.billingContactPhone.$error }]" />
                            <span class="item-input--vuelidate-error" v-if="player$.playerDetail.billingContactPhone.$error"><i>Phone number is invalid</i></span>
                        </label>
                        <label class="input__label">
                            Ext
                            <k-input v-model="playerDetail.billingContactPhoneExt" />
                        </label>
                    </div>
                    <div class="input__row pair">
                        <label class="input__label">
                            Billing Address
                            <k-input v-model="playerDetail.billingAddress" />
                        </label>
                        <label class="input__label">
                            Additional (Suite, Unit, etc)
                            <k-input v-model="playerDetail.billingContactAddressAdditional" />
                        </label>
                    </div>
                    <div class="input__row trio">
                        <label class="input__label">
                            City
                            <k-input v-model="playerDetail.billingContactCity" />
                        </label>
                        <label class="input__label">
                            State
                            <k-dropdown :data-items="stateSelectsAbbr"
                                        v-model="playerDetail.billingContactState"
                                        :default-item="'Select State'"
                                        @change="defaultDropdownSelected($event, 'billingContactState', 'Select State')"></k-dropdown>
                        </label>
                        <label class="input__label">
                            Zip
                            <k-input v-model="playerDetail.billingContactZip" />
                        </label>
                    </div>
                </div>

                <div class="upsert-item__section team-information">
                    <h2 class="upsert-item__section--title">Team Information</h2>
                    <div class="input__row pair">
                        <label class="input__label">
                            Current League
                            <k-input v-model="playerDetail.currentLeague" />
                        </label>
                        <label class="input__label">
                            Current Team
                            <k-input v-model="playerDetail.currentTeam" />
                        </label>
                    </div>
                </div>

                <div class="upsert-item__section team-information">
                    <h2 class="upsert-item__section--title">NHL Draft</h2>
                    <div class="input__row pair">
                        <label class="input__label">
                            NHL Team
                            <k-input disabled v-model="playerDetail.nhlRightsTeamDrafted" />
                        </label>
                        <label class="input__label">
                            Year Drafted
                            <k-numeric-input disabled v-model="playerDetail.nhlRightsYearDrafted" :format="'0'"></k-numeric-input>
                        </label>
                    </div>
                </div>

                <div class="upsert-item__section team-information">
                    <h2 class="upsert-item__section--title">NHL Rights</h2>
                    <div class="input__row pair">
                        <label class="input__label">
                            NHL Team
                            <k-input disabled v-model="playerDetail.nhlRightsTeam" />
                        </label>
                        <label class="input__label">
                            Start Date
                            <k-datepicker disabled v-model="playerDetail.nhlRightsDate_formatted"></k-datepicker>
                        </label>
                    </div>
                </div>
            </section>
            <section class="player-contract-milestone">
                <k-tabstrip :selected="selectedTab" @select="onTabSelected" :tabs="detailTabs">
                    <template v-slot:notes>
                        <div class="notes-history__container">
                            <div class="player-notes__container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-notes"></i>
                                    <h2>Player Notes</h2> 
                                </div>
                                <div class="editor__container">
                                    <k-editor :tools="tools"
                                            ref="noteseditor"
                                            :content-style="{ height: '150px' }"
                                            :resizable="true"
                                            v-on:focus="onEditorFocus"
                                            :default-content="defaultEditorContent"
                                            :defaultEditMode="'div'" />
                                    <div class="editor__container--submit">
                                        <k-button class="submit-note__btn" @click="addPlayerNote"><i class="fa-duotone fa-pen-line"></i> Submit </k-button>
                                    </div>
                                </div>
                                <div v-if="playerNotes.length === 0" class="note-detail__container--empty">
                                    <h4>No player notes available</h4>
                                </div>
                                <div v-if="playerNotes.length > 0" class="note-detail__container">
                                    <div v-for="(note, index) in playerNotes" :key="index" class="note">
                                        <div class="note-date">{{ note.userName }} - {{ note.createdOn.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit'}).split(',').join('') }}</div>
                                        <div class="note-body" v-html="note.note"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="player-history__container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-rectangle-history-circle-user"></i>
                                    <h2>Player History</h2>
                                </div>
                                <div class="player-history-dropdown__container">
                                    <k-dropdown :data-items="filteredPlayerHistoryFields"
                                        class="player-history__dropdown"
                                        :value="selectedHistoryField"
                                        :filterable="true"
                                        @filterchange="onPlayerHistoryFilterChange"
                                        @change="onPlayerHistoryFieldSelected"></k-dropdown>
                                </div>
                                <div v-if="filteredPlayerHistory.length === 0" class="note-detail__container--empty">
                                    <h4>No player history available</h4>
                                </div>
                                <div v-if="filteredPlayerHistory.length > 0" class="note-detail__container">
                                    <div v-for="(note, index) in filteredPlayerHistory" :key="index" class="note">
                                        <div class="note-date">{{ note.updatedOn.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit'}).split(',').join('') }} - {{ note.fieldName }}<span>{{ note.updatedBy }}</span></div>
                                        <div class="note-body" v-if="note.newValue && !note.oldValue">Added: {{ formatHistoryLog(note.fieldName, note.newValue) }}</div>
                                        <div class="note-body" v-if="!note.newValue && note.oldValue">Deleted: {{ formatHistoryLog(note.fieldName, note.oldValue) }}</div>
                                        <div class="note-body" v-if="note.newValue && note.oldValue">Updated: {{ formatHistoryLog(note.fieldName, note.oldValue) }} to {{ formatHistoryLog(note.fieldName, note.newValue) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:contracts>
                        <div v-if="uneditedPlayerContracts.length > 0" class="player-contracts__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-files"></i>
                                    <h2>Contracts</h2>
                                    <k-dropdown :data-items="contractFilterSelects"
                                                class="contract-filter__dropdown"
                                                v-model="selectedContractFilter"></k-dropdown>
                                </div>
                                <detail-view-grid :columns="contractColumns"
                                                :initial-sort="[{ field: 'expiration_field', dir: 'asc' }]"
                                                :item-id-field="'contractId'"
                                                :items="playerContracts"
                                                :player-id="playerId"
                                                :row-clickable="true"
                                                :row-click-function="goToContract"></detail-view-grid>
                            </div>
                        </div>
                        <div v-if="uneditedPlayerContracts.length === 0 && !isLoading" class="no-contracts__container">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-files"></i>
                                <h2>Contracts</h2>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No contracts found</h4>
                            <p>Visit the Contract Management Section to start assigning contracts to players</p>
                        </div>
                    </template>
                    <template v-slot:statistics>
                        <div class="upsert-item__container player-milestone" v-if="playerMilestone">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-hockey-stick-puck"></i>
                                <h2>Statistics</h2>
                            </div>
                            <div  class="nhl-api__result-container">
                                <p v-if="milestoneFetched" class="api--found"><i class="fa-solid fa-circle-check"></i> Player stats fetched from the NHL API</p>
                                <p v-if="!milestoneFetched" class="api--not-found"><i class="fa-solid fa-circle-x"></i> Player stats could not be fetched from the NHL API. Enter them manually, or check player name and team for errors</p>
                            </div>
                            <div class="upsert-item__section" v-if="!milestoneFetched && !uneditedPlayerPositions.some(pos => pos === 'Goalie')">
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Games Played
                                        <k-numeric-input v-model="playerMilestone.gamesPlayed" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Goals
                                        <k-numeric-input v-model="playerMilestone.goals" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Assists
                                        <k-numeric-input v-model="playerMilestone.assists" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Points
                                        <k-numeric-input v-model="playerMilestone.points" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Plus / Minus
                                        <k-numeric-input v-model="playerMilestone.plusMinus"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        PIM
                                        <k-numeric-input v-model="playerMilestone.penaltyMinutes" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Shots
                                        <k-numeric-input v-model="playerMilestone.shots" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label" v-if="playerMilestone.apiSource !== 'EP'">
                                        Power Play Goals
                                        <k-numeric-input v-model="playerMilestone.powerPlayGoals" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label" v-if="playerMilestone.apiSource === 'EP'">
                                        Power Play Goals Avg
                                        <k-numeric-input v-model="playerMilestone.powerPlayGoalsAvg" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Power Play Points
                                        <k-numeric-input v-model="playerMilestone.powerPlayPoints" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Shorthanded Goals
                                        <k-numeric-input v-model="playerMilestone.shorthandedGoals" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Shorthanded Points
                                        <k-numeric-input v-model="playerMilestone.shorthandedPoints" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Game Winning Goals
                                        <k-numeric-input v-model="playerMilestone.gameWinningGoals" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Overtime Goals
                                        <k-numeric-input v-model="playerMilestone.overtimeGoals" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Shooting Percentage
                                        <k-numeric-input v-model="playerMilestone.shootingPercentage" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label"></label>
                                </div>
                            </div>
                            <div class="upsert-item__section" v-if="!milestoneFetched && uneditedPlayerPositions.some(pos => pos === 'Goalie')">
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Games Played
                                        <k-numeric-input v-model="playerMilestone.gamesPlayed" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        PIM
                                        <k-numeric-input v-model="playerMilestone.penaltyMinutes" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Shutouts
                                        <k-numeric-input v-model="playerMilestone.shutouts" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Goals Against
                                        <k-numeric-input v-model="playerMilestone.goalsAgainst" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Goals Against Average
                                        <k-numeric-input v-model="playerMilestone.goalsAgainstAverage"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Shots Against
                                        <k-numeric-input v-model="playerMilestone.shotsAgainst" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        Save Percentage
                                        <k-numeric-input v-model="playerMilestone.savePercentage"></k-numeric-input>
                                    </label>
                                </div>
                            </div>
                            <div v-if="milestoneFetched && !uneditedPlayerPositions.some(pos => pos === 'Goalie')" class="nhl-milestones__tabstrip-container">
                                <k-tabstrip class="nhl-milestones__tabstrip" :selected="selectedMilestoneTab" @select="onMilestoneTabSelected" :tabs="milestoneDetailTabs">
                                    <template v-for="playerMilestone in nhlPlayerMilestones" :key="playerMilestone.season" v-slot:[playerMilestone.season]>
                                        <div class="upsert-item__section">
                                            <h4 class="milestone--team-name"><i class="fa-duotone fa-hockey-puck"></i> {{playerMilestone.stat.teamName}}</h4>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Games Played
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.gamesPlayed" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Goals
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.goals" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Assists
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.assists" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Points
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.points" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Plus / Minus
                                                    <k-numeric-input disabled v-model="playerMilestone.plusMinus"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    PIM
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.penaltyMinutes" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Shots
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shots" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Power Play Goals
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.powerPlayGoals" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Power Play Points
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.powerPlayPoints" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Shorthanded Goals
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shorthandedGoals" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Shorthanded Points
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shorthandedPoints" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Game Winning Goals
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.gameWinningGoals" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Overtime Goals
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.overtimeGoals" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Shooting Percentage
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shootingPercentage" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label"></label>
                                            </div>
                                        </div>
                                    </template>
                                </k-tabstrip>
                            </div>
                            <div v-if="milestoneFetched && uneditedPlayerPositions.some(pos => pos === 'Goalie')" class="nhl-milestones__tabstrip-container">
                                <k-tabstrip class="nhl-milestones__tabstrip" :selected="selectedMilestoneTab" @select="onMilestoneTabSelected" :tabs="milestoneDetailTabs">
                                    <template v-for="playerMilestone in nhlPlayerMilestones" :key="playerMilestone.season" v-slot:[playerMilestone.season]>
                                        <div class="upsert-item__section">
                                            <h4 class="milestone--team-name"><i class="fa-duotone fa-hockey-puck"></i> {{playerMilestone.stat.teamName}}</h4>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Games Played
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.gamesPlayed" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    PIM
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.penaltyMinutes" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Shutouts
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shutouts" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Goals Against
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.goalsAgainst" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Goals Against Average
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.goalsAgainstAverage"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Shots Against
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.shotsAgainst" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row trio">
                                                <label class="input__label">
                                                    Save Percentage
                                                    <k-numeric-input disabled v-model="playerMilestone.stat.savePercentage"></k-numeric-input>
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </k-tabstrip>
                            </div>
                        </div>
                    </template>
                    <template v-slot:schedule>
                        <div class="team-schedule__container">
                            <div class="past-games__container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-hockey-sticks"></i>
                                    <h2>Past Games</h2>
                                </div>
                                <k-listview :data-items="playerSchedule.filter(item => item.gameState !== 'FUT')"
                                            :item="'playerScheduleItem'"
                                            :footer="'playerScheduleFooter'">
                                    <template v-slot:playerScheduleItem="{ props }">
                                        <div class="player-schedule-item">
                                            <div class="game-date__container">
                                                <p>{{ props.dataItem.gameDate }}</p>
                                                <p>{{ props.dataItem.homeTeam.id === apiPlayerTeamId ? 'Home' : 'Away' }}</p>
                                            </div>
                                            <div class="game-data__container">
                                                <div v-bind:class="['team__row', { 'winner': props.dataItem.awayTeam.winner }]">
                                                    <div class="team__name-logo">
                                                        <img :src="props.dataItem.awayTeam.logo" />
                                                        <p class="team-name">{{ props.dataItem.awayTeam.fullName }}</p>
                                                    </div>
                                                    <p class="team-score">{{ props.dataItem.awayTeam.score }} <span><i v-if="props.dataItem.awayTeam.winner" class="fa-solid fa-caret-left"></i></span></p>
                                                </div>
                                                <div v-bind:class="['team__row', { 'winner': props.dataItem.homeTeam.winner }]">
                                                    <div class="team__name-logo">
                                                        <img :src="props.dataItem.homeTeam.logo" />
                                                        <p class="team-name">{{ props.dataItem.homeTeam.fullName }}</p>
                                                    </div>
                                                    <p class="team-score">{{ props.dataItem.homeTeam.score }} <span><i v-if="props.dataItem.homeTeam.winner" class="fa-solid fa-caret-left"></i></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:playerScheduleFooter>
                                        <k-listview-footer>
                                            {{ playerSchedule.filter(item => item.gameState !== 'FUT').length }} Past Games
                                        </k-listview-footer>
                                    </template>
                                </k-listview>
                                <div class="loader-container" v-if="isLoadingSchedule">
                                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                                </div>
                            </div>
                            <div class="upcoming-games__container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-hockey-sticks"></i>
                                    <h2>Upcoming Games</h2>
                                </div>
                                <k-listview :data-items="playerSchedule.filter(item => item.gameState === 'FUT')"
                                            :item="'playerScheduleItem'"
                                            :footer="'playerScheduleFooter'">
                                        <template v-slot:playerScheduleItem="{ props }">
                                            <div class="player-schedule-item">
                                                <div class="game-date__container">
                                                    <p>{{ props.dataItem.gameDate }}</p>
                                                    <p>{{ props.dataItem.homeTeam.id === apiPlayerTeamId ? 'Home' : 'Away' }}</p>
                                                </div>
                                                <div class="game-data__container">
                                                    <div v-bind:class="['team__row', { 'winner': props.dataItem.awayTeam.winner }]">
                                                        <div class="team__name-logo">
                                                            <img :src="props.dataItem.awayTeam.logo" />
                                                            <p class="team-name">{{ props.dataItem.awayTeam.fullName }}</p>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="['team__row', { 'winner': props.dataItem.homeTeam.winner }]">
                                                        <div class="team__name-logo">
                                                            <img :src="props.dataItem.homeTeam.logo" />
                                                            <p class="team-name">{{ props.dataItem.homeTeam.fullName }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:playerScheduleFooter>
                                            <k-listview-footer>
                                                {{ playerSchedule.filter(item => item.gameState === 'FUT').length }} Upcoming Games
                                            </k-listview-footer>
                                        </template>
                                    </k-listview>
                            </div>
                        </div>
                    </template>
                    <template v-slot:citizenship>
                        <div v-if="uneditedPlayerCitizenshipDocs.length > 0" class="player-contracts__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-globe"></i>
                                    <h2>Citizenship Documents</h2>
                                    <k-button class="add-document__btn" @click="onAddCitizenshipDocument"><i class="fa-regular fa-passport"></i> Add Document</k-button>
                                </div>
                                <detail-view-grid :columns="citizenshipDocColumns"
                                                :initial-sort="[{ field: 'expiringDate', dir: 'asc' }]"
                                                :item-id-field="'id'"
                                                :items="uneditedPlayerCitizenshipDocs"
                                                :player-id="playerId"
                                                :row-clickable="false"
                                                :edit-action="true"
                                                :edit-function="onEditCitizenshipDocument"></detail-view-grid>
                            </div>
                        </div>
                        <div v-if="uneditedPlayerCitizenshipDocs.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-globe"></i>
                                <h2>Citizenship Documents</h2>
                                <k-button class="add-document__btn" @click="onAddCitizenshipDocument"><i class="fa-regular fa-passport"></i> Add Document</k-button>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No documents found</h4>
                        </div>
                    </template>
                    <template v-slot:calendar>
                        <div class="player-calendar__container">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-calendar-days"></i>
                                <h2>Calendar</h2>
                            </div>
                            <k-scheduler :data-items="calendarItems"
                                        class="player-calendar"
                                        :display-date="new Date()"
                                        :views="calendarViews"></k-scheduler>
                        </div>
                    </template>
                    <template v-slot:documents> 
                        <div class="player-documents__container player-contracts__container">
                            <div class="player-documents__upload-list">
                                <div :class="['team-schedule__container', 'player-documents__list-container', 'player-contracts__list-container']">
                                    <div class="contract-title__container">
                                        <i class="fa-duotone fa-files"></i>
                                        <h2>Documents</h2>
                                        <k-dropdown :data-items="documentRootFolders"
                                                    :text-field="'name'"
                                                    v-model="selectedFolderFilter"
                                                    :class="['upload-folder__dropdown']"></k-dropdown>
                                        <k-button @click="toggleUploadDocumentsDialog" class="show-upload__btn"><i class="fa-solid fa-upload"></i> Upload Documents</k-button>
                                    </div>
                                    <detail-view-grid :columns="playerDocumentColumns"
                                                    :initial-sort="[{ field: 'documentName', dir: 'asc' }]"
                                                    :item-id-field="'documentId'"
                                                    :items="playerDocuments"
                                                    :player-id="playerId"
                                                    :row-clickable="true"
                                                    :rowClickFunction="onOpenDocument"
                                                    :edit-action="true"
                                                    :edit-function="onEditPlayerDocument"
                                                    :delete-action="true"
                                                    :delete-function="onDeleteDocument"></detail-view-grid>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:family>
                        <div v-if="uneditedPlayerAssociations.length > 0" class="player-contracts__container family__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-people-group"></i>
                                    <h2>Family</h2>
                                    <k-button class="add-document__btn" @click="onAddPlayerAssociation"><i class="fa-duotone fa-user-group"></i> Add Family</k-button>
                                </div>
                                <detail-view-grid :columns="playerAssociationColumns"
                                                :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                :item-id-field="'associationId'"
                                                :items="uneditedPlayerAssociations"
                                                :player-id="playerId"
                                                :row-clickable="false"
                                                :edit-action="true"
                                                :edit-function="onEditPlayerAssociation"
                                                :delete-action="true"
                                                :delete-function="onDeletePlayerAssociation"></detail-view-grid>
                            </div>
                        </div>
                        <div v-if="uneditedPlayerAssociations.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-people-group"></i>
                                <h2>Family</h2>
                                <k-button class="add-document__btn" @click="onAddPlayerAssociation"><i class="fa-duotone fa-user-group"></i> Add Family</k-button>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No associations found</h4>
                        </div>
                    </template>
                    <template v-slot:injuries>
                        <div v-if="uneditedPlayerInjuries.length > 0" class="player-contracts__container injuries__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-face-head-bandage"></i>
                                    <h2>Injuries</h2>
                                    <k-button class="add-document__btn" @click="onAddPlayerInjury"><i class="fa-regular fa-bandage"></i> Add Injury</k-button>
                                </div>
                                <detail-view-grid :columns="injuryColumns"
                                                :initial-sort="[{ field: 'injuryDate', dir: 'desc' }]"
                                                :item-id-field="'injuryId'"
                                                :items="uneditedPlayerInjuries"
                                                :player-id="playerId"
                                                :row-clickable="false"
                                                :edit-action="true"
                                                :edit-function="onEditPlayerInjury"
                                                :delete-action="true"
                                                :delete-function="onDeletePlayerInjury"></detail-view-grid>
                            </div>
                        </div>
                        <div v-if="uneditedPlayerInjuries.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-face-head-bandage"></i>
                                <h2>Injuries</h2>
                                <k-button class="add-document__btn" @click="onAddPlayerInjury"><i class="fa-regular fa-bandage"></i> Add Injury</k-button>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No injuries found</h4>
                        </div>
                    </template>
                    <template v-slot:draftInfo>
                        <div v-if="uneditedPlayerDraftDetails.length > 0" class="player-contracts__container draft-details__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-ice-skate"></i>
                                    <h2>NHL Draft Information</h2>
                                    <k-button class="add-document__btn" @click="onAddPlayerDraftDetail"><i class="fa-solid fa-hockey-stick-puck"></i> Add Record</k-button>
                                </div>
                                <detail-view-grid :columns="draftDetailColumns"
                                                :initial-sort="[{ field: 'yearDrafted', dir: 'desc' }]"
                                                :item-id-field="'id'"
                                                :items="uneditedPlayerDraftDetails"
                                                :player-id="playerId"
                                                :row-clickable="false"
                                                :edit-action="true"
                                                :edit-function="onEditPlayerDraftDetail"
                                                :delete-action="true"
                                                :delete-function="onDeletePlayerDraftDetail"></detail-view-grid>
                            </div>
                        </div>

                        <div v-if="uneditedPlayerDraftDetails.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-ice-skate"></i>
                                <h2>NHL Draft Information</h2>
                                <k-button class="add-document__btn" @click="onAddPlayerDraftDetail"><i class="fa-solid fa-hockey-stick-puck"></i> Add Record</k-button>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No Details found</h4>
                        </div>
                    </template>
                    <template v-slot:nhlRights>
                        <div v-if="uneditedPlayerRightsDetails.length > 0" class="player-contracts__container draft-details__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-hockey-mask"></i>
                                    <h2>NHL Rights Information</h2>
                                    <k-button class="add-document__btn" @click="onAddPlayerRightsDetail"><i class="fa-solid fa-hockey-stick-puck"></i> Add Record</k-button>
                                </div>
                                <detail-view-grid :columns="rightsDetailColumns"
                                                :initial-sort="[{ field: 'rightsDate', dir: 'desc' }]"
                                                :item-id-field="'id'"
                                                :items="uneditedPlayerRightsDetails"
                                                :player-id="playerId"
                                                :row-clickable="false"
                                                :edit-action="true"
                                                :edit-function="onEditPlayerRightsDetail"
                                                :delete-action="true"
                                                :delete-function="onDeletePlayerRightsDetail"></detail-view-grid>
                            </div>
                        </div>

                        <div v-if="uneditedPlayerRightsDetails.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-hockey-mask"></i>
                                <h2>NHL Rights Information</h2>
                                <k-button class="add-document__btn" @click="onAddPlayerRightsDetail"><i class="fa-solid fa-hockey-stick-puck"></i> Add Record</k-button>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No Details found</h4>
                        </div>
                    </template>
                    <template v-slot:scouting>
                        <div v-if="uneditedPlayerScoutingReports.length > 0" class="player-contracts__container scouting-reports__container">
                            <div class="player-contracts__list-container">
                                <div class="contract-title__container">
                                    <i class="fa-duotone fa-solid fa-clipboard-list"></i>    
                                    <h2>Scouting Reports</h2>
                                </div>
                                <detail-view-grid :columns="scoutingReportColumns"
                                                  :initial-sort="[{ field: 'gameDate', dir: 'desc' }]"
                                                  :item-id-field="'reportId'"
                                                  :items="uneditedPlayerScoutingReports"
                                                  :player-id="playerId"
                                                  :row-clickable="false"
                                                  :edit-action="true"
                                                  :edit-function="onEditScoutingReport"
                                                  :delete-action="true"
                                                  :delete-function="onDeletePlayerScoutingReport"></detail-view-grid>
                            </div>
                        </div>

                        <div v-if="uneditedPlayerScoutingReports.length === 0 && !isLoading" class="no-contracts__container documents">
                            <div class="contract-title__container">
                                <i class="fa-duotone fa-solid fa-clipboard-list"></i>    
                                <h2>Scouting Reports</h2>
                            </div>
                            <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No Details found</h4>
                        </div>                    </template>
                </k-tabstrip>
            </section>
        </div>


        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <upsert-document-dialog :show-dialog="showEditDocumentDialog"
                                :edit-mode="editDocumentMode"
                                :add-mode="addDocumentMode"
                                :player-id="playerId"
                                :edited-document="selectedDocument"
                                @dialogclosed="toggleEditDocumentDialog"
                                @documentsupdated="updateDocuments"></upsert-document-dialog>

        <upsert-player-document-dialog :show-dialog="showUploadDocumentDialog"
                                    :player-id="playerId"
                                    :default-folder-name="documentFolderNames.picturesFolderName"
                                    :current-document="playerPicDocument"
                                    @dialogclosed="toggleUploadDocumentDialog"
                                    @documentsuploaded="assignAndFormatData"></upsert-player-document-dialog>

        <upsert-association-dialog :show-dialog="showPlayerAssociationDialog"
                                :edit-mode="editAssociationMode"
                                :add-mode="addAssociationMode"
                                :player-id="playerId"
                                :edited-association="selectedAssociation"
                                @dialogclosed="toggleEditAssociationDialog"
                                @associationsupdated="updateAssociations"></upsert-association-dialog>

        <upsert-injury-dialog :show-dialog="showPlayerInjuryDialog"
                                :edit-mode="editInjuryMode"
                                :add-mode="addInjuryMode"
                                :player-id="playerId"
                                :edited-injury="selectedInjury"
                                @dialogclosed="toggleUpsertInjuryDialog"
                                @injuriesupdated="updateInjuries"></upsert-injury-dialog>

        <upsert-draft-detail-dialog :show-dialog="showDraftDetailDialog"
                                :edit-mode="editDraftDetailMode"
                                :add-mode="addDraftDetailMode"
                                :player-id="playerId"
                                :player-detail="playerDetail"
                                :edited-detail="selectedDraftDetail"
                                @dialogclosed="toggleUpsertDraftDetailDialog"
                                @draftdetailsupdated="updateDraftDetails"></upsert-draft-detail-dialog>

        <upsert-rights-detail-dialog :show-dialog="showRightsDetailDialog"
                                :edit-mode="editRightsDetailMode"
                                :add-mode="addRightsDetailMode"
                                :player-id="playerId"
                                :player-detail="playerDetail"
                                :edited-detail="selectedRightsDetail"
                                @dialogclosed="toggleUpsertRightsDetailDialog"
                                @rightsdetailsupdated="updateRightsDetails"></upsert-rights-detail-dialog>

        <upload-documents-dialog :show-dialog="showUploadDocumentsDialog"
                                :player-id="playerId"
                                @dialogclosed="toggleUploadDocumentsDialog"
                                @documentsuploaded="updatePlayerDocuments"></upload-documents-dialog>

        <edit-player-document-dialog :show-dialog="showEditPlayerDocumentDialog"
                                :editedDocument="editedPlayerDocument"
                                :player-id="playerId"
                                @dialogclosed="toggleEditPlayerDocumentDialog"
                                @documentupdated="updatePlayerDocuments"></edit-player-document-dialog>

        <update-scouting-report-dialog :show-dialog="showEditScoutingReportDialog"
                                       :selected-report="selectedScoutingReport"
                                       @dialogclosed="toggleEditScoutingReportDialog"
                                       @reportupdated="updateScoutingReports"></update-scouting-report-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="onActionConfirmed"></confirm-dialog>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input, NumericTextBox, Checkbox } from "@progress/kendo-vue-inputs";
    import { DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { ListView, ListViewFooter } from '@progress/kendo-vue-listview';
    import { Editor } from '@progress/kendo-vue-editor';
    import { Scheduler } from '@progress/kendo-vue-scheduler';

    import { filterBy } from '@progress/kendo-data-query';

    import { useVuelidate } from '@vuelidate/core';
    import { required, email, minLength } from '@vuelidate/validators';

    import viewHeader from '../components/viewHeader.vue';
    import upsertCitizenshipDocumentDialog from '../components/upsertCitizenshipDocumentDialog.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import upsertPlayerAssociationDialog from '../components/upsertPlayerAssociationDialog.vue';
    import uploadDocumentsDialog from '../components/uploadDocumentsDialog.vue';
    import editPlayerDocumentDialog from '../components/editPlayerDocumentDialog.vue';
    import detailViewGrid from '../components/detailViewGrid.vue';
    import upsertPlayerInjuryDialog from '../components/upsertPlayerInjuryDialog.vue';
    import upsertDocumentDialog from '../components/upsertDocumentDialog.vue';
    import upsertDraftDetailDialog from '../components/upsertDraftDetailDialog.vue';
    import upsertRightsDetailDialog from '../components/upsertRightsDetailDialog.vue';
    import updateScoutingReportDialog from '../components/updateScoutingReportDialog.vue';

    import cloneDeep from 'lodash.clonedeep';
    import isEqualWith from 'lodash.isequalwith';
    import date from 'date-and-time';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import contractHelpers from '../helpers/contractHelpers.js';
    import citizenshipDocHelpers from '../helpers/citizenshipDocHelpers.js';
    import formatHelpers from '@/helpers/formatHelpers';
    import playerHelpers from '../helpers/playerHelpers.js';
    import invoiceHelpers from '../helpers/invoiceHelpers.js';
    import injuryHelpers from '../helpers/injuryHelpers.js';
    import documentHelpers from '../helpers/documentHelpers.js';
    import scoutingReportHelpers from '../helpers/scoutingReportHelpers.js';

    const phoneValidator = (val) => {
        if (!val) return true;

        const phoneRegEx = `^((\\(\\d{3}\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$`;
        return val.match(phoneRegEx);
    }

    export default {
        setup() {
            return { player$: useVuelidate() }
        },
        name: 'playerDetailView',
        props: ['agentDetail', 'detailPlayerId'],
        emits: ['returntodashboard'],
        data() {
            return {
                addAssociationMode: false,
                addDocumentMode: false,
                addDraftDetailMode: false,
                addInjuryMode: false,
                addRightsDetailMode: false,
                apiPlayerId: '',
                apiPlayerTeamCode: '',
                apiPlayerTeamId: null,
                calendarItems: [],
                calendarViews: [
                    { name: 'month', title: 'Month' }
                ],
                citizenshipDocColumns: [
                    { field: 'type', title: 'Type', width: '125' },
                    { field: 'familyMemberName', title: 'Name' },
                    { field: 'countryOfOrigin', title: 'Country', width: '150' },
                    { field: 'issuedDate', title: 'Issued Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'expiringDate', title: 'Expiring Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                confirmFunction: '',
                confirmIconClass: '',
                confirmMsg: '',
                contractColumns: [
                    { field: 'contractTitle', title: 'Name' },
                    { field: 'contractType', title: 'Type' },
                    { field: 'expiration_field', title: 'Expiration Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                ],
                contractFilterSelects: ['All Contracts', 'Agency Contracts', 'Endorsement Contracts', 'Equipment Contracts', 'Playing Contracts'],
                currentSeasonEnd: null,
                currentSeasonStart: null,
                defaultEditorContent: 'Add your note here!',
                deletedAssociation: null,
                deletedDocument: null,
                deletedDraftDetail: null,
                deletedInjury: null,
                deletedRightsDetail: null,
                deletedScoutingReport: null,
                detailTabs: [
                    {
                        title: 'Notes/History',
                        content: 'notes'
                    },
                    {
                        title: 'Contracts',
                        content: 'contracts'
                    },
                    {
                        title: 'Citizenship',
                        content: 'citizenship'
                    },
                    {
                        title: 'Statistics',
                        content: 'statistics'
                    },
                    {
                        title: 'Calendar',
                        content: 'calendar'
                    },
                    {
                        title: 'Documents',
                        content: 'documents'
                    },
                    {
                        title: 'Family',
                        content: 'family'
                    },
                    {
                        title: 'Injuries',
                        content: 'injuries'
                    },
                    {
                        title: 'Draft Detail',
                        content: 'draftInfo'
                    },
                    {
                        title: 'NHL Rights',
                        content: 'nhlRights'
                    },
                    {
                        title: 'Scouting',
                        content: 'scouting'
                    }
                ],
                documentRootFolders: [],
                draftDetailColumns: [
                    { field: 'yearDrafted', title: 'Year Drafted', filter: 'numeric', type: 'numeric' },
                    { field: 'teamName', title: 'Team' },
                    { field: 'selectionRound', title: 'Round', filter: 'numeric', type: 'numeric', width: '100' },
                    { field: 'selectionNumber', title: 'Pick', filter: 'numeric', type: 'numeric', width: '100' },
                    { field: 'signByOption', title: 'Sign By' },
                    { field: 'signByDate', title: 'Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                draftEligibleYear: null,
                editAssociationMode: false,
                editDocumentMode: false,
                editDraftDetailMode: false,
                editInjuryMode: false,
                editRightsDetailMode: false,
                editedPlayerDocument: null,
                errorMsg: '',
                errorTitle: '',
                files: [],
                filteredPlayerHistoryFields: [],
                injuryColumns: [
                    { field: 'injuryDate', title: 'Injury Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'description', title: 'Description' },
                    { field: 'returnDate', title: 'Return Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                isLoading: true,
                isLoadingSchedule: false,
                historyLogCurrencyFields: ['Annual Contract Value'],
                historyLogDateFields: ['DOB', 'Date Drafted', 'Draft Eligible Date', 'nhlRightsDate'],
                milestoneDateFields: [],
                milestoneDetailTabs: [],
                milestoneModelAdded: false,
                milestoneFetched: false,
                nhlPlayerMilestones: [],
                notificationMsg: '',
                playerAssociationColumns: [
                    { field: 'fullName', title: 'Name' },
                    { field: 'relationship', title: 'Relationship' },
                    { field: 'email', title: 'Email' },
                    { field: 'phone', title: 'Phone' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                playerContracts: [],
                playerDetail: {},
                playerDocumentColumns: [
                    { field: 'documentName', title: 'Name' },
                    { field: 'documentFolder', title: 'Folder' },
                    { field: 'uploadDate', title: 'Upload Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'documentDescription', title: 'Description', width: '300' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                playerDocuments: [],
                playerHistory: [],
                playerHistoryFields: ['All', 'Age', 'Annual Contract Value', 'Billing Address', 'Billing Address Addl', 'Billing City', 'Billing Contact Email', 'Billing Contact Name', 'Billing Contact Phone', 'Billing Contact Phone Ext', 'Billing State', 'Billing Zip', 'Citizenship', 'Current League', 'Current Team', 'DOB', 'First Name', 'Last Name', 
                    'NHL Rights Team', 'NHL Rights Date Drafted', 'Position', 'Projected Draft Round', 'Status', 'Type', 'NHL Draft Team', 'NHL Rights Date'],
                playerId: '',
                playerInvoices: [],
                playerMilestone: {},
                playerMilestoneModel: {
                    playerId: '',
                    gamesPlayed: 0,
                    goals: 0,
                    assists: 0,
                    points: 0,
                    penaltyMinutes: 0,
                    powerPlayGoals: 0,
                    powerPlayPoints: 0,
                    shorthandedGoals: 0,
                    shorthandedPoints: 0,
                    gameWinningGoals: 0,
                    overtimeGoals: 0,
                    shots: 0,
                    shootingPercentage: 0.00,
                    apiUpdate: false,
                    updated: null
                },
                playerNotes: [],
                playerPicDocument: null,
                playerPicUrl: null,
                playerPositions: [],
                playerSchedule: [],
                rightsDetailColumns: [
                    { field: 'teamName', title: 'Team' },
                    { field: 'rightsDate', title: 'Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                scoutingReportColumnsForwardsDefense: [
                    { field: 'gameDate', title: 'Game Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'speed', title: 'Speed', filter: 'numeric', type: 'number' },
                    { field: 'agility', title: 'Agility', filter: 'numeric', type: 'number' },
                    { field: 'shot', title: 'Shot', filter: 'numeric', type: 'number' },
                    { field: 'stickHandling', title: 'Handling', filter: 'numeric', type: 'number' },
                    { field: 'playmakingWithoutPuck', title: 'PlayMkg', filter: 'numeric', type: 'number' },
                    { field: 'playmakingWithPuck', title: 'PlayMkg / P', filter: 'numeric', type: 'number'},
                    { field: 'consistencyEffort', title: 'Con / Ef', filter: 'numeric', type: 'number' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                scoutingReportColumnsGoalie: [
                    { field: 'gameDate', title: 'Game Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'speed', title: 'Speed', filter: 'numeric', type: 'number' },
                    { field: 'skatingAbility', title: 'Skating', filter: 'numeric', type: 'number' },
                    { field: 'slidingAbility', title: 'Sliding', filter: 'numeric', type: 'number' },
                    { field: 'technique', title: 'Technique', filter: 'numeric', type: 'number' },
                    { field: 'saves', title: 'Saves', filter: 'numeric', type: 'number' },
                    { field: 'mobility', title: 'Mobility', filter: 'numeric', type: 'number' },
                    { field: 'stickHandling', title: 'Handling', filter: 'numeric', type: 'number' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                selectedAssociation: null,
                selectedContractFilter: 'All Contracts',
                selectedDocument: null,
                selectedDocumentFolder: '',
                selectedDraftDetail: null,
                selectedFolderFilter: { name: 'All Folders', id: null },
                selectedHistoryField: 'All',
                selectedInjury: null,
                selectedMilestoneTab: 0,
                selectedRightsDetail: null,
                selectedScoutingReport: null,
                selectedTab: 0,
                showConfirmDialog: false,
                showPlayerPic: false,
                showUploadDocumentsDialog: false,
                showDraftDetailDialog: false,
                showEditDocumentDialog: false,
                showEditPlayerDocumentDialog: false,
                showEditScoutingReportDialog: false,
                showErrorDialog: false,
                showPlayerAssociationDialog: false,
                showPlayerInjuryDialog: false,
                showRightsDetailDialog: false,
                showUploadDocumentDialog: false,
                successNotification: false,
                tools: [
                    ["Bold", "Italic", "Underline"],
                    ["Undo", "Redo"],
                    ["Link", "Unlink"],
                    ["AlignLeft", "AlignCenter", "AlignRight"],
                    ["OrderedList", "UnorderedList", "Indent", "Outdent"],
                ],
                uneditedPlayerAssociations: [],
                uneditedPlayerCitizenshipDocs: [],
                uneditedPlayerContracts: [],
                uneditedPlayerDetail: {},
                uneditedPlayerDocuments: [],
                uneditedPlayerDraftDetails: [],
                uneditedPlayerRightsDetails: [],
                uneditedPlayerInjuries: [],
                uneditedPlayerMilestone: {},
                uneditedPlayerNoTradeClauses: [],
                uneditedPlayerPositions: [],
                uneditedPlayerScoutingReports: []
            }
        },
        computed: {
            agencyContractColumns() {
                return contractHelpers.agencyContractColumns;
            },
            agencyContractType() {
                return contractHelpers.agencyContractType;
            },
            allContractColumns() {
                return contractHelpers.allContractColumns;
            },
            citizenshipDocDateFields() {
                return citizenshipDocHelpers.citizenshipDocDateFields;
            },
            contractDateFields() {
                return contractHelpers.contractDateFields;
            },
            detailDateFields() {
                return playerHelpers.playerDateFields;
            },
            documentFolderNames() {
                return documentHelpers.documentFolderNames;
            },
            draftDetailDateFields() {
                return playerHelpers.draftDetailDateFields;
            },
            endorsementContractColumns() {
                return contractHelpers.endorsementContractColumns;
            },
            equipmentContractColumns() {
                return contractHelpers.equipmentContractColumns;
            },
            filteredPlayerHistory() {
                if (this.selectedHistoryField === 'All') {
                    return cloneDeep(this.playerHistory);
                } else {
                    return cloneDeep(this.playerHistory.filter(log => log.fieldName === this.selectedHistoryField));
                }
            },
            endorsementContractType() {
                return contractHelpers.endorsementContractType;
            },
            equipmentContractType() {
                return contractHelpers.equipmentContractType;
            },
            greenCardDocumentType() {
                return citizenshipDocHelpers.greenCardDocumentType;
            },
            injuryDateFields() {
                return injuryHelpers.injuryDateFields;
            },
            nhlTeams() {
                return this.$store.state.nhlApiTeams;
            },
            nhlTeamSelects() {
                return playerHelpers.nhlTeamSelects;
            },
            passportDocumentType() {
                return citizenshipDocHelpers.passportDocumentType;
            },
            playerContractColumns() {
                return contractHelpers.playerContractColumns;
            },
            playingContractType() {
                return contractHelpers.playingContractType;
            },
            positionSelects() {
                return playerHelpers.playerPositionSelects;
            },
            rightsDetailDateFields() {
                return playerHelpers.rightsDetailDateFields;
            },
            saveChangesBtnDisabled() {
                let playerContractsEqual = this.isEqualWith(this.playerContracts, this.uneditedPlayerContracts);
                
                let playerDetailEqual = this.isEqualWith(this.playerDetail, this.uneditedPlayerDetail);

                let playerMilestoneEqual = this.isEqualWith(this.playerMilestone, this.uneditedPlayerMilestone);

                let playerPositionsEqual = this.isEqualWith(cloneDeep(this.playerPositions).sort(), cloneDeep(this.uneditedPlayerPositions).sort());

                if(playerContractsEqual && playerDetailEqual && playerMilestoneEqual && playerPositionsEqual) return true;

                return false;
            },
            scoutingReportColumns() {
                if (this.uneditedPlayerPositions.some(pos => pos === 'Goalie')) {
                    return this.scoutingReportColumnsGoalie;
                } else {
                    return this.scoutingReportColumnsForwardsDefense;
                }
            },
            scoutingReportDateFields() {
                return scoutingReportHelpers.scoutingReportDateFields;
            },
            stateSelectsAbbr() {
                return invoiceHelpers.stateSelectsAbbr;
            },
            statusSelects() {
                return playerHelpers.playerStatusSelects;
            },
            visaDocumentType() {
                return citizenshipDocHelpers.visaDocumentType;
            }
        },
        watch: {
            selectedContractFilter(val) {
                if (val === 'All Contracts') {
                    this.playerContracts = cloneDeep(this.uneditedPlayerContracts);
                    this.contractColumns = cloneDeep(this.allContractColumns);
                    this.contractColumns.pop();
                } else {
                    this.playerContracts = this.uneditedPlayerContracts.filter(contract => val.includes(contract.contractType));

                    let columns;

                    switch(val) {
                        case 'Agency Contracts':
                            columns = cloneDeep(this.agencyContractColumns);
                            break;
                        case 'Endorsement Contracts':
                            columns = cloneDeep(this.endorsementContractColumns);
                            break;
                        case 'Equipment Contracts':
                            columns = cloneDeep(this.equipmentContractColumns);
                            break;
                        case 'Playing Contracts':
                            columns = cloneDeep(this.playerContractColumns);
                            columns.shift();
                            break;
                        default:
                            columns = cloneDeep(this.allContractColumns);
                    }

                    columns.pop();
                    this.contractColumns = columns;
                }
            },
            selectedFolderFilter(val) {
                if (val.name === 'All Folders') {
                    return this.playerDocuments = cloneDeep(this.uneditedPlayerDocuments);
                }

                this.playerDocuments = this.uneditedPlayerDocuments.filter(doc => doc.documentFolder === val.name);
            },
            selectedTab(val) {
                if (val === 11 && this.playerSchedule.length === 0 && this.apiPlayerId) {
                    this.getNhlApiTeamSchedule();
                }

                if (val === 5 && this.documentRootFolders.length === 0) {
                    this.getDocumentRootFolders();
                }
            }
        },
        async mounted() {
            this.getNhlCurrentSeason();
            await this.getNhlApiTeams();

            if (this.agentDetail) {
                this.playerId = this.detailPlayerId;
            } else {
                this.playerId = this.$route.query.id;
            }

            await this.getPlayerDetail();

            this.filteredPlayerHistoryFields = cloneDeep(this.playerHistoryFields);
        },
        validations() {
            return {
                playerDetail: {
                    firstName: { required },
                    lastName: { required },
                    billingContactEmail: { email },
                    status: { required },
                    billingContactPhone: { phoneValidator }
                },
                playerPositions: {
                    required,
                    minLength: minLength(1)
                }
            }
        },
        methods: {
            async addPlayerNote() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() !== this.defaultEditorContent) {

                    let self = this;

                    this.isLoading = true;

                    let note = {
                        note: noteHtml,
                        playerId: this.playerId,
                        noteId: null,
                        userName: auth.user()?.name
                        
                    };

                    this.$refs.noteseditor.setHTML(this.defaultEditorContent);

                    let tokenResponse = await auth.acquireTokenResponse();

                    return apiService.CreatePlayerNote(tokenResponse.accessToken, note)
                        .then((res) => {
                            self.playerNotes = res.data.data;

                            self.playerNotes.forEach(note => {
                                note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                            });
                            self.playerNotes.sort((a, b) => b.createdOn - a.createdOn);

                            self.isLoading = false;

                            self.showNotification('Note added successfully');
                        }).catch(error => {
                            console.log(error);
                            self.onRequestError('An Error Occurred', error?.response?.data?.message);
                        });
                }
            },
            async assignAndFormatData(data) {
                this.playerPicUrl = data.playerPicUrl;

                this.playerHistory = data.playerHistoryLogs.filter(log => log.newValue || log.oldValue);

                data.playerDocuments = this.formatHockeyDocumentDates(data.playerDocuments);
                this.uneditedPlayerDocuments = data.playerDocuments.sort((a, b) => a.documentName.localeCompare(b.documentName));

                if (this.playerPicUrl) {
                    this.playerPicDocument = this.uneditedPlayerDocuments.find(doc => doc.documentId === data.player.associatedPhotoId);

                    if (this.playerPicDocument) {
                        this.playerPicUrl = `data:${this.playerPicDocument.documentType};base64,${this.playerPicUrl}`;
                    }
                }
                
                this.playerNotes = data.playerNotes;

                this.playerNotes.forEach(note => {
                    note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                });

                this.playerNotes.sort((a, b) => b.createdOn - a.createdOn);

                this.playerHistory.forEach(note => {
                    note.updatedOn = formatHelpers.formatDateTime(note.updatedOn);
                });

                this.playerHistory.sort((a, b) => b.updatedOn - a.updatedOn);

                this.playerInvoices = data.playerInvoices;

                this.uneditedPlayerNoTradeClauses = data.noTradeClauses;
                this.uneditedPlayerDraftDetails = data.draftDetails;
                this.uneditedPlayerRightsDetails = data.rightsDetails;
                this.uneditedPlayerContracts = data.contracts;
                this.uneditedPlayerDetail = data.player;
                this.uneditedPlayerPositions = data.playerPositions.map(pos => pos.position);
                this.uneditedPlayerMilestone = data.milestone;
                this.uneditedPlayerCitizenshipDocs = [...data.greenCards, ...data.passports, ...data.visas];

                this.uneditedPlayerCitizenshipDocs.forEach(doc => {
                    this.citizenshipDocDateFields.forEach(field => {
                        doc[field] = formatHelpers.formatDate(doc[field]);
                    });

                    if (!doc.familyRecord) {
                        doc.familyMemberName = doc.fullName;
                    }
                });

                this.uneditedPlayerCitizenshipDocs.sort((a, b) => a.expiringDate - b.expiringDate);

                this.uneditedPlayerScoutingReports = data.scoutingReports;

                this.uneditedPlayerScoutingReports.forEach(report => {
                    report.playerPositions = data.playerPositions;

                    this.scoutingReportDateFields.forEach(field => {
                        report[field] = formatHelpers.formatDate(report[field]);
                    });
                });

                this.uneditedPlayerScoutingReports.sort((a, b) => b.gameDate - a.gameDate);

                this.uneditedPlayerContracts.forEach(contract => {
                    contractHelpers.contractDateFields.forEach(field => {
                        contract[field] = formatHelpers.formatDate(contract[field]);
                    });

                    if (contract.contractType === contractHelpers.playingContractType) {
                        contract.contractyears_field = contractHelpers.getContractYears(contract);
                    }

                    contract.expiration_field = contractHelpers.getContractExpirationField(contract);
                });

                this.uneditedPlayerAssociations = data.playerAssociations;

                this.uneditedPlayerInjuries = data.playerInjuries;

                this.uneditedPlayerInjuries.forEach(injury => {
                    this.injuryDateFields.forEach(field => {
                        injury[field] = formatHelpers.formatDate(injury[field]);
                    });
                });

                this.uneditedPlayerDraftDetails.forEach(detail => {
                    this.draftDetailDateFields.forEach(field => {
                        detail[field] = formatHelpers.formatDate(detail[field]);
                    });
                });

                this.uneditedPlayerRightsDetails.forEach(detail => {
                    this.rightsDetailDateFields.forEach(field => {
                        detail[field] = formatHelpers.formatDate(detail[field]);
                    });
                });

                this.uneditedPlayerNoTradeClauses.forEach(clause => {
                    contractHelpers.noTradeClauseDateFields.forEach(field => {
                        clause[field] = formatHelpers.formatDate(clause[field]);
                    });
                })

                if (!this.uneditedPlayerMilestone) {
                    this.uneditedPlayerMilestone = cloneDeep(this.playerMilestoneModel);
                    this.milestoneModelAdded = true;
                } else {
                    this.milestoneModelAdded = false;
                }

                if (this.uneditedPlayerMilestone?.apiUpdate) {
                    await this.getPlayerMilestone();
                }

                ['Detail', 'Milestone'].forEach(category => {
                    this.formatDateFields(category.toLowerCase(), this[`uneditedPlayer${category}`]);
                });

                if (this.uneditedPlayerDetail.draftEligibleDate_formatted) {
                    this.draftEligibleYear = this.uneditedPlayerDetail.draftEligibleDate_formatted.getFullYear();
                }

                this.assignCalendarItems();
                this.assignEditablePlayerProps();
            },
            assignCalendarItems() {
                this.calendarItems = [];

                this.uneditedPlayerCitizenshipDocs.forEach(doc => {
                    if (doc.expiringDate) {
                        this.calendarItems.push({
                            id: doc.id,
                            title: `${doc.type} Expiring`,
                            start: doc.expiringDate,
                            end: date.addDays(doc.expiringDate, 1)
                        });
                    }
                });

                this.playerInvoices.forEach(invoice => {
                    this.calendarItems.push({
                        id: invoice.id,
                        title: `Invoice Due: ${invoice.invoiceNo}`,
                        start: new Date(invoice.paymentDue),
                        end: date.addDays(new Date(invoice.paymentDue), 1)
                    })
                });

                this.uneditedPlayerContracts.forEach(contract => {
                    let contractExpirationDate = contractHelpers.getContractExpirationField(contract);
                    
                    if (contractExpirationDate) {
                        this.calendarItems.push({
                            id: contract.contractId,
                            title: `Contract Expiring: ${contract.contractTitle}`,
                            start: contractHelpers.getContractExpirationField(contract),
                            end: date.addDays(contractHelpers.getContractExpirationField(contract), 1)
                        });
                    }
                });

                this.uneditedPlayerInjuries.forEach(injury => {
                    this.calendarItems.push({
                        id: injury.injuryId,
                        title: 'Out on Injury',
                        start: injury.injuryDate,
                        end: injury.returnDate
                    })
                });

                this.uneditedPlayerNoTradeClauses.forEach(clause => {
                    this.calendarItems.push({
                        id: clause.clauseId,
                        title: 'No Trade Clause Reporting Due',
                        start: clause.dueDate,
                        end: date.addDays(clause.dueDate, 1)
                    })
                });

                if (this.uneditedPlayerDetail.dob_formatted) {
                    let playerDOB = cloneDeep(this.uneditedPlayerDetail.dob_formatted);
                    let birthday = new Date(playerDOB.setFullYear(new Date().getFullYear()));

                    this.calendarItems.push({
                        id: this.uneditedPlayerDetail.id,
                        title: 'Birthday',
                        start: birthday,
                        end: date.addDays(birthday, 1)
                    });
                }

                if (this.uneditedPlayerDetail.draftEligibleDate_formatted) {
                    this.calendarItems.push({
                        id: `${this.uneditedPlayerDetail.id}-draft`,
                        title: 'Draft Eligible',
                        start: this.uneditedPlayerDetail.draftEligibleDate_formatted,
                        end: date.addDays(this.uneditedPlayerDetail.draftEligibleDate_formatted, 1)
                    });
                }
            },
            assignEditablePlayerProps() {
                this.playerContracts = cloneDeep(this.uneditedPlayerContracts);

                this.playerDetail = cloneDeep(this.uneditedPlayerDetail);
                this.playerMilestone = cloneDeep(this.uneditedPlayerMilestone);

                this.playerDocuments = cloneDeep(this.uneditedPlayerDocuments);
                this.playerPositions = cloneDeep(this.uneditedPlayerPositions);
            },
            assignMilestoneDetailTabs() {
                this.milestoneDetailTabs = [];

                this.nhlPlayerMilestones.forEach(milestone => {
                    let seasonString = milestone.season.toString();
                    let title = seasonString.startsWith(this.currentSeasonStart.toString())
                        ? 'Current'
                        : `${seasonString.substring(0, 4)} / ${seasonString.substring(4)}`;
                    this.milestoneDetailTabs.unshift({
                        title: title,
                        content: seasonString
                    });
                });

                this.uneditedPlayerMilestone.teamName = this.uneditedPlayerDetail.currentTeam;

                this.nhlPlayerMilestones[this.nhlPlayerMilestones.length - 1].stat = this.uneditedPlayerMilestone;
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        return this[fields[0]][fields[1]] = null;
                    }

                    this[fields[0]][fields[1]] = evt.target.value;
                } else {
                    if (evt.target.value === defaultValue) {
                        return this.playerDetail[field] = null;
                    }

                    this.playerDetail[field] = evt.target.value;
                }
            },
            async deleteDocument() { 
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteHockeyDocument(tokenResponse.accessToken, this.deletedDocument)
                    .then(async (res) => {
                        let documents = self.formatHockeyDocumentDates(res.data.data);

                        self.uneditedPlayerDocuments = documents.sort((a, b) => a.documentName.localeCompare(b.documentName));
                        self.playerDocuments = cloneDeep(self.uneditedPlayerDocuments);

                        if (self.selectedFolderFilter && self.selectedFolderFilter.name !== 'All Folders') {
                            self.playerDocuments = self.uneditedPlayerDocuments.filter(doc => doc.documentFolder === self.selectedFolderFilter.name);
                        }

                        if (self.uneditedPlayerDetail.associatedPhotoId === self.deletedDocument.documentId) {
                            self.uneditedPlayerDetail.associatedPhotoId = self.playerDetail.associatedPhotoId = null;
                            self.playerPicUrl = null;

                            if (self.uneditedPlayerMilestone?.apiUpdate) {
                                await self.getPlayerMilestone(true);
                            }
                        }

                        self.isLoading = false;
                        self.showNotification('Document deleted successfully');
                        self.deletedDocument = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async deleteDraftDetail() {
                let request = {
                    draftDetail: cloneDeep(this.deletedDraftDetail),
                    playerDetail: {
                        playerId: this.playerId,
                        player: cloneDeep(this.playerDetail),
                        playerUpdated: true
                    }

                }

                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteDraftDetail(tokenResponse.accessToken, request)
                    .then(res => {
                        self.updateDraftDetails(res.data.data);

                        self.isLoading = false;
                        self.showNotification('Record deleted successfully');
                        self.deletedDraftDetail = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async deletePlayerAssociation() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeletePlayerAssociation(tokenResponse.accessToken, this.deletedAssociation.associationId, this.playerId)
                    .then((res) => {
                        self.updateAssociations(res.data.data);

                        self.isLoading = false;
                        self.showNotification('Association deleted successfully');
                        self.deletedAssociation = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async deletePlayerInjury() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeletePlayerInjury(tokenResponse.accessToken, this.deletedInjury.injuryId, this.playerId)
                    .then((res) => {
                        self.updateInjuries(res.data.data);

                        self.isLoading = false;
                        self.showNotification('Record deleted successfully');
                        self.deletedInjury = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async deleteRightsDetail() {
                let request = {
                    rightsDetail: cloneDeep(this.deletedRightsDetail),
                    playerDetail: {
                        playerId: this.playerId,
                        player: cloneDeep(this.playerDetail),
                        playerUpdated: true
                    }

                }

                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteRightsDetail(tokenResponse.accessToken, request)
                    .then(res => {
                        self.updateRightsDetails(res.data.data);

                        self.isLoading = false;
                        self.showNotification('Record deleted successfully');
                        self.deletedRightsDetail = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async deleteScoutingReport() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteScoutingReport(tokenResponse.accessToken, this.deletedScoutingReport.reportId) 
                    .then(async () => {
                        await this.$store.dispatch('getScoutingReports', true);

                        self.updateScoutingReports();

                        self.isLoading = false;
                        self.showNotification('Report deleted successfully');

                        self.deletedScoutingReport = null;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            formatApiNumberFields(obj) {
                let milestoneModel = {
                    playerId: '',
                    gamesPlayed: obj.gamesPlayed,
                    goals: obj.goals,
                    assists: obj.assists,
                    points: obj.points,
                    penaltyMinutes: obj.pim,
                    plusMinus: obj.plusMinus,
                    powerPlayGoals: obj.powerPlayGoals,
                    powerPlayPoints: obj.powerPlayPoints,
                    shorthandedGoals: obj.shorthandedGoals,
                    shorthandedPoints: obj.shorthandedPoints,
                    gameWinningGoals: obj.gameWinningGoals,
                    overtimeGoals: obj.otGoals,
                    shots: obj.shots,
                    shootingPercentage: obj.shootingPctg * 100,
                    teamName: obj.teamName.default,
                    shutouts: obj.shutouts,
                    goalsAgainst: obj.goalsAgainst,
                    goalsAgainstAverage: obj.goalsAgainstAvg,
                    shotsAgainst: obj.shotsAgainst,
                    savePercentage: obj.savePctg
                }
                
                return milestoneModel;
            },
            formatContactPhoneNumberInput() {
                if (!this.playerDetail.billingContactPhone) return;

                const phoneNumber = this.playerDetail.billingContactPhone.replace(/[^\d]/g, '');
                const phoneNumberLength = phoneNumber.length;

                if (phoneNumberLength < 4) return this.playerDetail.billingContactPhone = phoneNumber;

                if (phoneNumberLength < 7) {
                    return this.playerDetail.billingContactPhone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                }

                this.playerDetail.billingContactPhone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;

            },
            formatDateFields(category, obj) {
                if (obj) {
                    for(const [key] of Object.entries(obj)) {
                        if(this[`${category}DateFields`].some(field => field === key)) {
                            if (obj[key]) {
                                obj[`${key}_formatted`] = new Date(obj[key]);
                            } else {
                                obj[`${key}_formatted`] = null;
                            }
                        }
                    }
                }
            },
            formatHistoryLog(fieldName, value) {
                if (this.historyLogCurrencyFields.some(field => field === fieldName)) {
                    return this.$options.filters.formatCurrencyDec(value);
                }

                if (this.historyLogDateFields.some(field => field === fieldName)) {
                    return new Date(value).toLocaleDateString('en-US', {timeZone: 'UTC'});
                }

                return value;
            },
            formatHockeyDocumentDates(documents) {
                documents.forEach(doc => {
                    doc.uploadDate = formatHelpers.formatDate(doc.uploadDate);
                });            

                return documents;
            },
            formatPlayerSchedule() {
                this.playerSchedule.forEach(game => {
                    game.awayTeam.fullName = this.nhlTeams.find(team => team.id === game.awayTeam.id)?.fullName;
                    game.awayTeam.winner = game.awayTeam.score > game.homeTeam.score;
                    game.homeTeam.fullName = this.nhlTeams.find(team => team.id === game.homeTeam.id)?.fullName;
                    game.homeTeam.winner = game.homeTeam.score > game.awayTeam.score;

                    if (game.gameState !== 'FUT') {
                        game.gameDate = new Date(game.gameDate).toDateString();
                    } else {
                        game.gameDate = new Date(game.startTimeUTC).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit'}).split(',').join('');
                    }
                });

                this.isLoadingSchedule = false;
            },
            async getDocumentRootFolders() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDocumentRootFolders(tokenResponse.accessToken)
                    .then((res) => {
                        self.documentRootFolders = res.data.data ?? [];
                        self.documentRootFolders.unshift({ name: 'All Folders', id: null });
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                    });

            },
            async getNhlApiPlayerMilestones(playerId) {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.getNhlApiPlayerMilestones(tokenResponse.accessToken, playerId)
                    .then((res) => {
                        return res.data.data.seasonTotals;
                    }).catch(error => {
                        console.log(error);
                    })
            },
            async getNhlApiTeams() {
                await this.$store.dispatch('getNhlApiTeams');
            },
            async getNhlApiTeamRoster(teamId) {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNhlApiTeamRoster(tokenResponse.accessToken, teamId, `${this.currentSeasonStart}${this.currentSeasonEnd}`)
                    .then((res) => {
                        return [...res.data.data.defensemen, ...res.data.data.forwards, ...res.data.data.goalies];
                    }).catch(error => {
                        console.log(error);
                    });
            },
            async getNhlApiTeamSchedule() {
                this.isLoadingSchedule = true;

                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNhlApiTeamSchedule(tokenResponse.accessToken, this.apiPlayerTeamCode) 
                    .then((res) => {
                        self.playerSchedule = res.data.data.games;

                        self.formatPlayerSchedule();
                    }).catch(error => {
                        console.log(error);
                        self.isLoadingSchedule = false;
                    });
            },
            getNhlCurrentSeason() {
                let currentMonth = new Date().getMonth();
                let currentYear = new Date().getFullYear();

                if (currentMonth >= 9) {
                    this.currentSeasonStart = currentYear;
                    this.currentSeasonEnd = currentYear + 1;
                } else {
                    this.currentSeasonStart = currentYear - 1;
                    this.currentSeasonEnd = currentYear;
                }

                return `${this.currentSeasonStart}${this.currentSeasonEnd}`;
            },
            async getPlayerDetail() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerDetail(tokenResponse.accessToken, this.playerId)
                    .then((res) => {
                        if (res.data.data) {
                            console.log(res.data.data);
                            self.assignAndFormatData(res.data.data);
                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Player detail unavailable');
                        }
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async getPlayerMilestone(getHeadshot = false) {
                let playerTeam = this.nhlTeams.find(team => team.fullName === this.uneditedPlayerDetail.currentTeam.trim());

                if (playerTeam) {
                    this.apiPlayerTeamId = playerTeam.id;
                    this.apiPlayerTeamCode = playerTeam.triCode;

                    let teamRoster = await this.getNhlApiTeamRoster(this.apiPlayerTeamCode);

                    if (teamRoster && Array.isArray(teamRoster)) {
                        let player = teamRoster.find(player => `${player.firstName.default} ${player.lastName.default}` === `${this.uneditedPlayerDetail.firstName.trim()} ${this.uneditedPlayerDetail.lastName.trim()}`);

                        if (player) {
                            this.playerPicUrl = this.playerPicUrl ?? player.headshot;
                            this.apiPlayerId = player.id;

                            if (getHeadshot) return;

                            let playerMilestones = await this.getNhlApiPlayerMilestones(this.apiPlayerId);

                            if (playerMilestones) {
                                this.nhlPlayerMilestones = playerMilestones
                                    .filter(milestone => milestone.leagueAbbrev === 'NHL');

                                this.nhlPlayerMilestones.forEach((milestone, index) => {
                                    if (this.nhlPlayerMilestones[index + 1]) {
                                        if (milestone.season === this.nhlPlayerMilestones[index + 1].season && milestone.teamName.default === this.nhlPlayerMilestones[index + 1].teamName.default) {
                                            this.nhlPlayerMilestones.splice(index + 1, 1);
                                        }
                                    }

                                    milestone.stat = this.formatApiNumberFields(milestone);
                                });

                                this.assignMilestoneDetailTabs();

                                this.milestoneFetched = true;
                            }

                            let scheduleTab = this.detailTabs.find(tab => tab.title === 'Team Schedule');

                            if (!scheduleTab) {
                                this.detailTabs.push({ title: 'Team Schedule', content: 'schedule' });
                            }                
                        }
                    }
                }
            },
            goToContract(evt) {
                this.$router.push(`/ContractDetail?id=${evt.dataItem.contractId}`);
            },
            isEqualWith(unedited, edited) {
                return isEqualWith(unedited, edited, (a, b) => {
                    if ((a === null || a === '' || a === 0) && (b === null || b === '' || b === 0)) return true;
                });
            },
            onAddCitizenshipDocument() {
                this.editDocumentMode = false;
                this.addDocumentMode = true;

                this.toggleEditDocumentDialog();
            },
            onAddPlayerDraftDetail() {
                this.editDraftDetailMode = false;
                this.addDraftDetailMode = true;

                this.toggleUpsertDraftDetailDialog();
            },
            onAddPlayerRightsDetail() {
                this.editRightsDetailMode = false;
                this.addRightsDetailMode = true;

                this.toggleUpsertRightsDetailDialog();
            },
            onActionConfirmed() {
                this[this.confirmFunction]();
            },
            onAddPlayerAssociation() {
                this.editAssociationMode = false;
                this.addAssociationMode = true;

                this.toggleEditAssociationDialog();
            },
            onAddPlayerInjury() {
                this.editInjuryMode = false;
                this.addInjuryMode = true;

                this.toggleUpsertInjuryDialog();
            },
            onBackToDashboard() {
                this.$emit('returntodashboard');
            },
            onBackToPlayers() {
                this.$router.push('/PlayerManagement');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDeleteDocument(document) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedDocument = document;
                this.confirmFunction = 'deleteDocument';
                this.confirmMsg = `Are you sure you would like to delete ${document.documentName}?`;
                this.toggleConfirmDialog();
            },
            onDeletePlayerAssociation(association) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedAssociation = association;
                this.confirmFunction = 'deletePlayerAssociation';
                this.confirmMsg = `Are you sure you would like to delete ${association.firstName} ${association.lastName}?`;
                this.toggleConfirmDialog();
            },
            onDeletePlayerDraftDetail(detail) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedDraftDetail = detail;
                this.confirmFunction = 'deleteDraftDetail';
                this.confirmMsg = `Are you sure you would like to delete this record?`;
                this.toggleConfirmDialog();
            },
            onDeletePlayerInjury(injury) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedInjury = injury;
                this.confirmFunction = 'deletePlayerInjury';
                this.confirmMsg = `Are you sure you would like to delete this record?`;
                this.toggleConfirmDialog();
            },
            onDeletePlayerRightsDetail(detail) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedRightsDetail = detail;
                this.confirmFunction = 'deleteRightsDetail';
                this.confirmMsg = `Are you sure you would like to delete this record?`;
                this.toggleConfirmDialog();
            },
            onDeletePlayerScoutingReport(report) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deletedScoutingReport = report;
                this.confirmFunction = 'deleteScoutingReport';
                this.confirmMsg = `Are you sure you would like to delete this record?`;
                this.toggleConfirmDialog();
            },
            onDiscardChanges() {
                this.assignEditablePlayerProps();
            },
            onDraftEligibleYearEntered(evt) {
                if (!evt.value) {
                    this.playerDetail.draftEligibleDate_formatted = null;
                }

                if (evt.value < 1000) return;

                this.playerDetail.draftEligibleDate_formatted = new Date(evt.value, 5, 1);
            },
            onEditCitizenshipDocument(document) {
                this.editDocumentMode = true;
                this.addDocumentMode = false;
                this.selectedDocument = document;

                this.toggleEditDocumentDialog();
            },
            onEditorFocus() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() === this.defaultEditorContent) {
                    this.$refs.noteseditor.setHTML('');
                }
            },
            onEditPlayerAssociation(association) {
                this.editAssociationMode = true;
                this.addAssociationMode = false;

                this.selectedAssociation = association;

                this.toggleEditAssociationDialog();
            },
            onEditPlayerDraftDetail(detail) {
                this.editDraftDetailMode = true;
                this.addDraftDetailMode = false;

                this.selectedDraftDetail = detail;

                this.toggleUpsertDraftDetailDialog();
            },
            onEditPlayerInjury(injury) {
                this.editInjuryMode = true;
                this.addInjuryMode = false;

                this.selectedInjury = injury;

                this.toggleUpsertInjuryDialog();
            },
            onEditPlayerDocument(document) {
                this.editedPlayerDocument = cloneDeep(document);

                this.toggleEditPlayerDocumentDialog();
            },
            onEditPlayerRightsDetail(detail) {
                this.editRightsDetailMode = true;
                this.addRightsDetailMode = false;

                this.selectedRightsDetail = detail;

                this.toggleUpsertRightsDetailDialog();
            },
            onEditScoutingReport(report) {
                this.selectedScoutingReport = report;

                this.toggleEditScoutingReportDialog();
            },
            onMilestoneTabSelected(evt) {
                this.selectedMilestoneTab = evt.selected;
            },
            async onOpenDocument(evt) {
                let selectedPlayerDocument = evt.dataItem;
                let routeData = this.$router.resolve({name: 'documentview', query: { id: selectedPlayerDocument.documentId }});
                window.open(routeData.href, '_blank');
            },
            onPlayerHistoryFieldSelected(evt) {
                this.selectedHistoryField = evt.value;
            },
            onPlayerHistoryFilterChange(evt) {
                this.filteredPlayerHistoryFields = filterBy(this.playerHistoryFields, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onTabSelected(evt) {
                this.selectedTab = evt.selected;
            },
            async onUpdatePlayer() {
                await this.player$.$validate();

                if (this.player$.playerPositions.$error || this.player$.playerDetail.$error) return;

                this.isLoading = true;

                let request = {
                    playerId: this.uneditedPlayerDetail.id
                };

                if (!this.isEqualWith(this.playerDetail, this.uneditedPlayerDetail)) {
                    this.detailDateFields.forEach(field => {
                        this.playerDetail[field] = this.playerDetail[`${field}_formatted`];
                    });

                    request.player = cloneDeep(this.playerDetail);
                    request.player.lastUpdateBy = auth.user()?.name; 
                    request.playerUpdated = true;
                }

                if (!this.isEqualWith(this.playerMilestone, this.uneditedPlayerMilestone)) {
                    this.milestoneDateFields.forEach(field => {
                        this.playerMilestone[field] = this.playerMilestone[`${field}__formatted`] ?? '';
                    });

                    request.milestone = this.playerMilestone;

                    if (this.milestoneModelAdded) {
                        request.milestoneAdded = true;
                        request.milestone.playerId = this.playerDetail.id;
                    } else {
                        request.milestoneUpdated = true;
                    }
                }

                if (!this.isEqualWith(this.playerContracts, this.uneditedPlayerContracts)) {
                    this.playerContracts.forEach(contract => {
                        this.contractDateFields.forEach(field => {
                            contract[field] = contract[`${field}__formatted`] ?? '';
                        })
                    });

                    request.contracts = this.playerContracts;
                    request.contractsUpdated = true;
                }

                if (!this.isEqualWith(cloneDeep(this.playerPositions).sort(), cloneDeep(this.uneditedPlayerPositions).sort())) {
                    request.positions = this.playerPositions;
                    request.uneditedPositions = this.uneditedPlayerPositions;
                    request.positionsUpdated = true;
                }

                this.updatePlayer(request);
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleEditAssociationDialog() {
                this.showPlayerAssociationDialog = !this.showPlayerAssociationDialog;

                if (!this.showPlayerAssociationDialog) {
                    this.selectedAssociation = null;
                }
            },
            toggleEditDocumentDialog() {
                this.showEditDocumentDialog = !this.showEditDocumentDialog;

                if (!this.showEditDocumentDialog) {
                    this.selectedDocument = null;
                }
            },
            toggleEditPlayerDocumentDialog() {
                this.showEditPlayerDocumentDialog = !this.showEditPlayerDocumentDialog;

                if (!this.showEditPlayerDocumentDialog) {
                    this.editedPlayerDocument = null;
                }
            },
            toggleEditScoutingReportDialog() {
                this.showEditScoutingReportDialog = !this.showEditScoutingReportDialog;

                if (!this.showEditScoutingReportDialog) {
                    this.selectedScoutingReport = null;
                }
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleUploadDocumentDialog() {
                if (!this.showUploadDocumentDialog && !this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before managing documents');
                }

                this.showUploadDocumentDialog = !this.showUploadDocumentDialog;
            },
            toggleUploadDocumentsDialog() {
                this.showUploadDocumentsDialog = !this.showUploadDocumentsDialog;
            },
            toggleUpsertDraftDetailDialog() {
                if (!this.showDraftDetailDialog && !this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before managing draft details');
                }

                this.showDraftDetailDialog = !this.showDraftDetailDialog;

                if (!this.showDraftDetailDialog) {
                    this.selectedDraftDetail = null;
                }
            },
            toggleUpsertInjuryDialog() {
                this.showPlayerInjuryDialog = !this.showPlayerInjuryDialog;

                if (!this.showPlayerInjuryDialog) {
                    this.selectedInjury = null;
                }
            },
            toggleUpsertRightsDetailDialog() {
                if (!this.showRightsDetailDialog && !this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before managing rights details');
                }

                this.showRightsDetailDialog = !this.showRightsDetailDialog;

                if (!this.showRightsDetailDialog) {
                    this.selectedRightsDetail = null;
                }
            },
            async updateAssociations(associations) {
                this.uneditedPlayerAssociations = associations;
            },
            async updateDocuments() {
                await this.$store.dispatch('getCitizenshipDocuments');

                this.uneditedPlayerCitizenshipDocs = cloneDeep(this.$store.state.citizenshipDocuments.filter(doc => doc.player_id === this.uneditedPlayerDetail.id));

                this.assignCalendarItems();
            },
            async updateDraftDetails(playerDetail) {
                this.assignAndFormatData(playerDetail);
                await this.$store.dispatch('getPlayers', true);
            },
            async updateInjuries(injuries) {
                injuries.forEach(injury => {
                    this.injuryDateFields.forEach(field => {
                        injury[field] = formatHelpers.formatDate(injury[field]);
                    });
                });

                this.uneditedPlayerInjuries = injuries;

                this.assignCalendarItems();
            },
            async updatePlayer(request) {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdatePlayer(tokenResponse.accessToken, request)
                    .then(async (res) => {
                        if (res.data.data) {
                            self.assignAndFormatData(res.data.data);
                            await this.$store.dispatch('getPlayers', true);

                            self.isLoading = false;
                            self.showNotification('Player updated successfullly');
                        } else {
                            self.onRequestError('An Error Occurred', 'Failed to update player');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            updatePlayerDocuments(documents) {
                documents = this.formatHockeyDocumentDates(documents);

                this.uneditedPlayerDocuments = documents;
                this.playerDocuments = cloneDeep(this.uneditedPlayerDocuments);


                if (this.selectedFolderFilter && this.selectedFolderFilter.name !== 'All Folders') {
                    this.playerDocuments = this.uneditedPlayerDocuments.filter(doc => doc.documentFolder === this.selectedFolderFilter.name);
                }
            },
            async updateRightsDetails(playerDetail) {
                this.assignAndFormatData(playerDetail);
                await this.$store.dispatch('getPlayers', true);
            },
            updateScoutingReports() {
                let scoutingReports = this.$store.state.scoutingReports.filter(report => report.playerId === this.playerId);

                scoutingReports.forEach(report => {
                    report.playerPositions = this.playerPositions;

                    this.scoutingReportDateFields.forEach(field => {
                        report[field] = formatHelpers.formatDate(report[field]);
                    });
                });

                this.uneditedPlayerScoutingReports = scoutingReports;
            }
        },
        filters: {
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            },
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'view-header': viewHeader,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-input': Input,
            'k-dropdown': DropDownList,
            'k-checkbox': Checkbox,
            'k-tabstrip': TabStrip,
            'k-button': Button,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-listview': ListView,
            'k-listview-footer': ListViewFooter,
            'upsert-document-dialog': upsertCitizenshipDocumentDialog,
            'upsert-player-document-dialog': upsertDocumentDialog,
            'k-editor': Editor,
            'k-scheduler': Scheduler,
            'confirm-dialog': confirmDialog,
            'upsert-association-dialog': upsertPlayerAssociationDialog,
            'upload-documents-dialog': uploadDocumentsDialog,
            'edit-player-document-dialog': editPlayerDocumentDialog,
            'detail-view-grid': detailViewGrid,
            'upsert-injury-dialog': upsertPlayerInjuryDialog,
            'upsert-draft-detail-dialog': upsertDraftDetailDialog,
            'upsert-rights-detail-dialog': upsertRightsDetailDialog,
            'k-multiselect': MultiSelect,
            'update-scouting-report-dialog': updateScoutingReportDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/playerDetailView.css';
</style>