<template>
    <div class="nhl-dashboard-component">
        <k-tabstrip class="team-data-tabstrip" :selected="selectedTeamDataTab" @select="onTeamDataTabSelected" :tabs="teamDataTabs">
            <template v-slot:capspace>
                <view-header :title="'Cap Space'">
                    <template v-slot:viewicon>
                        <i class="fa-duotone fa-solid fa-layer-group"></i>
                    </template>
                </view-header>
                <div class="grid-container cap-space">
                    <detail-view-grid :columns="capSpaceColumns"
                                    :initial-sort="[{ field: 'teamName', dir: 'asc' }]"
                                    :item-id-field="'teamId'"
                                    :items="teamData"
                                    :delete-action="false"
                                    :allow-export="true"
                                    :elite-prospects-search="true"
                                    :row-clickable="true"
                                    :row-click-function="onGoToTeam"
                                    :export-title="'Team Cap Space'"></detail-view-grid>
                </div>
            </template>
            <template v-slot:positionalbreakdown>
                <view-header :title="'Positional Breakdown'">
                    <template v-slot:viewicon>
                        <i class="fa-duotone fa-solid fa-layer-group"></i>
                    </template>
                </view-header>
                <div class="grid-container positional-breakdown">
                    <detail-view-grid :columns="positionalBreakdownColumns"
                                    :initial-sort="[{ field: 'teamName', dir: 'asc' }]"
                                    :item-id-field="'teamId'"
                                    :items="teamData"
                                    :delete-action="false"
                                    :allow-export="true"
                                    :elite-prospects-search="true"
                                    :row-clickable="true"
                                    :row-click-function="onGoToTeam"
                                    :export-title="'Team Cap Space Breakdown'"></detail-view-grid>
                </div>
            </template>
            <!-- <template v-slot:taxcalculator>
                <view-header :title="'Tax Calculator'">
                    <template v-slot:viewicon>
                        <i class="fa-sharp-duotone fa-solid fa-calculator-simple"></i>
                    </template>
                </view-header>
                <div class="grid-container tax-calculator">
                    <iframe width="100%" src="https://cardinalpointathleteadvisors.com/embed-tax-calculator"></iframe>
                </div>
            </template> -->
        </k-tabstrip>

        <nhl-team-detail-dialog :show-dialog="showTeamDetailDialog" 
                                :team="selectedTeam"
                                :team-logos="teamLogos"
                                @dialogclosed="toggleTeamDetailDialog" />

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Dialog } from '@progress/kendo-vue-dialogs';

    import detailViewGrid from '../components/detailViewGrid.vue';
    import viewHeader from '../components/viewHeader.vue';
    import nhlTeamDetailDialog from './nhlTeamDetailDialog.vue';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'nhlDashboardComponent',
        data() {
            return {
                capSpaceColumns: [
                    { cell: 'teamName', field: 'teamName', title: 'Team' },
                    { field: 'currentCapSpace', title: 'Cap Space', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'teamCapHit', field: 'currentRosterAnnualCapHit', title: 'Cap Hit', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { field: 'rosterString', title: 'Active Roster' },
                    { field: 'contractsString', title: 'Contracts' },
                    { field: 'retainedSalaryString', title: 'Retained Slots' }
                ],
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                positionalBreakdownColumns: [
                    { cell: 'teamName', field: 'teamName', title: 'Team' },
                    { cell: 'capHitForwards', field: 'currentRosterAnnualCapHitForwards', title: 'Forwards', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitDefense', field: 'currentRosterAnnualCapHitDefense', title: 'Defense', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitGoalies', field: 'currentRosterAnnualCapHitGoalies', title: 'Goalies', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitLtir', field: 'currentRosterLtir', title: 'LTIR', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitDead', field: 'currentRosterDeadCapHit', title: 'Dead Cap', type: 'number', filter: 'numeric', format: '{0:c0}' }

                ],
                selectedTeam: null,
                selectedTeamDataTab: 0,
                showErrorDialog: false,
                showTeamDetailDialog: false,
                teamData: [],
                teamDataTabs: [
                    {
                        title: 'Cap Space',
                        content: 'capspace'
                    },
                    {
                        title: 'Positional Breakdown',
                        content: 'positionalbreakdown'
                    },
                    // {
                    //     title: 'Tax Calculator',
                    //     content: 'taxcalculator'
                    // }
                ]
            }
        },
        computed: {
            teamLogos() {
                return this.teamData?.map(team => {
                    return {
                        teamId: team.puckPediaId,
                        logo: team.teamLogo
                    }
                });
            }
        },
        mounted() {
            this.getNhlDashboardData();
        },
        methods: {
            async getNhlDashboardData() {
                this.isLoading = true;

                let request = {
                    nhl_team_id: null,
                    team_id: null
                }

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaTeams(tokenResponse.accessToken, request)
                    .then((res) => {
                        if (res?.data?.data) {
                            res.data.data.forEach(team => {
                                team.chartSeries = [
                                    {
                                        title: 'Forwards',
                                        dataItems: [team.currentRosterAnnualCapHitForwards]
                                    },
                                    {
                                        title: 'Defense',
                                        dataItems: [team.currentRosterAnnualCapHitDefense]
                                    },
                                    {
                                        title: 'Goalies',
                                        dataItems: [team.currentRosterAnnualCapHitGoalies]
                                    },
                                    {
                                        title: 'Dead Cap',
                                        dataItems: [team.currentRosterDeadCapHit]
                                    }
                                ]
                            });

                            this.teamData = res.data.data;
                        }

                        this.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })

            },
            onGoToTeam(evt) {
                this.selectedTeam = evt.dataItem;
                this.toggleTeamDetailDialog();
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onTeamDataTabSelected(evt) {
                this.selectedTeamDataTab = evt.selected;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleTeamDetailDialog() {
                this.showTeamDetailDialog = !this.showTeamDetailDialog
            }
        },
        components: {
            'detail-view-grid': detailViewGrid,
            'view-header': viewHeader,
            'k-loader': Loader,
            'k-tabstrip': TabStrip,
            'k-dialog': Dialog,
            'nhl-team-detail-dialog': nhlTeamDetailDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/nhlDashboardComponent.css';
</style>