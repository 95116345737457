<template>
    <div class="nhl-team-detail">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog nhl-team-detail-dialog">
                <div class="dialog-body">
                    <section class="section section--header">
                        <div class="team-logo-container">
                            <img class="team-logo" :src="teamData?.logo" />
                        </div>
                        <div class="team-title-container" v-if="teamData">
                            <h3 class="team-name">{{ teamData?.name }}</h3>
                            <h4 class="team-cap-hit">Cap Hit: {{ $options.filters.formatCurrency(teamData?.seasons[0]?.current_roster_annual_cap_hit) }}</h4>
                        </div>
                        <div class="section-nav"  v-if="teamData">
                            <div v-for="item in navItems" :key="item" :class="['nav-item', { 'active': selectedNavItem === item}]" @click="goToNavItem(item)">
                                <p class="nav-item-text">{{ item }}</p>
                            </div>
                        </div>
                        <div class="dialog-close" @click="onCloseDialog"><k-button class="close-btn"><i class="fa-solid fa-left"></i> Back to Dashboard</k-button></div>
                    </section>
                    <section class="section section--overview" v-if="selectedNavItem === 'Overview' && teamData && draftData">
                        <div class="cap-overview-container">
                            <div class="cap-row cap-title-row">
                                <div class="cap-row-item">Cap Hit</div>
                                <div class="cap-row-item">Cap Space</div>
                                <div class="cap-row-item">LTIR</div>
                                <div class="cap-row-item">Roster</div>
                                <div class="cap-row-item">Contracts</div>
                                <div class="cap-row-item">Carryover Bonuses</div>
                            </div>
                            <div class="cap-row">
                                <div class="cap-row-item">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit) }}</div>
                                <div class="cap-row-item">{{ $options.filters.formatCurrency(currentCapData.current_cap_space) }}</div>
                                <div class="cap-row-item">{{ $options.filters.formatCurrency(currentCapData.ltir) }}</div>
                                <div class="cap-row-item">{{ currentCapData.roster }} / 23</div>
                                <div class="cap-row-item">{{ currentCapData.contracts }} / 50</div>
                                <div class="cap-row-item">{{ $options.filters.formatCurrency(currentCapData.carryover_overages) }}</div>
                            </div>
                        </div>

                        <h4 class="overview-category">Draft Picks</h4>
                        <div class="draft-overview-container">
                            <div class="draft-row draft-title-row">
                                <div class="draft-row-item">Year</div>
                                <div v-for="(round, index) in 7" :key="index" class="draft-row-item">Round {{ index + 1 }}</div>
                            </div>
                            <div class="draft-row" v-for="(draft, index) in draftData" :key="draft.draftYear">
                                <div class="draft-row-item">{{ draft.draftYear }}</div>
                                <div class="draft-row-item" v-for="number in 7" :key="number">
                                    <span v-for="pick in draft.drafts.filter(d => d.round == number)" :key="pick.draft_pick_id" class="pick-img-container">
                                        <img v-if="!pick.originalTeamLogo" :src="pick.toTeamLogo" />
                                        <img :title="`Traded Away ${pick.tradeDate.toLocaleDateString()}`" v-if="pick.status === 'Traded Away'" :src="pick.originalTeamLogo" class="traded-away" />
                                        <img :title="`Traded To ${pick.tradeDate.toLocaleDateString()}`" v-if="pick.status === 'Traded To'" :src="pick.originalTeamLogo" class="traded-to" />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <h4 class="overview-category">Roster</h4>
                        <div class="roster-grids-container">
                            <div class="roster-grid">
                                <view-header :title="'Forwards'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getProgressValue('forwards')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_forwards) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                            <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('forwards') }}% of cap</p>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.forwards"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('forwards', 1)"
                                                    :year2Total="getColumnSeasonTotal('forwards', 2)"
                                                    :year3Total="getColumnSeasonTotal('forwards', 3)"
                                                    :year4Total="getColumnSeasonTotal('forwards', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Forwards`"></detail-view-grid>
                                </div>  
                            </div>

                            <div class="roster-grid">
                                <view-header :title="'Defense'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getProgressValue('defence')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_defence) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                            <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('defence') }}% of cap</p>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.defense"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('defense', 1)"
                                                    :year2Total="getColumnSeasonTotal('defense', 2)"
                                                    :year3Total="getColumnSeasonTotal('defense', 3)"
                                                    :year4Total="getColumnSeasonTotal('defense', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Defense`"></detail-view-grid>
                                </div>
                            </div>

                            <div class="roster-grid">
                                <view-header :title="'Goalies'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getProgressValue('goalies')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_goalies) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                            <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('goalies') }}% of cap</p>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.goalies"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('goalies', 1)"
                                                    :year2Total="getColumnSeasonTotal('goalies', 2)"
                                                    :year3Total="getColumnSeasonTotal('goalies', 3)"
                                                    :year4Total="getColumnSeasonTotal('goalies', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Goalies`"></detail-view-grid>
                                </div>
                            </div>

                            <h4 class="overview-category">Buried</h4>
                            <div class="roster-grid">
                                <view-header :title="'Buried'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container" v-if="roster.buried?.length > 0">
                                                <k-progressbar :value="getProgressValue('buried')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_buried) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                            <p v-if="selectedRosterDataSelect === 'Cap Hit' && roster.buried?.length > 0" class="cap-percent">{{ getProgressValue('buried') }}% of cap</p>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.buried"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('buried', 1)"
                                                    :year2Total="getColumnSeasonTotal('buried', 2)"
                                                    :year3Total="getColumnSeasonTotal('buried', 3)"
                                                    :year4Total="getColumnSeasonTotal('buried', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Buried`"></detail-view-grid>
                                </div>
                            </div>

                            <h4 class="overview-category">Buyout / Retained</h4>
                            <div class="roster-grid">
                                <view-header :title="'Buyout/Retained'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects.filter(select => select === 'Cap Hit' || select === 'AAV')"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container" v-if="roster.buyouts?.length > 0">
                                                <k-progressbar :value="getProgressValue('buyout')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_buyouts + currentCapData?.current_roster_annual_cap_hit_retained + currentCapData?.current_roster_annual_cap_hit_other) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buyouts', 'year1Aav')) }}</p>
                                            </div>
                                            <p v-if="selectedRosterDataSelect === 'Cap Hit' && roster.buyouts?.length > 0" class="cap-percent">{{ getProgressValue('buyout') }}% of cap</p>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="getBuyoutsRosterColumns()"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.buyouts"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('buyouts', 1)"
                                                    :year2Total="getColumnSeasonTotal('buyouts', 2)"
                                                    :year3Total="getColumnSeasonTotal('buyouts', 3)"
                                                    :year4Total="getColumnSeasonTotal('buyouts', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Buyouts/Retained`"></detail-view-grid>
                                </div>
                            </div>

                            <h4 class="overview-category">Non-Roster</h4>
                            <div class="roster-grid">
                                <view-header :title="'Non-Roster Forwards'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getNonRosterProgressValue('nrForwards')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Cap')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.nrForwards"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('nrForwards', 1)"
                                                    :year2Total="getColumnSeasonTotal('nrForwards', 2)"
                                                    :year3Total="getColumnSeasonTotal('nrForwards', 3)"
                                                    :year4Total="getColumnSeasonTotal('nrForwards', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Forwards`"></detail-view-grid>
                                </div>
                            </div>

                            <div class="roster-grid">
                                <view-header :title="'Non-Roster Defense'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getNonRosterProgressValue('nrDefense')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Cap')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.nrDefense"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('nrDefense', 1)"
                                                    :year2Total="getColumnSeasonTotal('nrDefense', 2)"
                                                    :year3Total="getColumnSeasonTotal('nrDefense', 3)"
                                                    :year4Total="getColumnSeasonTotal('nrDefense', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Defense`"></detail-view-grid>
                                </div>
                            </div>

                            <div class="roster-grid">
                                <view-header :title="'Non-Roster Goalies'">
                                    <template v-slot:viewicon>
                                        <i class="fa-solid fa-hockey-sticks"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                        <div class="filters-container">
                                            <k-dropdown class="roster-data-dropdown"
                                                        :data-items="rosterDataSelects"
                                                        v-model="selectedRosterDataSelect"></k-dropdown>
                                            <div class="progress-container">
                                                <k-progressbar :value="getNonRosterProgressValue('nrGoalies')" />
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Cap')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Aav')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Salary')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1SigningBonus')) }}</p>
                                                <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1PerformanceBonus')) }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </view-header>
                                <div class="grid-container">
                                    <detail-view-grid :columns="rosterColumns"
                                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :item-id-field="'player_id'"
                                                    :items="roster.nrGoalies"
                                                    :row-clickable="true"
                                                    :row-click-function="onGoToPlayer"
                                                    :delete-action="false"
                                                    :allow-export="true"
                                                    :not-pageable="true"
                                                    :year1Total="getColumnSeasonTotal('nrGoalies', 1)"
                                                    :year2Total="getColumnSeasonTotal('nrGoalies', 2)"
                                                    :year3Total="getColumnSeasonTotal('nrGoalies', 3)"
                                                    :year4Total="getColumnSeasonTotal('nrGoalies', 4)"
                                                    nhl-team-detail="true"
                                                    :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Goalies`"></detail-view-grid>
                                </div>
                            </div>
                        </div>

                        <h4 class="overview-category">{{ currentSeasonEnd }} Free Agents</h4>
                        <div class="free-agents-container">
                            <div class="agents-list-container">
                                <div class="free-agent-header">
                                    <h5><i class="fa-duotone fa-solid fa-ice-skate"></i> RFA Free Agents</h5>
                                </div>
                                <k-listview :data-items="freeAgents.filter(a => a.expiryStatus === 'RFA')"
                                            :item="'agent'"
                                            :header="'agentHeader'"
                                            :footer="'agentFooter'"
                                            class="free-agents-listview">
                                    <template v-slot:agentHeader>
                                        <k-listview-header>
                                            <div class="agent-row">
                                                <div class="agent-row-item name">
                                                    <p>Player</p>
                                                </div>
                                                <div class="agent-row-item age">
                                                    <p>Age</p>
                                                </div>
                                                <div class="agent-row-item position">
                                                    <p>Position</p>
                                                </div>
                                                <div class="agent-row-item aav">
                                                    <p>AAV</p>
                                                </div>
                                            </div>
                                        </k-listview-header>
                                    </template>
                                    <template v-slot:agent="{ props }">
                                        <div class="agent-row">
                                            <div class="agent-row-item name">
                                                <p>{{ props.dataItem.fullName }} <span class="nr" v-if="props.dataItem.active === '0'">&nbsp;(NR)</span></p>
                                            </div>
                                            <div class="agent-row-item age">
                                                <p>{{ props.dataItem.age }}</p>
                                            </div>
                                            <div class="agent-row-item position">
                                                <p>{{ props.dataItem.position }}</p>
                                            </div>
                                            <div class="agent-row-item aav">
                                                <p>{{ $options.filters.formatCurrency(props.dataItem.year1Aav) }}</p>
                                            </div>
                                        </div>
                                    </template>  
                                    <template v-slot:agentFooter>
                                        <k-listview-footer>
                                            Total: {{ freeAgents.filter(a => a.expiryStatus === 'RFA').length }}
                                        </k-listview-footer>
                                    </template>
                                </k-listview>
                            </div>
                            <div class="agents-list-container">
                                <div class="free-agent-header">
                                    <h5><i class="fa-sharp-duotone fa-solid fa-hockey-sticks"></i> UFA Free Agents</h5>
                                </div>
                                <k-listview :data-items="freeAgents.filter(a => a.expiryStatus === 'UFA')"
                                            :item="'agent'"
                                            :header="'agentHeader'"
                                            :footer="'agentFooter'"
                                            class="free-agents-listview">
                                    <template v-slot:agentHeader>
                                        <k-listview-header>
                                            <div class="agent-row">
                                                <div class="agent-row-item name">
                                                    <p>Player</p>
                                                </div>
                                                <div class="agent-row-item age">
                                                    <p>Age</p>
                                                </div>
                                                <div class="agent-row-item position">
                                                    <p>Position</p>
                                                </div>
                                                <div class="agent-row-item aav">
                                                    <p>AAV</p>
                                                </div>
                                            </div>
                                        </k-listview-header>
                                    </template>
                                    <template v-slot:agent="{ props }">
                                        <div class="agent-row">
                                            <div class="agent-row-item name">
                                                <p>{{ props.dataItem.fullName }} <span class="nr" v-if="props.dataItem.active === '0'">&nbsp;(NR)</span></p>
                                            </div>
                                            <div class="agent-row-item age">
                                                <p>{{ props.dataItem.age }}</p>
                                            </div>
                                            <div class="agent-row-item position">
                                                <p>{{ props.dataItem.position }}</p>
                                            </div>
                                            <div class="agent-row-item aav">
                                                <p>{{ $options.filters.formatCurrency(props.dataItem.year1Aav) }}</p>
                                            </div>
                                        </div>
                                    </template>  
                                    <template v-slot:agentFooter>
                                        <k-listview-footer>
                                            Total: {{ freeAgents.filter(a => a.expiryStatus === 'UFA').length }}
                                        </k-listview-footer>
                                    </template>
                                </k-listview>
                            </div>
                        </div>

                    </section>
                    <section class="section section--cap-data" v-if="selectedNavItem === 'Cap Data' && teamData">
                        <k-tabstrip :selected="selectedCapTab" @select="onCapTabSelected" :tabs="capTabs">
                            <template v-slot:currentcap>
                                 <view-header :title="'Current Cap'">
                                    <template v-slot:viewicon>
                                        <i class="fa-regular fa-chart-mixed-up-circle-dollar"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                    </template>
                                </view-header>
                                <div class="summary-data">
                                    <h5 class="current-season">{{ currentCapData.season }} Current Cap</h5>
                                    <div class="cap-data">
                                        <div class="cap-data-row">
                                            <div class="cap-row-item">
                                                <p class="item-title">Projected Cap Space</p>
                                                <div :class="['item-value', {'negative': currentCapData.projected_cap_space < 0}]">{{ $options.filters.formatCurrency(currentCapData.projected_cap_space) }}</div>
                                            </div>
                                            <div class="cap-row-item">
                                                <p class="item-title">Current Cap Space</p>
                                                <div :class="['item-value', {'negative': currentCapData.current_cap_space < 0}]">{{ $options.filters.formatCurrency(currentCapData.current_cap_space) }}</div>
                                            </div>
                                            <div class="cap-row-item">
                                                <p class="item-title">Deadline Cap Space</p>
                                                <div class="item-value neutral">{{ $options.filters.formatCurrency(currentCapData.deadline_cap_space) }}</div>
                                            </div>
                                            <div class="cap-row-item">
                                                <p class="item-title">LTIR Pool</p>
                                                <div class="item-value neutral">{{ $options.filters.formatCurrency(currentCapData.ltir) }}</div>
                                            </div>
                                        </div>
                                        <div class="cap-chart-data">
                                            <p class="chart-title">Projected Cap Hit: <span :class="['proj-cap-hit', {'over': currentCapData.projected_cap_hit > 88000000 }]">{{ $options.filters.formatCurrency(currentCapData.projected_cap_hit) }}</span></p>
                                            <k-chart :style="{ height: '80px' }">
                                                <k-chart-series>
                                                    <k-chart-series-item :type="'bullet'"
                                                                         :color="'#D4B139'"
                                                                         :data-items="capHitChartItem" />
                                                </k-chart-series>
                                                <k-chart-category-axis>
                                                    <k-chart-category-axis-item
                                                        :major-grid-lines="hidden"
                                                        :minor-grid-lines="hidden" />
                                                </k-chart-category-axis>
                                                <k-chart-value-axis>
                                                    <k-chart-value-axis-item
                                                        :labels="{ content: labelContent }" 
                                                        :major-grid-lines="hidden"
                                                        :minor-ticks="hidden"
                                                        :min="65000000"
                                                        :max="100000000"
                                                        :plot-bands="capHitChartPlotBands" />
                                                </k-chart-value-axis>
                                            </k-chart>
                                            <div class="salary-floor-cap">Salary Floor: <span>{{ $options.filters.formatCurrency(currentCapData.salary_floor) }}</span> | Salary Cap: <span>{{ $options.filters.formatCurrency(currentCapData.salary_cap) }}</span></div>
                                            <div class="season-summary-container">
                                                <div class="season-summary">
                                                    <div class="summary-row">
                                                        <div class="summary-row-item">
                                                            <p class="field">Annual Cap Hit</p>
                                                            <p>{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit) }}</p>
                                                        </div>
                                                        <div class="summary-row-item">
                                                            <p class="field">Active Roster</p>
                                                            <p>{{ currentCapData.roster }}/23</p>
                                                        </div>
                                                    </div>
                                                    <div class="summary-row">
                                                        <div class="summary-row-item">
                                                            <p class="field">Bonus Overages</p>
                                                            <p>{{ $options.filters.formatCurrency(currentCapData.carryover_overages) }}</p>
                                                        </div>
                                                        <div class="summary-row-item">
                                                            <p class="field">Standard Contracts</p>
                                                            <p>{{ currentCapData.contracts }}/50</p>
                                                        </div>
                                                    </div>
                                                    <div class="summary-row">
                                                        <div class="summary-row-item">
                                                            <p class="field">Potential Bonuses</p>
                                                            <p>{{ $options.filters.formatCurrency(currentCapData.potential_bonuses) }}</p>
                                                        </div>
                                                        <div class="summary-row-item">
                                                            <p class="field">Retained Remaining</p>
                                                            <p>{{ currentCapData.retained_salary_remaining }}/3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cap-breakdown-container">
                                                <h5 class="cap-breakdown-title">Cap Hit Detail</h5>
                                                <div class="cap-breakdown-row">
                                                    <p class="cap-breakdown-row-item">Forwards <span class="value">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit_forwards) }}</span></p>
                                                    <p class="cap-breakdown-row-item">Defense <span class="value">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit_defence) }}</span></p>
                                                    <p class="cap-breakdown-row-item">Goalies <span class="value">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit_goalies) }}</span></p>
                                                </div>
                                                <div class="cap-breakdown-row">
                                                    <p class="cap-breakdown-row-item">Buyouts <span class="value">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit_buyouts) }}</span></p>
                                                    <p class="cap-breakdown-row-item">Dead <span class="value">{{ $options.filters.formatCurrency(currentCapData.dead_cap_hit) }}</span></p>
                                                    <p class="cap-breakdown-row-item">Retained <span class="value">{{ $options.filters.formatCurrency(currentCapData.current_roster_annual_cap_hit_retained) }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:capprojections>
                                 <view-header :title="'Cap Projections'">
                                    <template v-slot:viewicon>
                                        <i class="fa-regular fa-chart-mixed-up-circle-dollar"></i>
                                    </template>
                                    <template v-slot:viewfilters>
                                    </template>
                                </view-header>
                                <div class="projection-rows-container">
                                    <div class="projection-row season">
                                        <div class="projection-row-item title season">Projection</div>
                                        <div class="projection-row-item season" v-for="season in teamData.seasons?.filter((team, index) => index < 4)" :key="season.season_id">
                                            <span>{{ season.season }}</span>
                                        </div>
                                    </div>
                                    <div class="projection-row" v-for="row in capProjectionRows" :key="row.display">
                                        <div class="projection-row-item title">{{ row.display }}</div>
                                        <div class="projection-row-item" v-for="season in teamData.seasons?.filter((team, index) => index < 4)" :key="season.season_id">
                                            <span v-if="row.type === 'currency'" :class="[{'color-code': row.colorCode}, { 'negative': season[row.field] < 0 }]">{{ $options.filters.formatCurrency(season[row.field]) }}</span>
                                            <span v-if="row.field === 'roster'">{{ season[row.field] }}<span class="fade">/ 23</span></span>
                                            <span v-if="row.field === 'contracts'">{{ season[row.field] }}<span class="fade">/ 50</span></span>
                                            <span v-if="row.type === 'number'">{{ season[row.field] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </k-tabstrip>
                        <section class="section section--news">
                            <div class="news-container">
                                <div class="card-container">
                                    <dashboard-puckpedia-component :component-title="'Team News'"
                                                                   :team-id="teamData?.team_id"></dashboard-puckpedia-component>
                                </div>
                            </div>
                        </section>
                    </section>
                    <section class="section section--roster" v-if="selectedNavItem === 'Roster' && teamData">
                        <k-tabstrip :selected="selectedRosterTab" @select="onRosterTabSelected" :tabs="rosterTabs">
                            <template v-slot:forwards>
                                <div v-if="selectedRosterTab === 0" class="roster-tab-container">
                                    <view-header :title="'Forwards'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getProgressValue('forwards')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_forwards) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('forwards', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('forwards') }}% of cap</p>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.forwards"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('forwards', 1)"
                                                        :year2Total="getColumnSeasonTotal('forwards', 2)"
                                                        :year3Total="getColumnSeasonTotal('forwards', 3)"
                                                        :year4Total="getColumnSeasonTotal('forwards', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Forwards`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:defense>
                                <div v-if="selectedRosterTab === 1" class="roster-tab-container">
                                    <view-header :title="'Defense'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getProgressValue('defence')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_defence) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('defense', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('defence') }}% of cap</p>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.defense"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('defense', 1)"
                                                        :year2Total="getColumnSeasonTotal('defense', 2)"
                                                        :year3Total="getColumnSeasonTotal('defense', 3)"
                                                        :year4Total="getColumnSeasonTotal('defense', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Defense`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:goalies>
                                <div v-if="selectedRosterTab === 2" class="roster-tab-container">
                                    <view-header :title="'Goalies'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getProgressValue('goalies')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_goalies) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('goalies', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="cap-percent">{{ getProgressValue('goalies') }}% of cap</p>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.goalies"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('goalies', 1)"
                                                        :year2Total="getColumnSeasonTotal('goalies', 2)"
                                                        :year3Total="getColumnSeasonTotal('goalies', 3)"
                                                        :year4Total="getColumnSeasonTotal('goalies', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Goalies`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:buried>
                                <div v-if="selectedRosterTab === 3" class="roster-tab-container">
                                    <view-header :title="'Buried'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container" v-if="roster.buried?.length > 0">
                                                    <k-progressbar :value="getProgressValue('buried')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_buried) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buried', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit' && roster.buried?.length > 0" class="cap-percent">{{ getProgressValue('buried') }}% of cap</p>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.buried"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('buried', 1)"
                                                        :year2Total="getColumnSeasonTotal('buried', 2)"
                                                        :year3Total="getColumnSeasonTotal('buried', 3)"
                                                        :year4Total="getColumnSeasonTotal('buried', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Buried`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:buyoutretained>
                                <div v-if="selectedRosterTab === 4" class="roster-tab-container">
                                    <view-header :title="'Buyout/Retained'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects.filter(select => select === 'Cap Hit' || select === 'AAV')"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container" v-if="roster.buyouts?.length > 0">
                                                    <k-progressbar :value="getProgressValue('buyout')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(currentCapData?.current_roster_annual_cap_hit_buyouts + currentCapData?.current_roster_annual_cap_hit_retained + currentCapData?.current_roster_annual_cap_hit_other) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('buyouts', 'year1Aav')) }}</p>
                                                </div>
                                                <p v-if="selectedRosterDataSelect === 'Cap Hit' && roster.buyouts?.length > 0" class="cap-percent">{{ getProgressValue('buyout') }}% of cap</p>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="getBuyoutsRosterColumns()"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.buyouts"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('buyouts', 1)"
                                                        :year2Total="getColumnSeasonTotal('buyouts', 2)"
                                                        :year3Total="getColumnSeasonTotal('buyouts', 3)"
                                                        :year4Total="getColumnSeasonTotal('buyouts', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Buyouts/Retained`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:nrforwards>
                                <div v-if="selectedRosterTab === 5" class="roster-tab-container">
                                    <view-header :title="'Non-Roster Forwards'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getNonRosterProgressValue('nrForwards')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Cap')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrForwards', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.nrForwards"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('nrForwards', 1)"
                                                        :year2Total="getColumnSeasonTotal('nrForwards', 2)"
                                                        :year3Total="getColumnSeasonTotal('nrForwards', 3)"
                                                        :year4Total="getColumnSeasonTotal('nrForwards', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Forwards`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:nrdefense>
                                <div v-if="selectedRosterTab === 6" class="roster-tab-container">
                                    <view-header :title="'Non-Roster Defense'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getNonRosterProgressValue('nrDefense')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Cap')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrDefense', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.nrDefense"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('nrDefense', 1)"
                                                        :year2Total="getColumnSeasonTotal('nrDefense', 2)"
                                                        :year3Total="getColumnSeasonTotal('nrDefense', 3)"
                                                        :year4Total="getColumnSeasonTotal('nrDefense', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Defense`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:nrgoalies>
                                <div v-if="selectedRosterTab === 7" class="roster-tab-container">
                                    <view-header :title="'Non-Roster Goalies'">
                                        <template v-slot:viewicon>
                                            <i class="fa-solid fa-hockey-sticks"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <div class="filters-container">
                                                <k-dropdown class="roster-data-dropdown"
                                                            :data-items="rosterDataSelects"
                                                            v-model="selectedRosterDataSelect"></k-dropdown>
                                                <div class="progress-container">
                                                    <k-progressbar :value="getNonRosterProgressValue('nrGoalies')" />
                                                    <p v-if="selectedRosterDataSelect === 'Cap Hit'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Cap')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'AAV'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Aav')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Salary'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1Salary')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Signing Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1SigningBonus')) }}</p>
                                                    <p v-if="selectedRosterDataSelect === 'Performance Bonus'" class="progress-overlay">{{ $options.filters.formatCurrency(getCurrentSeasonTotal('nrGoalies', 'year1PerformanceBonus')) }}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </view-header>
                                    <div class="grid-container">
                                        <detail-view-grid :columns="rosterColumns"
                                                        :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                        :item-id-field="'player_id'"
                                                        :items="roster.nrGoalies"
                                                        :row-clickable="true"
                                                        :row-click-function="onGoToPlayer"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :year1Total="getColumnSeasonTotal('nrGoalies', 1)"
                                                        :year2Total="getColumnSeasonTotal('nrGoalies', 2)"
                                                        :year3Total="getColumnSeasonTotal('nrGoalies', 3)"
                                                        :year4Total="getColumnSeasonTotal('nrGoalies', 4)"
                                                        nhl-team-detail="true"
                                                        :export-title="`${teamData?.name} ${selectedRosterDataSelect} Non-Roster Goalies`"></detail-view-grid>
                                    </div>
                                </div>
                            </template>
                        </k-tabstrip>
                        <section class="section section--free-agents">
                            <div class="agents-list-container">
                                <div class="free-agent-header">
                                    <h5><i class="fa-duotone fa-solid fa-ice-skate"></i> RFA Free Agents</h5>
                                </div>
                                <k-listview :data-items="freeAgents.filter(a => a.expiryStatus === 'RFA')"
                                            :item="'agent'"
                                            :header="'agentHeader'"
                                            :footer="'agentFooter'"
                                            class="free-agents-listview">
                                    <template v-slot:agentHeader>
                                        <k-listview-header>
                                            <div class="agent-row">
                                                <div class="agent-row-item name">
                                                    <p>Player</p>
                                                </div>
                                                <div class="agent-row-item age">
                                                    <p>Age</p>
                                                </div>
                                                <div class="agent-row-item position">
                                                    <p>Position</p>
                                                </div>
                                                <!-- <div class="agent-row-item aav">
                                                    <p>AAV</p>
                                                </div> -->
                                            </div>
                                        </k-listview-header>
                                    </template>
                                    <template v-slot:agent="{ props }">
                                        <div class="agent-row">
                                            <div class="agent-row-item name">
                                                <p>{{ props.dataItem.fullName }} <span class="nr" v-if="props.dataItem.active === '0'">&nbsp;(NR)</span></p>
                                            </div>
                                            <div class="agent-row-item age">
                                                <p>{{ props.dataItem.age }}</p>
                                            </div>
                                            <div class="agent-row-item position">
                                                <p>{{ props.dataItem.position }}</p>
                                            </div>
                                            <!-- <div class="agent-row-item aav">
                                                <p>{{ $options.filters.formatCurrency(props.dataItem.year1Aav) }}</p>
                                            </div> -->
                                        </div>
                                    </template>  
                                    <template v-slot:agentFooter>
                                        <k-listview-footer>
                                            Total: {{ freeAgents.filter(a => a.expiryStatus === 'RFA').length }}
                                        </k-listview-footer>
                                    </template>
                                </k-listview>
                            </div>
                            <div class="agents-list-container">
                                <div class="free-agent-header">
                                    <h5><i class="fa-sharp-duotone fa-solid fa-hockey-sticks"></i> UFA Free Agents</h5>
                                </div>
                                <k-listview :data-items="freeAgents.filter(a => a.expiryStatus === 'UFA')"
                                            :item="'agent'"
                                            :header="'agentHeader'"
                                            :footer="'agentFooter'"
                                            class="free-agents-listview">
                                    <template v-slot:agentHeader>
                                        <k-listview-header>
                                            <div class="agent-row">
                                                <div class="agent-row-item name">
                                                    <p>Player</p>
                                                </div>
                                                <div class="agent-row-item age">
                                                    <p>Age</p>
                                                </div>
                                                <div class="agent-row-item position">
                                                    <p>Position</p>
                                                </div>
                                                <!-- <div class="agent-row-item aav">
                                                    <p>AAV</p>
                                                </div> -->
                                            </div>
                                        </k-listview-header>
                                    </template>
                                    <template v-slot:agent="{ props }">
                                        <div class="agent-row">
                                            <div class="agent-row-item name">
                                                <p>{{ props.dataItem.fullName }} <span class="nr" v-if="props.dataItem.active === '0'">&nbsp;(NR)</span></p>
                                            </div>
                                            <div class="agent-row-item age">
                                                <p>{{ props.dataItem.age }}</p>
                                            </div>
                                            <div class="agent-row-item position">
                                                <p>{{ props.dataItem.position }}</p>
                                            </div>
                                            <!-- <div class="agent-row-item aav">
                                                <p>{{ $options.filters.formatCurrency(props.dataItem.year1Aav) }}</p>
                                            </div> -->
                                        </div>
                                    </template>  
                                    <template v-slot:agentFooter>
                                        <k-listview-footer>
                                            Total: {{ freeAgents.filter(a => a.expiryStatus === 'UFA').length }}
                                        </k-listview-footer>
                                    </template>
                                </k-listview>
                            </div>
                        </section>
                    </section>
                    <section class="section section--draft pipeline" v-if="selectedNavItem === 'Draft' && draftData?.length === 4">
                        <view-header :title="'Draft Detail'">
                            <template v-slot:viewicon>
                                <i class="fa-duotone fa-solid fa-people-group"></i>
                            </template>
                            <template v-slot:viewfilters>
                            </template>
                        </view-header>
                        <div class="more-container">
                            <p class="view-more" v-if="draftDataPage === 1" @click="draftDataPage = 2">View More <i class="fa-light fa-angle-right"></i></p>
                            <p class="view-more" v-if="draftDataPage === 2" @click="draftDataPage = 1"><i class="fa-light fa-angle-left"></i> Go Back</p>
                        </div>
                        <div class="draft-columns-container">
                            <div class="draft-columns" v-if="draftDataPage === 1">
                                <nhl-team-draft-column :draft-detail="draftData[0]" :team-id="team.puckPediaId" />
                                <nhl-team-draft-column :draft-detail="draftData[1]" :team-id="team.puckPediaId" />
                            </div>
                            <div class="draft-columns" v-if="draftDataPage === 2">
                                <nhl-team-draft-column :draft-detail="draftData[2]" :team-id="team.puckPediaId" />
                                <nhl-team-draft-column :draft-detail="draftData[3]" :team-id="team.puckPediaId" />
                            </div>
                        </div>
                    </section>
                </div>

            <div class="loader-container" v-if="isLoading">
                <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
            </div>

            </k-dialog>
        </transition>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <elite-prospects-search-dialog :show-dialog="showSearchDialog"
                                       :detail-data="selectedDetail"
                                       @dialogclosed="toggleSearchDialog"></elite-prospects-search-dialog>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { ProgressBar } from "@progress/kendo-vue-progressbars";
    import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-vue-listview';
    import { Button } from "@progress/kendo-vue-buttons";
    import {
        Chart,
        ChartTooltip,
        ChartSeries,
        ChartSeriesItem,
        ChartCategoryAxis,
        ChartCategoryAxisItem,
        ChartValueAxis,
        ChartValueAxisItem,
    } from "@progress/kendo-vue-charts";

    import 'hammerjs';

    import viewHeader from '../components/viewHeader.vue';
    import detailViewGrid from '../components/detailViewGrid.vue';
    import dashboardPuckPediaComponent from "./dashboardPuckPediaComponent.vue";
    import eliteProspectsSearchDialog from './eliteProspectsSearchDialog.vue';
    import nhlTeamDraftColumn from './nhlTeamDraftColumn.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'nhlTeamDetailDialog',
        props: ['showDialog', 'team', 'teamLogos'],
        emits: ['dialogclosed'],
        data() {
            return {
                allPlayers: [],
                capHitChartPlotBands: [
                    {
                        from: 65000000,
                        to: 88000000,
                        color: '#e6e6eb'
                    },
                    {
                        from: 88000000,
                        to: 100000000,
                        color: '#e8cdcd'
                    }
                ],
                capProjectionRows: [
                    { display: 'NHL Cap Limit', field: 'salary_cap', type: 'currency' },
                    { display: 'Projected Cap Hit', field: 'projected_cap_hit', type: 'currency' },
                    { display: 'Projected Cap Space', field: 'projected_cap_space', type: 'currency', colorCode: true },
                    { display: 'Annual Cap Hit', field: 'current_roster_annual_cap_hit', type: 'currency' },
                    { display: 'Current Cap Space', field: 'current_cap_space', type: 'currency', colorCode: true },
                    { display: 'Deadline Cap Space', field: 'deadline_cap_space', type: 'currency' },
                    { display: 'LTIR Pool', field: 'ltir', type: 'currency' },
                    { display: 'Bonus Overages', field: 'carryover_overages', type: 'currency' },
                    { display: 'Potential Bonuses', field: 'potential_bonuses', type: 'currency' },
                    { display: 'Active Roster', field: 'roster' },
                    { display: 'Standard Contracts', field: 'contracts' },
                    { display: 'Retained Remaining', field: 'retained_salary_remaining', type: 'number' }
                ],
                capTabs: [
                    {
                        title: 'Current Cap',
                        content: 'currentcap'
                    },
                    {
                        title: 'Cap Projections',
                        content: 'capprojections'
                    }
                ],
                currentCapData: null,
                defensePositions: ['Defense'],
                draftData: null,
                draftDataPage: 1,
                draftYears: [],
                errorMsg: '',
                errorTitle: '',
                forwardsPositions: ['Right Wing', 'Left Wing', 'Center'],
                freeAgents: [],
                goaliesPositions: ['Goaltender'],
                hidden: { visible: false },
                isLoading: false,
                navItems: ['Overview', 'Cap Data', 'Roster', 'Draft'],
                roster: {
                    forwards: [],
                    defense: [],
                    goalies: [],
                    buried: [],
                    buyouts: [],
                    nrForwards: [],
                    nrDefense: [],
                    nrGoalies: []
                },
                rosterDataSelects: ['Cap Hit', 'AAV', 'Salary', 'Signing Bonus', 'Performance Bonus'],
                rosterTabs: [
                    { 
                        title: 'Forwards',
                        content: 'forwards'
                    },
                    {
                        title: 'Defense',
                        content: 'defense'
                    },
                    {
                        title: 'Goalies',
                        content: 'goalies'
                    },
                    {
                        title: 'Buried',
                        content: 'buried'
                    },
                    {
                        title: 'Buyout/Retained',
                        content: 'buyoutretained'
                    },
                    {
                        title: 'NR Forwards',
                        content: 'nrforwards'
                    },
                    {
                        title: 'NR Defense',
                        content: 'nrdefense'
                    },
                    {
                        title: 'NR Goalies',
                        content: 'nrgoalies'
                    }
                ],
                selectedDetail: null,
                selectedNavItem: 'Overview',
                selectedCapTab: 0,
                selectedRosterDataSelect: 'Cap Hit',
                selectedRosterTab: 0,
                showErrorDialog: false,
                showSearchDialog: false,
                teamData: null
            }
        },
        computed: {
            allRosterSeasonColumns() {
                let columns = [];

                if (this.currentSeason) {
                    columns = [
                        { cell: 'year1Cap', title: this.currentSeason, field: 'year1Cap', type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Cap Hit', footerCell: 'footerTemplate' },
                        { cell: 'year1Aav', title: this.currentSeason, field: 'year1Aav', type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'AAV', footerCell: 'footerTemplate' },
                        { cell: 'year1Salary', title: this.currentSeason, field: 'year1Salary', type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Salary', footerCell: 'footerTemplate' },
                        { cell: 'year1SigningBonus', title: this.currentSeason, field: 'year1SigningBonus', type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Signing Bonus', footerCell: 'footerTemplate' },
                        { cell: 'year1PerformanceBonus', title: this.currentSeason, field: 'year1PerformanceBonus', type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Performance Bonus', footerCell: 'footerTemplate' }
                    ];

                    for(let x = 1; x < 4; x++) {
                        let columnDisplay = `${this.currentSeasonStart + x}-${this.currentSeasonEnd + x}`;

                        columns.push(...[
                            { cell: `year${x + 1}Cap`, title: columnDisplay, field: `year${x + 1}Cap`, type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Cap Hit', footerCell: 'footerTemplate' },
                            { cell: `year${x + 1}Aav`, title: columnDisplay, field: `year${x + 1}Aav`, type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'AAV', footerCell: 'footerTemplate' },
                            { cell: `year${x + 1}Salary`, title: columnDisplay, field: `year${x + 1}Salary`, type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Salary', footerCell: 'footerTemplate' },
                            { cell: `year${x + 1}SigningBonus`, title: columnDisplay, field: `year${x + 1}SigningBonus`, type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Signing Bonus', footerCell: 'footerTemplate' },
                            { cell: `year${x + 1}PerformanceBonus`, title: columnDisplay, field: `year${x + 1}PerformanceBonus`, type: 'number', filter: 'numeric', format: '{0:c0}', dataType: 'Performance Bonus', footerCell: 'footerTemplate' }
                        ]);
                    }
                }

                return columns;
            },
            capHitChartItem() {
                if (!this.currentCapData) return [];

                return [[this.currentCapData.projected_cap_hit, 88000000]];
            },
            currentSeason() {
                if (this.currentCapData) {
                    return this.currentCapData.season;
                }

                return null;
            },
            currentSeasonEnd() {
                if (this.currentCapData) {
                    return +this.currentCapData.season?.split('-')[1];
                }

                return null;
            },
            currentSeasonStart() {
                if (this.currentCapData) {
                    return +this.currentCapData.season?.split('-')[0];
                }

                return null;
            },
            rosterColumns() {
                return [...this.rosterDefaultColumns, ...this.rosterSeasonColumns];
            },
            rosterDefaultColumns() {
                return [
                    { cell:'rosterName', field: 'fullName', title: 'Player', width: this.selectedNavItem === 'Roster' ? '250px' : '300px', footerCell: 'footerTemplate' },
                    { field: 'age', title: 'Age', width: this.selectedNavItem === 'Roster' ? '65px' : '95px'},
                    { field: 'position', title: 'Pos', width: this.selectedNavItem === 'Roster' ? '100px' : '130px'},
                    { field: 'contractLevel', title: 'Contract', width: this.selectedNavItem === 'Roster' ? '120px' : '140px'},
                    { field: 'yearsRemaining', title: 'Yrs', width: this.selectedNavItem === 'Roster' ? '75px' : '110px'}
                ]
            },
            rosterSeasonColumns() {
                let columns = [];

                if (this.currentSeason) {
                    columns = this.allRosterSeasonColumns.filter(col => col.dataType === this.selectedRosterDataSelect);
                }

                return columns;
            },
            rosterSeasons() {
                let seasons = [];

                if (this.currentSeason) {
                    seasons.push(this.currentSeason);

                    for(let x = 1; x < 4; x++) {
                        seasons.push(`${this.currentSeasonStart + x}-${this.currentSeasonEnd + x}`);
                    }
                }

                return seasons;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    if (this.team) {
                        this.getTeamData();
                    }
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignDraftDetails(draftData) {
                draftData.forEach(data => {
                    data.drafts.forEach(draft => {
                        draft.toTeamLogo = this.teamLogos.find(logo => logo.teamId === draft.team_id)?.logo;
                        draft.status = '';

                        if (draft.original_team_id) {
                            draft.originalTeamLogo = this.teamLogos.find(logo => logo.teamId === draft.original_team_id)?.logo;

                            if (draft.original_team_id === this.team.puckPediaId) {
                                draft.status = 'Traded Away';
                            }

                            if (draft.original_team_id && draft.team_id === this.team.puckPediaId) {
                                draft.status = 'Traded To';
                            }
                        }

                        if (draft.status === 'Traded Away' && draft.trade) {
                            draft.tradeDetail = draft.trade[0].trade_details;
                            draft.tradeDate = new Date(`${draft.trade[0].date}T00:00:00`);
                        }

                        if (draft.status === 'Traded To' && draft.trade) {
                            draft.tradeDetail = draft.trade[draft.trade.length - 1].trade_details;
                            draft.tradeDate = new Date(`${draft.trade[draft.trade.length - 1].date}T00:00:00`);
                        }
                    });

                    data.drafts.sort((a, b) => a.status.localeCompare(b.status));
                    data.drafts.sort((a, b) => a.round.localeCompare(b.round));
                });

                console.log(draftData);
            },
            async assignRoster(players) {
                players.forEach(player => {
                    player.contractYears = this.getPlayerContractYears(player);
                    player.fullName = `${player.last_name}, ${player.first_name}`;
                    this.getPlayerContractExpiryStatus(player);
                    this.getPlayerSeasonData(player);
                    player.contractLevel = player.contractYears.find(year => year.season === this.currentSeason)?.contractLevel;
                    player.lastStatus = player.current[0]?.last_player_status;
                    player.yearsRemaining = player.contractYears?.length > 0 ? `${this.getPlayerContractYearsRemaining(player.contractYears)} ${player.expiryStatus}` : null;
                });

                this.currentCapData.buried_caphits.forEach(buried => {
                    let playerMatch = players.find(player => player.player_id === buried.player_id);

                    if (playerMatch) {
                        playerMatch.year1Cap = buried.buried_caphit;
                    }
                });

                let buriedPlayerIds = this.currentCapData.buried_caphits.map(player => player.player_id);
                let buyoutRetainedPlayerIds = [...this.currentCapData.buyout_contracts, ...this.currentCapData.retained_contracts].map(contract => contract.player_id);

                if (buyoutRetainedPlayerIds.length > 0) {
                    this.roster.buyouts = await this.getPlayersByPlayerIds(buyoutRetainedPlayerIds) ?? [];
                    this.roster.buyouts.forEach(player => {
                        if (player.current?.length > 0) {
                            player.contractLevel = player.current[0].years?.find(year => year.season === this.currentSeason)?.contractLevel;
                            player.lastStatus = player.current[0].last_player_status;
                        }

                        let playerMatch = this.currentCapData.buyout_contracts.find(contract => contract.player_id === player.player_id);

                        if (playerMatch) {
                            player.buyoutRetainedStatus = 'Buyout';
                        } else {
                            player.buyoutRetainedStatus = 'Retained';
                        }
                    });

                    this.getBuyoutRetainedSeasonData();
                }

                if (this.currentCapData.other_contracts?.length > 0) {
                    this.currentCapData.other_contracts.forEach(contract => {
                        contract.fullName = `${contract.last_name}, ${contract.first_name}`;
                        contract.year1Cap = contract.cap_hit ? +contract.cap_hit : 0;
                        contract.year1Aav = contract.cap_hit ? +contract.cap_hit : 0;
                    });

                    this.roster.buyouts = [...this.roster.buyouts, ...this.currentCapData.other_contracts];
                }

                this.allPlayers = cloneDeep(players);

                this.roster.forwards = players.filter(player => player.active === '1' && this.forwardsPositions.some(pos => player.position === pos));
                this.roster.defense = players.filter(player => player.active === '1' && this.defensePositions.some(pos => player.position === pos));
                this.roster.goalies = players.filter(player => player.active === '1' && this.goaliesPositions.some(pos => player.position === pos));
                this.roster.buried = players.filter(player => buriedPlayerIds.some(id => id === player.player_id));
                this.roster.nrForwards = players.filter(player => player.active === '0' && this.forwardsPositions.some(pos => player.position === pos));
                this.roster.nrDefense = players.filter(player => player.active === '0' && this.defensePositions.some(pos => player.position === pos));
                this.roster.nrGoalies = players.filter(player => player.active === '0' && this.goaliesPositions.some(pos => player.position === pos));

                console.log(this.roster);
                this.freeAgents = this.getFreeAgents(players);
            },
            getBonusProgressValue(position) {
                if (this.selectedRosterDataSelect === 'Signing Bonus') {
                    let positionTotal = this.roster[position].reduce((prev, cur) => {
                        return prev + cur.year1SigningBonus;
                    }, 0);

                    if (positionTotal === 0) return 0;

                    let seasonTotal = this.allPlayers.reduce((prev, cur) => {
                        return prev + cur.year1SigningBonus;
                    }, 0)

                    return Math.round((positionTotal / seasonTotal) * 100);
                }

                if (this.selectedRosterDataSelect === 'Performance Bonus') {
                    let positionTotal = this.roster[position].reduce((prev, cur) => {
                        return prev + cur.year1PerformanceBonus;
                    }, 0);

                    if (positionTotal === 0) return 0;

                    let seasonTotal = this.allPlayers.reduce((prev, cur) => {
                        return prev + cur.year1PerformanceBonus;
                    }, 0)

                    return Math.round((positionTotal / seasonTotal) * 100);
                }
            },
            getBuyoutRetainedSeasonData() {
                this.rosterSeasons.forEach((season, index) => {
                    let teamDataSeason = this.teamData.seasons?.find(data => data.season === season);

                    if (teamDataSeason) {
                        let buyoutRetainedContracts = [...teamDataSeason.buyout_contracts, ...teamDataSeason.retained_contracts];

                        this.roster.buyouts.forEach(player => {
                            player[`year${ index + 1 }Cap`] = buyoutRetainedContracts.find(contract => contract.player_id === player.player_id)?.cap_hit 
                                ? +buyoutRetainedContracts.find(contract => contract.player_id === player.player_id)?.cap_hit 
                                : 0;

                            if (player.buyoutRetainedStatus === 'Retained') {
                                player[`year${ index + 1 }Aav`] = buyoutRetainedContracts.find(contract => contract.player_id === player.player_id)?.cap_hit 
                                    ? +buyoutRetainedContracts.find(contract => contract.player_id === player.player_id)?.cap_hit 
                                    : 0;
                            }

                            if (player.buyoutRetainedStatus === 'Buyout') {
                                player[`year${ index + 1 }Aav`] = 0;
                            }

                            player[`year${ index + 1 }Salary`] = 0;
                        });
                    }
                }) 
            },
            getBuyoutsRosterColumns() {
                let columns = cloneDeep(this.rosterColumns);
                let yrsRemainingIndex = columns.findIndex(col => col.field === 'yearsRemaining');

                if (yrsRemainingIndex > -1) {
                    columns.splice(yrsRemainingIndex, 1, { field: 'buyoutRetainedStatus', title: 'Type' });
                    return columns;
                }
            },
            getColumnSeasonTotal(position, year) {
                if (this.selectedRosterDataSelect === 'Cap Hit') {
                    if (position === 'defense') {
                        position = 'defence';
                    }

                    if (this.teamData.seasons[year - 1][`current_roster_annual_cap_hit_${position}`] !== null && this.teamData.seasons[year - 1][`current_roster_annual_cap_hit_${position}`] !== undefined) {
                        if (position !== 'buyouts') {
                            return this.teamData.seasons[year - 1][`current_roster_annual_cap_hit_${position}`]
                        } else {
                            return this.teamData.seasons[year - 1].current_roster_annual_cap_hit_buyouts + this.teamData.seasons[year - 1].current_roster_annual_cap_hit_retained + this.teamData.seasons[year - 1].current_roster_annual_cap_hit_other;
                        }
                    }

                    if (position === 'defence') {
                        position = 'defense';
                    }

                    return this.roster[position].reduce((prev, cur) => {
                        return prev + cur[`year${year}Cap`];
                    }, 0);
                }

                if (this.selectedRosterDataSelect === 'AAV') {
                    return this.roster[position].reduce((prev, cur) => {
                        return prev + cur[`year${year}Aav`];
                    }, 0);
                }

                if (this.selectedRosterDataSelect === 'Salary') {
                    return this.roster[position].reduce((prev, cur) => {
                        return prev + cur[`year${year}Salary`];
                    }, 0);
                }

                if (this.selectedRosterDataSelect === 'Signing Bonus') {
                    return this.roster[position].reduce((prev, cur) => {
                        return prev + cur[`year${year}SigningBonus`];
                    }, 0);
                }

                if (this.selectedRosterDataSelect === 'Performance Bonus') {
                    return this.roster[position].reduce((prev, cur) => {
                        return prev + cur[`year${year}PerformanceBonus`];
                    }, 0);
                }
            },
            getCurrentSeasonTotal(position, field) {
                return this.roster[position].reduce((prev, cur) => {
                    return prev + cur[field];
                }, 0);
            },
            getFreeAgents(allPlayers) {
                return allPlayers.filter(player => !player.contractYears?.find(year => year.season === `${this.currentSeasonStart + 1}-${this.currentSeasonEnd + 1}`))
                    .sort((a, b) => a.fullName.localeCompare(b.fullName));
            },
            getPlayerContractExpiryStatus(player) {
                if (player.future && player.future.length > 0) {
                    player.expiryStatus = player.future[0].expiry_status;
                    player.arbitrationEligible = player.future[0].arbitration_eligible === '1' ? true : false;
                } else {
                    if (player.current && player.current.length > 0) {
                        player.expiryStatus = player.current[0].expiry_status;
                        player.arbitrationEligible = player.current[0].arbitration_eligible === '1' ? true: false;
                    }
                }
            },
            getPlayerContractYears(player) {
                let currentYears = [];
                let futureYears = [];

                if (player.current && player.current.length > 0) {
                    player.current[0].years.forEach(year => {
                        year.contract = 'current';
                    });

                    currentYears = player.current[0].years;
                }

                if (player.future && player.future.length > 0) {
                    player.future[0].years.forEach(year => {
                        year.contract = 'future';
                    });

                    futureYears = player.future[0].years;
                }

                return [...currentYears, ...futureYears];
            },
            getPlayerContractYearsRemaining(contractYears) {
                let currentSeasonIndex = contractYears.findIndex(year => year.season === this.currentSeason);

                if (currentSeasonIndex > -1) {
                    let remainingContractYears = contractYears.slice(currentSeasonIndex);

                    return remainingContractYears.length;
                }
            },
            async getPlayerDetail(playerId) {
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetEPPlayerDetail(tokenResponse.accessToken, playerId)
                    .then(res => {
                        res.data.data?.gameLogs?.data.forEach(log => {
                            log.game.date = new Date(`${log.game.date}T00:00:00`);
                        });

                        res.data.data?.transfers?.data.forEach(transfer => {
                            transfer.date = new Date(`${transfer.date}T00:00:00`);
                        });
                        
                        this.selectedDetail = res.data.data;

                        this.isLoading = false;

                        this.toggleSearchDialog();
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async getPlayersByPlayerIds(playerIds) {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaPlayersByPlayerIds(tokenResponse.accessToken, playerIds) 
                    .then((res) => {
                        res.data?.data?.forEach(player => {
                            player.fullName = `${player.last_name}, ${player.first_name}`;
                        });

                        return res.data?.data;
                    }).catch(error => {
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            getPlayerSeasonData(player) {
                if (player.contractYears) {
                    this.rosterSeasons.forEach((season, index) => {
                        player[`year${ index + 1 }Cap`] = player.contractYears.find(year => year.season === season)?.cap_hit 
                            ? +player.contractYears.find(year => year.season === season).cap_hit 
                            : 0;

                        player[`year${ index + 1}Aav`] = player.contractYears.find(year => year.season === season)?.aav 
                            ? +player.contractYears.find(year => year.season === season)?.aav 
                            : 0;

                        player[`year${ index + 1 }Salary`] = player.contractYears.find(year => year.season === season)?.total_salary 
                            ? +player.contractYears.find(year => year.season === season)?.total_salary 
                            : 0;

                        player[`year${ index + 1 }SigningBonus`] = player.contractYears.find(year => year.season === season)?.s_bonuses
                            ? +player.contractYears.find(year => year.season === season)?.s_bonuses
                            : 0;

                        player[`year${ index + 1 }PerformanceBonus`] = player.contractYears.find(year => year.season === season)?.potential_bonuses
                            ? +player.contractYears.find(year => year.season === season)?.potential_bonuses
                            : 0;

                        player[`year${ index + 1 }NoMovementClause`] = player.contractYears.find(year => year.season === season)?.clause?.includes('NMC');
                        player[`year${ index + 1 }NoTradeClause`] = player.contractYears.find(year => year.season === season)?.clause === 'NTC';
                        player[`year${ index + 1 }ModifiedNoTradeClause`] = player.contractYears.find(year => year.season === season)?.clause?.includes('M-NTC');

                        if (player[`year${ index + 1 }ModifiedNoTradeClause`]) {
                            let contract;

                            if (player.future?.length > 0) {
                                contract = [player.current[0], player.future[0]].find(contract => contract.years?.some(year => year.season === season));
                            } else {
                                contract = player.current[0];
                            }

                            if (contract) {
                                let detail = '';

                                let teamsIndex = contract.clause_details?.toLowerCase().indexOf('Team No Trade List'.toLowerCase());

                                if (teamsIndex > -1) {
                                    detail = contract.clause_details.substring(teamsIndex - 3, teamsIndex)?.trim() + ' Team No Trade List';
                                }

                                player[`year${ index + 1 }ClauseDetail`] = detail;
                            }

                        }
                    });
                }
            },
            getProgressValue(position) {
                if (this.selectedRosterDataSelect !== 'Signing Bonus' && this.selectedRosterDataSelect !== 'Performance Bonus') {
                    if (position !== 'buyout') {
                        return Math.round((this.currentCapData[`current_roster_annual_cap_hit_${position}`] / this.currentCapData.salary_cap) * 100);
                    } else {
                        return Math.round(((this.currentCapData.current_roster_annual_cap_hit_buyouts 
                            + this.currentCapData.current_roster_annual_cap_hit_retained 
                            + this.currentCapData.current_roster_annual_cap_hit_other) / this.currentCapData.salary_cap) * 100);
                    }
                } 

                if (position === 'defence') {
                    position = 'defense';
                }

                if (position === 'buyout') {
                    position = 'buyouts';
                }

                return this.getBonusProgressValue(position);
            },
            getNonRosterProgressValue(position) {
                if (this.selectedRosterDataSelect !== 'Signing Bonus' && this.selectedRosterDataSelect !== 'Performance Bonus') {
                    let total = this.roster[position].reduce((prev, cur) => {
                        return prev + cur.year1Cap;
                    }, 0);

                    return Math.round((total / this.currentCapData.salary_cap) * 100);
                }

                return this.getBonusProgressValue(position);
            },
            async getTeamData() {
                this.isLoading = true;

                let request = {
                    nhl_team_id: null,
                    team_id: this.team.puckPediaId
                }

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaTeam(tokenResponse.accessToken, request) 
                    .then((res) => {
                        if (res?.data?.data) {
                            res.data.data.team.logo = res.data.data.team?.logo?.replace('sm@', 'lg@');
                            this.teamData = res.data.data.team;

                            if (this.teamData.seasons?.length > 0) {
                                this.currentCapData = this.teamData.seasons[0];
                            }

                            this.getTeamDraftData();
                            this.assignRoster(res.data.data.players);
                            console.log(this.teamData);
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async getTeamDraftData() {
                for(let i = 0; i < 4; i++) {
                    this.draftYears.push(`${this.currentSeasonEnd + i}`);
                }

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaDraftsByTeam(tokenResponse.accessToken, this.draftYears, this.team.puckPediaId)
                    .then((res) => {
                        if (res?.data?.data) {
                            this.draftData = res.data.data;
                            this.assignDraftDetails(this.draftData)
                        }
                        this.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            goToNavItem(item) {
                this.selectedNavItem = item;
            },
            labelContent(evt) {
                return `$${evt.value / 1000000}M`;
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onCapTabSelected(evt) {
                this.selectedCapTab = evt.selected;
            },
            onGoToPlayer(evt) {
                if (!evt.dataItem.eliteprospects_id) return;

                this.getPlayerDetail(evt.dataItem.eliteprospects_id);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRosterTabSelected(evt) {
                this.selectedRosterTab = evt.selected;
                this.selectedRosterDataSelect = 'Cap Hit';
            },
            resetDefaults() {
                this.teamData = null;
                this.draftData = null;

                this.draftYears = [];

                this.roster = {
                    forwards: [],
                    defense: [],
                    goalies: [],
                    buried: [],
                    buyouts: [],
                    nrForwards: [],
                    nrDefense: [],
                    nrGoalies: []
                };

                this.selectedNavItem = 'Overview';
                this.selectedCapTab = 0;
                this.currentCapData = null;

                this.selectedRosterTab = 0;
                this.selectedRosterDataSelect = 'Cap Hit';

                this.selectedDetail = null;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleSearchDialog() {
                this.showSearchDialog = !this.showSearchDialog;
            }
        },
        filters: {
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            },
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-loader': Loader,
            'k-tabstrip': TabStrip,
            'view-header': viewHeader,
            'k-chart': Chart,
            'k-chart-series': ChartSeries,
            'k-chart-series-item': ChartSeriesItem,
            'k-chart-category-axis': ChartCategoryAxis,
            'k-chart-category-axis-item': ChartCategoryAxisItem,
            'k-chart-value-axis': ChartValueAxis,
            'k-chart-value-axis-item': ChartValueAxisItem,
            'k-dropdown': DropDownList,
            'k-progressbar': ProgressBar,
            'detail-view-grid': detailViewGrid,
            'dashboard-puckpedia-component': dashboardPuckPediaComponent,
            'k-listview': ListView,
            'k-listview-header': ListViewHeader,
            'k-listview-footer': ListViewFooter,
            'elite-prospects-search-dialog': eliteProspectsSearchDialog,
            'nhl-team-draft-column': nhlTeamDraftColumn,
            'k-button': Button
        }
    }
</script>

<style>
    @import '/src/assets/css/nhlTeamDetailDialog.css';
</style>